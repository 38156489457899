import React, {useEffect, useState} from 'react'
import Page from '../../../components/Structure/Page/Page'
import SimpleTable from './components/SimpleTable'
import { onGetFiltersFromURL, onGetParamsFromState, onUpdateURLArgs } from './custom_functions'
import AddOpticaModal from './modals/AddOpticaModal/AddOpticaModal'
import {request_opticals, request_delete_optica} from './requests'

const Opticals = props => {

    const {location, history} = props

    const [loading, setLoading] = useState(false)
    const [opticals, setOpticals] = useState([])
    const [addModal, setAddModal] = useState(false)
    const [totalOpticals, setTotalOpticals] = useState(10)
    const [filter, setFilter] = useState({
        query:''
    })
    const [tableFilter, setTableFilter] = useState({
        limit:10,
        offset:0,
        order:1,
        order_by:'_id',
    })

    useEffect(() => {
        const initModule = async() => {try {
            setLoading(true)
            const params = onGetParamsFromState(filter, tableFilter)
            const _opticals = await request_opticals(params)
            setOpticals(_opticals.data)
            setTotalOpticals(_opticals.count)
            onUpdateURLArgs(filter, tableFilter)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }}
        initModule()
    }, [filter, tableFilter])

    useEffect(() => {
        const initModule = async() => {try {
            if(location.search){
                const response = onGetFiltersFromURL(location.search, filter, tableFilter)
                setFilter(response.filter)
                setTableFilter(response.tableFilter)
            }else{
                onUpdateURLArgs(filter, tableFilter)
            }
        } catch (error) {
        }}
        initModule()
    }, [])

    

    const onSelectedItem = (item) => {
        const {_id} = item
        history.push(`/opticals/${_id}`)
    }
  
    const onChangeFilter = (tag, value) => { // Change generic filter
        let newFilter = onUpdateFilter(tag, value)
        setFilter(newFilter)
    }

    const onUpdateFilter = (id,value) => {
        let temp = {...filter}
        temp[id] = value
        return temp
    }

    const onUpdateUsers = async() => {
        //const params = onGetParamsFromState(filter, tableFilter)
        const _opticals = await request_opticals()
        setOpticals(_opticals.data)
        setTotalOpticals(_opticals.count)
        setAddModal(false)
    }

    const onDeleteOptica = async(item) =>{
        const {_id} = item
        
        //console.log('se ha borrado el producto con id')
        //console.log(_id)

        setLoading(true)
        try {
            //await request_delete_optica(_id);
            //const _opticals = await request_opticals()
            //setOpticals(_opticals.data)
            //setTotalOpticals(_opticals.count)
            //console.log('se ha borrado el producto con id')
            //console.log(_id)
            setLoading(false)   
        } catch (error) {
            console.log(error)
            //setError('Hubo un error')
            setLoading(false)
        } 
    }

    /* console.log('hola')
    console.log(users)
    console.log(totalUsers) */

    return(
        <Page title='Ópticas' >
            <AddOpticaModal history={history} open={addModal} onClose={() => setAddModal(false)} onRequestUsers={onUpdateUsers}/>
            <SimpleTable
                data={opticals} 
                filter={filter}
                tableFilter={tableFilter}
                total={totalOpticals}
                loading={loading}
                onAddRegister={() => setAddModal(true)}
                onChangeFilter={onChangeFilter}
                onUpdateTableFilter={(data) => setTableFilter(data)}
                onSelectedItem={onSelectedItem}
                onDeleteItem={onDeleteOptica}
                />
        </Page>
    )
}

export default Opticals