import { mainServer } from '../../../variables/config'

export const request_order = async(id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/order/admin/${id}`)
    return response.data.data
}

export const request_add_product = async(id, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    await mainServer.post(`/order/admin/${id}/lineitem`, data)
}

export const request_update_lineitem = async(id, lineitemid, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    await mainServer.patch(`/order/admin/${id}/lineitem/${lineitemid}`, data)
}

export const request_update_discount = async(id, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    await mainServer.patch(`/order/admin/${id}/discount`, data)
}

export const request_delete_lineitem = async(id, lineitem_id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    await mainServer.delete(`/order/admin/${id}/lineitem/${lineitem_id}`)
}

export const request_search_products = async(params) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/product/admin/search`, {params:{...params}})
    return response.data.data
}

export const request_search_patients = async(params) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/patient/all`, {params:{...params}})
    return response.data.data
}

export const request_search_optometrists = async(params) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/optometrist/all`, {params:{...params}})
    return response.data.data
}

export const request_search_opticals = async(params) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/opticals/all`, {params:{...params}})
    return response.data.data
}

export const request_patient = async(id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/patient/${id}`)
    return response.data.data
}

export const request_opticals = async() => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/opticals/all`)
    return response.data.data
}

export const request_create_patient = async(data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.post(`/patient`, data)
    return response.data.data
}


export const request_update_shipping_address = async(id, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.patch(`/order/${id}/shippingAddress`, data)
    return response.data.data
}

export const request_update_billing_address = async(id, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.patch(`/order/${id}/billingAddress`, data)
    return response.data.data
}

export const request_update_order = async(id, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.patch(`/order/admin/${id}`, data)
    return response.data.data
}


export const request_mercadopago_payment_link = async(id,data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/order/${id}/admin/payment/mercadopago/link`)
    return response.data.data.url
}

export const request_mercadopago_preference = async(id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/order/payment/mercadopago/preference/${id}`)
    return response.data.data
}

export const request_mercadopago_payment = async(id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/order/payment/mercadopago/${id}`)
    return response.data.data
}

export const request_update_payment = async(id, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    await mainServer.patch(`/order/${id}/admin/payment`, data)
}

