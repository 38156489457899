import React from 'react';
import PublicRouters from './routes/router';
import { createBrowserHistory } from "history";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import reducer from './store/reducer'
import { blueGrey } from '@material-ui/core/colors';
import { general_theme } from './theme';


const store = createStore(reducer);

const theme = createMuiTheme(general_theme);

const App = (props) => {

  return(
    <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <PublicRouters history={createBrowserHistory()} {...props}/>
        </MuiThemeProvider>  
    </Provider>  
  )
}

export default App;
