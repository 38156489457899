import React from 'react'
import { makeStyles, Grid } from '@material-ui/core'
import DisplayText from '../../Texts/DisplayText'
import SingleMenu from './components/SingleMenu'
import { content } from './content'
import ContactInfo from './components/ContactInfo'
import { blueGrey } from '@material-ui/core/colors'
import logo from '../../../assets/ubelens-logo-color.png'
import payments from '../../../assets/payment-cards.png'
import PaymentContent from './components/PaymentContent'

const useStyles = makeStyles(theme => ({
    subscriber:{
        background:theme.palette.secondary.main
    },
    subscriberContainer:{
        width:1200,
        margin:'auto',
        padding:theme.spacing(4),
        paddingTop:theme.spacing(2),
        paddingBottom:theme.spacing(2),
        boxSizing:'border-box',
        [theme.breakpoints.down('md')]: {
            width:'100%',
            padding:theme.spacing(2),
        },
    },
    footer:{
        //background:theme.palette.secondary.dark
        background:blueGrey[100],
        borderTopRightRadius:16,
        borderTopLeftRadius:16
    },
    footercontainer:{
        width:1200,
        margin:'auto',
        padding:theme.spacing(4),
        paddingTop:theme.spacing(4),
        paddingBottom:theme.spacing(4),
        boxSizing:'border-box',
        [theme.breakpoints.down('md')]: {
            width:'100%',
            padding:theme.spacing(2),
        },
    },
    logoContainer:{
        background:theme.palette.primary.main,
        padding:"2px 16px",
        width:120,
        borderRadius:32,
        '&:hover':{
            cursor:'pointer'
        }
    }
}))

const Footer = props => {

    const classes = useStyles()
    const {history} = props

    return(
        <div>
            <div className={classes.footer}>
                <div className={classes.footercontainer}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>

                            <div className={classes.logoContainer} onClick={() => history.push('/')}><img src={logo} alt='' width='100%'/></div>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SingleMenu {...content.information}/>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <ContactInfo {...content.contact}/>
                        </Grid>
                        <Grid item xs={12}>
                            <PaymentContent />
                        </Grid>
                    </Grid>
                    
                </div>
            </div>
        </div>
    )
}

export default Footer