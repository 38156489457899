import { mainServer } from "../../../variables/config"
import axios from 'axios'
import FileSaver from 'file-saver'


export const request_optica = async(id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/opticals/${id}`)
    return response.data.data
}

export const request_update_optica = async(id,data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.patch(`/opticals/${id}`, data)
    return response.data.data
}

export const request_update_bank_account = async(id,data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.patch(`/opticals/${id}/bank_account`, data)
    return response.data.data
}

export const request_update_legalrepresentative = async(id,data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.patch(`/opticals/${id}/legalrepresentative`, data)
    return response.data.data
}

export const request_update_address = async(id,data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.patch(`/opticals/${id}/billingAddress`, data)
    return response.data.data
}

export const request_upload_image = async(id, file, data) => {
    const logData = JSON.parse(localStorage.getItem('token_data'));
    mainServer.defaults.headers.common['Authorization'] = logData.token;
    let response = await mainServer.post(`/opticals/${id}/image`, data)
    const options = {headers: {'Content-Type': file.type}}
    delete axios.defaults.headers.common["Authorization"]
    await axios.put(response.data.data.url, file, options);
}

export const request_delete_image = async(id) => {
    const logData = JSON.parse(localStorage.getItem('token_data'));
    mainServer.defaults.headers.common['Authorization'] = logData.token;
    await mainServer.delete(`/opticals/${id}/image`)
}


///////////////////////////// DOCUMENTS HANDLER  /////////////////////////////////////////////

export const request_upload_document = async(id, file, data) => {
    const logData = JSON.parse(localStorage.getItem('token_data'));
    mainServer.defaults.headers.common['Authorization'] = logData.token;
    let response = await mainServer.post(`/opticals/${id}/document`, data)
    console.log(response.data)
    const options = {headers: {'Content-Type': file.type}}
    delete axios.defaults.headers.common["Authorization"]
    await axios.put(response.data.data.url, file, options);
}
  
export const request_download_document = async(id, data) => {
    const logData = JSON.parse(localStorage.getItem('token_data'));
    mainServer.defaults.headers.common['Authorization'] = logData.token;
    const response = await mainServer.get(`/opticals/${id}/document`, {params:{...data}})
    delete axios.defaults.headers.common["Authorization"]
    const result = await axios.get(response.data.data.url, {responseType: 'blob'});
    FileSaver.saveAs(result.data, data.document);
} 

export const request_delete_document = async(id, data) => {
    const logData = JSON.parse(localStorage.getItem('token_data'));
    mainServer.defaults.headers.common['Authorization'] = logData.token;
    await mainServer.delete(`/opticals/${id}/document`, {params:{...data}})
}
  

export const request_change_password = async(id,data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    await mainServer.patch(`/opticals/${id}/changepassword`, data)
}

/*----------office----------- */

export const request_offices = async(params) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/branchoffice/all`, {params:{...params}})
    return response.data.data
}

export const request_office = async(id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/branchoffice/${id}`)
    return response.data.data
}

export const request_create_office = async(data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    await mainServer.post(`/branchoffice`, data)
}

export const request_update_office = async(id,data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.patch(`/branchoffice/${id}`, data)
    return response.data.data
}

export const request_update_office_address = async(id,data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.patch(`/branchoffice/${id}/address`, data)
    return response.data.data
}

/*----------optometrist--------- */

export const request_optometrists_by_optical = async(id, params) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/optometrist/optical/${id}/all`, {params:{...params}})
    return response.data.data
}

/*----------patients--------- */

export const request_patients_by_optical = async(id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/patient/optical/${id}/all`)
    return response.data.data
}