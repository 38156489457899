import React, {useEffect, useState} from 'react'
import { Grid } from '@material-ui/core'
import Page from '../../../components/Structure/Page/Page'
import UserInfoCard from './components/UserInfoCard'
import SimpleCard from '../../../components/Structure/Cards/SimpleCard'
import DisplayText from '../../../components/Texts/DisplayText'
import { catalogs } from '../../../texts/esp/catalogs'
import InputForm from '../../../components/Forms/InputForm'
import SelectForm from '../../../components/Forms/SelectForm'
import { onGetFormData, onSetErrorsToForm, isFormValid, onInitForm, onGetSelectedFormData } from '../../../shared/utility'
import ContainedButton from '../../../components/Actions/ContainedButton'
import { request_product, request_update_product, request_update_user } from './requests'
import TechnicalDetails from './components/TechnicalDetails'
import ImageContainer from './components/ImageContainer'
import UploadImageModal from './modals/UploadImageModal'

const ProductView = props => {

    const {location, history, match} = props

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [product, setProduct] = useState(null)
    const [imagemodal, setImagemodal] = useState(false)
    const [error, setError] = useState(null)


    useEffect(() => {
        const initModule = async() => {try {
            setLoading(true)
            const {id} = match.params
            const _product = await request_product(id)
            setProduct(_product)
            let _form = onInitForm(form, _product)
            _form.available.value = _form.available.value ? 1 : 2 
            setForm(_form)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }}
        initModule()
    }, [])

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }
    
    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetSelectedFormData(form, product)
        data2send.available = data2send.available === 1 ? true : false
        console.log(data2send)
        setSending(true)
        try {
            const _product = await request_update_product(product._id, data2send);
            setProduct(_product)
            setSending(false)
        } catch (error) {
            console.log(error)
            setError('Hubo un error')
            setSending(false)
        }

    }

    const onCloseImageModal = () => {
        setImagemodal(false)
    }

    const onUpdateImage = (data) => {
        console.log('Updater data de iamge upload or delete')
        setProduct(data)
        setImagemodal(false)
    }

    //sacar el ejemplo de aqui

    return(
        <Page title='Información de producto' loading={loading} goback>
            <UploadImageModal open={imagemodal} id={match.params.id} onClose={onCloseImageModal} onUpdateData={onUpdateImage}/>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container justify='flex-end'><Grid item>
                        <ContainedButton color='primary' onClick={onSubmit} loading={sending} >Guardar</ContainedButton>
                    </Grid></Grid>
                </Grid>
                <Grid item xs={12} md={8} xl={9}>
                    <SimpleCard>
                        <Grid container spacing={4} justify='flex-end'>
                            <Grid item xs={12}>
                                <DisplayText variant='subtitle1'>Información general</DisplayText>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} >
                                        <InputForm data={form.title} onChange={onChange} />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <InputForm data={form.price} onChange={onChange} />
                                    </Grid>
                                    <Grid item xs={6} >
                                        <InputForm data={form.optical_price} onChange={onChange} />
                                    </Grid>
                                    <Grid item xs={6} >
                                        <InputForm data={form.supplier_price} onChange={onChange} />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <InputForm data={form.long_description} onChange={onChange} />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <InputForm data={form.details} onChange={onChange} />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <InputForm data={form.specifications} onChange={onChange} />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <InputForm data={form.variants} onChange={onChange} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </SimpleCard>
                </Grid>
                <Grid item xs={12} md={4} xl={3}>
                    <SimpleCard>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <ImageContainer image={product ? product.image : null} onClick={() => setImagemodal(true)} />
                            </Grid>
                            <Grid item xs={12} >
                                <SelectForm data={form.available} onChange={onChange} />
                            </Grid>
                            <Grid item xs={12} >
                                <InputForm data={form.manufacturer} onChange={onChange} />
                            </Grid>
                            <Grid item xs={12} >
                                <InputForm data={form.tags} onChange={onChange} />
                            </Grid>
                        </Grid>
                        
                    </SimpleCard>
                </Grid>
            </Grid>
        </Page>
    )
}

export default ProductView

const formData = {
    title: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'title',
            type: 'text',
            fullWidth: true,
            label: 'Nombre',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    manufacturer: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'manufacturer',
            type: 'text',
            fullWidth: true,
            label: 'Laboratorio',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    tags: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'tags',
            type: 'text',
            fullWidth: true,
            label: 'Tags',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    long_description: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'long_description',
            type: 'text',
            fullWidth: true,
            label: 'Descripción',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 1024',
            rows:4,
            multiline:true
        },
        rules: {
            type: 'distance',
            min: 1, max: 1024
        }
    },
    price: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'price',
            type: 'number',
            fullWidth: true,
            label: 'Precio',
            helperText: 'El campo no puede ser negativo'
        },
        rules: {
            type: 'numeric',
            min: 0
        }
    },
    specifications: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'specifications',
            type: 'text',
            fullWidth: true,
            label: 'Especificaciones',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 1024',
            rows:4,
            multiline:true
        },
        rules: {
            type: 'distance',
            min: 0, max: 1024
        }
    },
    details: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'details',
            type: 'text',
            fullWidth: true,
            label: 'Detalles',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 1024',
            rows:4,
            multiline:true
        },
        rules: {
            type: 'distance',
            min: 0, max: 1024
        }
    },
    variants: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'variants',
            type: 'text',
            fullWidth: true,
            label: 'Variantes',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 65536',
            rows:4,
            multiline:true
        },
        rules: {
            type: 'distance',
            min: 0, max: 65536
        }
    },
    available: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[{value:1, label:'Activo'},{value:2, label:'Inactivo'}],
        config: {
            id: 'available',
            type: 'selected',
            fullWidth: true,
            label: 'Estatus',
            helperText: 'Seleccione al menos una opción',
        },
        rules: {
            type: 'select',
        }
    },
    optical_price: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'optical_price',
            type: 'number',
            fullWidth: true,
            label: 'Precio óptica',
            helperText: 'El campo no puede ser negativo'
        },
        rules: {
            type: 'numeric',
            min: 0
        }
    },
    supplier_price: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'supplier_price',
            type: 'number',
            fullWidth: true,
            label: 'Costo',
            helperText: 'El campo no puede ser negativo'
        },
        rules: {
            type: 'numeric',
            min: 0
        }
    },
}