export const layout = {
    menu:[
        {
            label:'MARCAS',
            items:[
                {label:'Acuvue', url:''},
                {label:'Alcon', url:''},
                {label:'Bausch + Lomb', url:''},
                {label:'CooperVision', url:''},
                {label:'Johnson&Johnson', url:''},
            ]
        },
        {
            label:'CONDICIÓN',
            items:[
                {label:'Miopía', url:''},
                {label:'Miopía e Hipermetropía', url:''},
                {label:'Asigmastismo', url:''},
                {label:'Asigmastismo e Hipermetropía', url:''},
                {label:'Cosméticos', url:''},
            ]
        }
    ]
}

export const nodata = '-'