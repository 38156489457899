import { ClickAwayListener, Grid, Grow, Icon, IconButton, makeStyles, Popper } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import ShortcutButton from '../../../../../components/Actions/ShortcutButton';
import InputForm from '../../../../../components/Forms/InputForm';
import SelectAutocompleteForm from '../../../../../components/Forms/SelectAutocompleteForm';
import SimpleCard from '../../../../../components/Structure/Cards/SimpleCard';
import DisplayText from '../../../../../components/Texts/DisplayText';
import { isFormValid, onGetFormData, onSetErrorsToForm } from '../../../../../shared/utility';
import { catalogs } from '../../../../../texts/esp/catalogs';
import { request_search_products } from '../../requests';

const DropsAndSolutionsButton = ({onInjectProductSearch}) => {

    const classes = useStyles()
    
    const [error, setError] = useState(null)
    const [products, setProducts] = useState([])
    

    const onSubmit = async () => {
      
        //Extrar the drops and solu
        if(!products.length){
            try {
                let params = {limit:100, offset:0, order:1, order_by:'title', query:'GOTAS'}
                const _drops = await request_search_products(params)
                params = {limit:100, offset:0, order:1, order_by:'title', query:'SOLUCIONES'}
                const _solu = await request_search_products(params)
                const _products = [..._drops.data, ..._solu.data]

                setProducts(_products)
                onInjectProductSearch(null, _products, 1)
                setError(null)
            } catch (error) {
                console.log(error)
            }
            return
        }

        onInjectProductSearch(null, products, 1)

    }


    return ( 
        <div>
            <ShortcutButton onClick={onSubmit} >Gotas y soluciones</ShortcutButton>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    search:{
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    }
}))


 
export default DropsAndSolutionsButton;

