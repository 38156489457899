import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { isFormValid, onGetErrorMessage, onGetFormData, onSetErrorsToForm } from '../../../../../shared/utility'
import { useStyles } from './styles'
import DisplayText from '../../../../../components/Texts/DisplayText'
import { request_create_optica } from '../../requests'
import SelectForm from '../../../../../components/Forms/SelectForm'
import { catalogs } from '../../../../../texts/esp/catalogs'
import InputForm from '../../../../../components/Forms/InputForm'
import SimpleModal from '../../../../../components/Overlays/SimpleModal'
import ContainedButton from '../../../../../components/Actions/ContainedButton'


const AddOpticaModal = props => {

    const { open, onClose, history } = props

    const classes = useStyles()
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')

    useEffect(() => {
        if (!open) {
            setForm(JSON.parse(JSON.stringify(formData)))
            setError(''); setLoading(false);
        }
    }, [open])


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        if(id === 'password') temp['passwordConfirmation'].value2 = temp[id].value
        setForm(temp)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length ){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            console.log('hay errores')
            console.log(errors)
            return
        } 

        //Extract data
        let data2send = onGetFormData(form)
        delete data2send.passwordConfirmation
        console.log(data2send)

        setLoading(true)
        try {
            const _optical = await request_create_optica(data2send);
            history.push(`/opticals/${_optical._id}`)
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
            setLoading(false)
        } 

    }

    let opticaType = null

    if(form.business_type_id.value == 1){
        opticaType = (<Grid item xs={12} >
            <InputForm data={form.identification_card} onChange={onChange} />
          </Grid>)
       // form.identification_card.isRequired = true
        
    }
    if(form.business_type_id.value == 2){
        //form.identification_card.config.label = 'NIT'
        opticaType = (<div className={classes.form2}>
                            <Grid container spacing={2}>
                            <Grid item xs={12} >
                                <InputForm data={form.identification_card} onChange={onChange} />
                            </Grid>
                            <Grid item xs={12} >
                                <InputForm data={form.lr_name} onChange={onChange} />
                            </Grid>
                            <Grid item xs={12} >
                                <InputForm data={form.lr_identification_card} onChange={onChange} />
                            </Grid>
                            </Grid>
                        </div>)
      //  form.identification_card_nit.isRequired = true
        //form.lr_identification_card.isRequired = true
        //form.lr_name.isRequired = true
    }

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div >
                <DisplayText variant='h5' color='primary' style={{ fontWeight: 500 }}>Agregar óptica</DisplayText>
                <DisplayText >Por favor ingresa los datos solicitados</DisplayText>
                <div className={classes.form}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <InputForm data={form.business_name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.email} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.password} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.passwordConfirmation} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <SelectForm data={form.business_type_id} onChange={onChange} />
                        </Grid>
                        {opticaType}
                    </Grid>
                </div>
                <ContainedButton color='primary' fullWidth onClick={onSubmit} loading={loading}>
                    Agregar
                </ContainedButton>
                <DisplayText color='error' align='center'>{error}</DisplayText>
            </div>
        </SimpleModal>
    )
}

export default AddOpticaModal


const formData = {
    business_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'business_name',
            type: 'text',
            fullWidth: true,
            label: 'Nombre/Razón social',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    email: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'email',
            fullWidth: true,
            label: 'Dirección de correo',
            helperText: 'Dirección de correo no válido'
        },
        rules: {
            type: 'email',
        }
    },
    password: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'password',
            type: 'password',
            fullWidth: true,
            label: 'Contraseña',
            helperText: 'Debe tener al menos 6 caractéres',
            autoComplete:'new-password'
        },
        rules: {
            type: 'distance',
            min: 6, max: 256
        }
    },
    passwordConfirmation: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'passwordConfirmation',
            type: 'password',
            fullWidth: true,
            label: 'Validar contraseña',
            helperText: 'Las contraseñas no coinciden'
        },
        rules: {
            type: 'equals',
        }
    },
    business_type_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...catalogs.opticals_types],
        config: {
            id: 'business_type_id',
            type: 'select',
            fullWidth: true,
            label: 'Tipo de negocio',
            helperText: 'Debe elegir una opción',
        },

    },
    identification_card: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'identification_card',
            type: 'text',
            fullWidth: true,
            label: 'Cédula/NIT',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    /* identification_card_nit: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'identification_card_nit',
            type: 'text',
            fullWidth: true,
            label: 'NIT',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    }, */
    lr_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'lr_name',
            type: 'text',
            fullWidth: true,
            label: 'Nombre del representante legal',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    lr_identification_card: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'lr_identification_card',
            type: 'text',
            fullWidth: true,
            label: 'Cédula del representante legal',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
}