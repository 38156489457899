import { Grid } from '@material-ui/core'
import React from 'react'
import DisplayText from '../../../../components/Texts/DisplayText'

const DisplayData = (props) =>{

    const {label, value} = props

    return(
        <Grid container spacing={1}>
            <Grid item ><DisplayText variant='subtitle2' color='primary'>{label}</DisplayText></Grid>
            <Grid item ><DisplayText variant='subtitle2'>{value}</DisplayText></Grid>
        </Grid>
    )
}

export default DisplayData