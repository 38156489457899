import { blueGrey, red } from "@material-ui/core/colors";
import axios from 'axios'

const primary_color = '#2A368B'
const secondary_color = '#0291ff'

export const config_data = {
    S3_SERVER_URL: 'https://ubelens-public.s3.amazonaws.com/'
}

export const palette = {
    layout:{
        primary:primary_color,
        secondary:secondary_color,
    },
    texts:{
        primary:primary_color,
        secondary:secondary_color,
        white:'#FFF',
        bluegrey_light:blueGrey[300],
        bluegrey:blueGrey[500],
        bluegrey_dark:blueGrey[700],
        bluegrey_super_dark:blueGrey[900],
        red:red[700]
    }
}

export const USER_TYPES = {
    ADMIN:1, 
    PATIENT:2,
    OPTOMETRIST:3,
    OPTICAL:4
}

export const ORDER_STATUS_TYPES = {
    PENDING:1,
    CONFIRMED:2,
    PREPARING:3,
    SENDING:4,
    DELIVERED:5,
    COMPLETED:6,
    CANCELLED:7
}
export const PRODUCT_TYPES = {
    MYIOPIA:1,
    ASTIGMATISM:2,
    MULTIFOCAL:3,
    COSMETICS:4,
    SOLUTIONS:5,
    DROPS:6
}

export const PAYMENT_STATUSES = {
    PENDING:1,
    COMPLETED:2,
    WITHDRAW:3,
    ERROR:4,
    CANCELLED:5,
    PARTIAL:6,
    INPROGRESS:7
}

export const EYE_CONFIG = {
    LEFT:'Ojo izquierdo',
    RIGHT:'Ojo derecho',
    EQUAL:'Misma formula'
}

export const mainServer = axios.create({
    baseURL: "https://api.ubelens.com/api",//'http://3.228.215.255/api2020',//,'http://127.0.0.1:3030',//,//http://127.0.0.1:3030
    headers: {'Content-Type': 'application/json'},
})

export const USERNAME_API = 'xandbox'
export const PASSWORD_API = 'tnHap6uKLUA57sTrcNzKQqAYdNCKfLwE'
export const BASIC_AUTH_HEADER = `Basic eGFuZGJveDp0bkhhcDZ1S0xVQTU3c1RyY056S1FxQVlkTkNLZkx3RQ==`

export const CONTACTLENSES_CATEGORIES = {
    "miopia-o-hipermetropia":"lentes,miopia",
    "astigmatismo":"lentes,astigmatismo",
    "presbicia":"lentes,presbicia",
    "lentes-cosmeticos":"lentes,cosmeticos",
    "uso-diario":"lentes,diario",
    "uso-quincenal":"lentes,quincenal",
    "uso-mensual":"lentes,mensual",
    "air-optix":"lentes,air optix",
    "dailies-aqua-comfort-plus":"lentes,dailies aqua",
    "freshlook":"lentes,freshlook",
    "soflens":"lentes,soflens",
    "pure-vision-2":"lentes,pure vision",
    "ultra":"lentes,ultra",
    "biomedics":"lentes,biomedics",
    "avaira-vitality":"lentes,avaira",
    "biofinity":"lentes,biofinity",
    "clariti":"lentes,clarity",
    "1-day":"lentes,1-day",
    "alcon":"lentes,alcon",
    "bausch-lomb":"lentes,bausch",
    "coopervision":"lentes,coopervision",
    "johnson-johnson":"lentes,johnson",
}

//////////////////////////////////////////////////////////////////////////////////
////                            SIDEBAR SETTINGS                               ///
//////////////////////////////////////////////////////////////////////////////////

export const sidebar_settings = {
    list:[
        {
            icon:'dashboard',
            name:'Dashboard',
            url:'/2',
            permissions:[1]
        },
        {
            icon:'supervisor_account',
            name:'Usuarios',
            url:'/users',
            permissions:[1]
        },
        {
            icon:'supervisor_account',
            name:'Optómetras',
            url:'/optometrists',
            permissions:[]
        },
        {
            icon:'supervisor_account',
            name:'Pacientes',
            url:'/patients',
            permissions:[]
        },
        {
            icon:'shopping_cart',
            name:'Pedidos',
            url:'/orders',
            permissions:[]
        },
        {
            icon:'local_offer',
            name:'Productos',
            url:'/products',
            permissions:[]
        },
        {
            icon:'groups',
            name:'Clientes',
            url:'/clients',
            permissions:[1]
        },
        {
            icon:'business',
            name:'Ópticas',
            url:'/opticals',
            permissions:[1]
        },
    ]
}