import { Avatar, Button, Divider, Grid } from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard'
import DisplayText from '../../../../components/Texts/DisplayText'
import { useStyles } from './styles'
import { onSetErrorsToForm, isFormValid, onInitForm, onGetSelectedFormData} from '../../../../shared/utility'
import InputForm from '../../../../components/Forms/InputForm'
import SelectForm from '../../../../components/Forms/SelectForm'
import { catalogs } from '../../../../texts/esp/catalogs'
import ContainedButton from '../../../../components/Actions/ContainedButton'
import { request_update_bank_account, request_optica } from '../requests'



const OpticalBankAccount = (props) => {

    const {data, id, onDispatchSuccess} = props

    //console.log('bank from props')
    //console.log(data)

    const classes = useStyles()

    const optical_init={
        name: 'hola',
        account_number:'',
        account_type_id:0
    }



    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [optical, setOptical] = useState(null)
    const [error, setError] = useState(null)

    useEffect(() => {
        const initModule = async() => {try {
            setLoading(true)
            setOptical(data)
            //console.log('hello from bank account')
            //console.log(data)
            //console.log(optical)
            //console.log('hola bank')
            //console.log(optical.name)
            const _form = onInitForm(form, data)
            setForm(_form)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }}
        initModule()
    }, [data])

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = null
        if(optical == null){
            data2send = onGetSelectedFormData(form, optical_init)
            //console.log(data2send)
        }else{
            data2send = onGetSelectedFormData(form, optical)
            //console.log(data2send)
            //console.log(id) 
        }
        

        setSending(true)
        try {
            const _optical = await request_update_bank_account(id, data2send);
            setOptical(_optical)
            setSending(false)
            onDispatchSuccess('Acción exitosa')
        } catch (error) {
            console.log(error)
            setError('Hubo un error')
            setSending(false)
        }

    }

    return(
        <div>
            <SimpleCard>
                <Grid container spacing={4} justify='flex-end'>
                    <Grid item xs={12}>
                        <DisplayText variant='subtitle1'>Cuenta bancaria</DisplayText>
                    </Grid>
                    <Grid item xs={12} >
                        <InputForm data={form.name} onChange={onChange} />
                    </Grid>
                    <Grid item xs={12} >
                        <InputForm data={form.account_number} onChange={onChange} />
                    </Grid>
                    <Grid item xs={12} >
                        <SelectForm data={form.account_type_id} onChange={onChange} />
                    </Grid>
                    <Grid item>
                        <ContainedButton color='primary' loading={sending} onClick={onSubmit}>Guardar</ContainedButton>
                    </Grid>
                </Grid>
            </SimpleCard>
        </div>
    )
}

export default OpticalBankAccount

const formData = {
    name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'name',
            type: 'text',
            fullWidth: true,
            label: 'Nombre(s)',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    account_number: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'account_number',
            type: 'text',
            fullWidth: true,
            label: 'Número de cuenta',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    account_type_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...catalogs.account_type],
        config: {
            id: 'account_type_id',
            type: 'select',
            fullWidth: true,
            label: 'Banco',
            helperText: 'Debe elegir una opción',
        },

    },
}