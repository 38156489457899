import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import Page from '../../../components/Structure/Page/Page'
import { onGetCatalogs } from '../../../shared/utility'
import { USER_TYPES } from '../../../variables/config'
import SimpleTable from './components/SimpleTable'
import { onGetFiltersFromURL, onGetParamsFromState, onUpdateURLArgs } from './custom_functions'
import AddUserModal from './modals/AddUserModal/AddUserModal'
import {request_patients, request_admin_patients, request_opticals} from './requests'

const Patients = props => {

    const {location, history, user} = props

    const [loading, setLoading] = useState(false)
    const [petients, setPatients] = useState([])
    const [opticals, setOpticals] = useState([])
    const [addModal, setAddModal] = useState(false)
    const [totalOptometrists, setTotalOptometrists] = useState(10)
    const [filter, setFilter] = useState({
        query:''
    })
    const [tableFilter, setTableFilter] = useState({
        limit:10,
        offset:0,
        order:1,
        order_by:'_id',
    })

    useEffect(() => {
        const initModule = async() => {try {
            if(!user) return
            console.log(user)
            setLoading(true)
            let _optometrists = await onGetFullRequest()
            setPatients(_optometrists.data)
            setTotalOptometrists(_optometrists.count)
            onUpdateURLArgs(filter, tableFilter)
            if(user.user_type_id === USER_TYPES.ADMIN){
                const _opticals = await request_opticals()
                setOpticals(onGetCatalogs(_opticals.data,'_id', 'business_name'))
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }}
        initModule()
    }, [user, filter, tableFilter])

    const onGetFullRequest = async() => {
        let response = []
        try {
            const params = onGetParamsFromState(filter, tableFilter)
            if(user.user_type_id === USER_TYPES.ADMIN) response = await request_admin_patients(params)
            if(user.user_type_id === USER_TYPES.OPTICAL) response = await request_patients(user._id, params)
            if(user.user_type_id === USER_TYPES.OPTOMETRIST) response = await request_patients(user.optical_id, params)

        } catch (error) {
            
        }
        return response

}

    useEffect(() => {
        const initModule = async() => {try {
            if(location.search){
                const response = onGetFiltersFromURL(location.search, filter, tableFilter)
                setFilter(response.filter)
                setTableFilter(response.tableFilter)
            }else{
                onUpdateURLArgs(filter, tableFilter)
            }
        } catch (error) {
        }}
        initModule()
    }, [])

    

    const onSelectedItem = (item) => {
        const {_id} = item
        history.push(`/patients/${_id}`)
    }
  
    const onChangeFilter = (tag, value) => { // Change generic filter
        let newFilter = onUpdateFilter(tag, value)
        setFilter(newFilter)
    }

    const onUpdateFilter = (id,value) => {
        let temp = {...filter}
        temp[id] = value
        return temp
    }

    const onUpdateCompleted = async() => {
        let _optometrists = await onGetFullRequest()
        setPatients(_optometrists.data)
        setTotalOptometrists(_optometrists.count)
        onUpdateURLArgs(filter, tableFilter)
        setAddModal(false)
    }

    let can_create = false
    if(user && (user.user_type_id === 3 || user.user_type_id === 4 || user.user_type_id === 1)) can_create = true

    return(
        <Page title='Pacientes' >
            <AddUserModal opticals={opticals} history={history} user={user} open={addModal} onClose={() => setAddModal(false)} onUpdateCompleted={onUpdateCompleted}/>
            <SimpleTable
                data={petients} 
                filter={filter}
                tableFilter={tableFilter}
                total={totalOptometrists}
                loading={loading}
                onAddRegister={can_create ? () => setAddModal(true) : null}
                onChangeFilter={onChangeFilter}
                onUpdateTableFilter={(data) => setTableFilter(data)}
                onSelectedItem={onSelectedItem}/>
        </Page>
    )
}

const mapStateToProps = state => {
    return{
        user: state.user
    }
}

export default connect(mapStateToProps)(Patients)