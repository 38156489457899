import { Card, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(theme => ({
    root:{
        borderRadius:24,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
        padding:'32px 32px 0px 32px'
    }
}))

const SimpleCard = props => {
    const classes = useStyles()
    return(
        <Card className={classes.root}>
            {props.children}
        </Card>
    )
}

export default SimpleCard