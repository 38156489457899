import { Avatar, Button, Divider, Grid } from '@material-ui/core'
import React from 'react'
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard'
import AvatarPicture from '../../../../components/Structure/DisplayData/AvatarPicture'
import DisplayIconData from '../../../../components/Structure/DisplayData/DisplayIconData'
import UserAvatar from '../../../../components/Structure/DisplayData/UserAvatar'
import DisplayText from '../../../../components/Texts/DisplayText'
import { isValidDocument, onGetFullname } from '../../../../shared/utility'
import nouser from '../../../../assets/nouser.png'

const UserInfoCard = (props) => {

    const {image, first_name, last_name, email, phone_number, optical, onChangePicture, onChangePassword} = props

    const fullname = onGetFullname(first_name, last_name)

    return(
        <div>
            <SimpleCard>
                <Grid container justify='center' spacing={2}>
                    <Grid item>
                        <AvatarPicture can_edit nopicture={nouser} onChangePicture={onChangePicture} imgSrc={image}/>
                    </Grid>
                    
                    <Grid item xs={12}>
                        <DisplayText variant='subtitle1' align='center'>{fullname}</DisplayText>
                    </Grid>
                    
                    <Grid item xs={12}>
                        <div style={{wordWrap:'break-word', wordBreak:'break-all'}}>
                        <DisplayIconData icon='mail' data={email}/>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <DisplayIconData icon='phone' data={phone_number}/>
                    </Grid>
                    <Grid item xs={12}>
                        <DisplayIconData icon='business' data={optical}/>
                    </Grid>
                    <Grid item>
                        <Button onClick={onChangePassword} variant='text'>Cambiar contraseña</Button>
                    </Grid>
                </Grid>
            </SimpleCard>
        </div>
    )
}

export default UserInfoCard