import React, {useEffect, useState} from 'react'
import Page from '../../components/Structure/Page/Page'
import { Grid, makeStyles } from '@material-ui/core'
import CarouselContainer from '../../components/Structure/CarouselContainer/CarouselContainer'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import CustomerSlider from '../../components/Structure/CustomerSlider/CustomerSlider';
import {content} from './content'
import ProductContainer from './components/ProductContainer';
import { withRouter } from 'react-router-dom';
import UbelensBenefits from './components/UbelensBenefits';
import AuthentificationContainer from './components/AuthentificationContainer';
import { connect } from 'react-redux';
import { request_products } from './requests';


const useStyles = makeStyles(theme => ({
    root:{
        //marginTop:98
    },
    
}))

const Home = props => {

    const classes = useStyles()
    const {isauth, history} = props
    const [contactLenses, setContactLenses] = useState([])
    const [solutions, setSolutions] = useState([])
    const [drops, setDrops] = useState([])

    useEffect(() => {
        const initModule = async() => {
            console.log("Init home")
            const _lenses = await request_products('lentes')
            const _solutions = await request_products('soluciones')
            const _drops = await request_products('gotas')
            setContactLenses(_lenses)
            setSolutions(_solutions)
            setDrops(_drops)
        }
        window.scrollTo(0,0)
        initModule()
    },[])

    const onGo2Page = (url) => {
        console.log(url)
    }

    const onSelectedProduct = (url, id) => {
        window.scrollTo(0,0)
        props.history.push(`${url}/${id}`)
    }

    



    return(
        <Page>
            Home
        </Page>
        
    )
}

const mapStateToProps = state => {
    return {
        isauth: state.isauth,
    }
}

export default withRouter( connect(mapStateToProps)(Home))