import { Avatar, Button, Divider, Grid } from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard'
import DisplayText from '../../../../components/Texts/DisplayText'
import { useStyles } from './styles'
import { onSetErrorsToForm, isFormValid, onInitForm, onGetSelectedFormData} from '../../../../shared/utility'
import InputForm from '../../../../components/Forms/InputForm'
import SelectForm from '../../../../components/Forms/SelectForm'
import { catalogs } from '../../../../texts/esp/catalogs'
import ContainedButton from '../../../../components/Actions/ContainedButton'
import { request_update_address } from '../requests'



const OpticalAddress = (props) => {

    const {data, id, onDispatchSuccess} = props

    const classes = useStyles()

    const optical_init={
        first_name:'',
        last_name:'',
        phone:'',
        address1:'',
        province:'',
        city:''
    }

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [optical, setOptical] = useState(optical_init)
    const [error, setError] = useState(null)

    useEffect(() => {
        const initModule = async() => {try {
            setLoading(true)
            setOptical(data)
            console.log(data)
            //console.log(optical)
            const _form = onInitForm(form, data)
            setForm(_form)
            if(data && data.province){
                const provice_catalog = catalogs.cities[data.province]
                if(provice_catalog) _form.city.options = [...provice_catalog]
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }}
        initModule()
    }, [data])

    /* const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    } */

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        if(id === 'province'){
            //console.log('Actualizar ciudades/barrios')
            temp.city.value = null
            temp.city.options = catalogs.cities[data.value]
        }
        setForm(temp)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        //console.log(optical)
        let data2send = null
        if(optical == null){
            data2send = onGetSelectedFormData(form, optical_init)
            //console.log(data2send)
        }else{
            data2send = onGetSelectedFormData(form, optical)
            //console.log(data2send)
            //console.log(id) 
        }
        
        console.log('data2send from adress')
        console.log(data2send)
 
        setSending(true)
        try {
            const _optical = await request_update_address(id, data2send);
            setOptical(_optical)
            setSending(false)
            onDispatchSuccess('Acción realizada con éxito')
        } catch (error) {
            console.log(error)
            setError('Hubo un error')
            setSending(false)
        }

    }

    return(
        <div>
            <SimpleCard>
                <Grid container spacing={4} justify='flex-end'>
                    <Grid item xs={12}>
                        <DisplayText variant='subtitle1'>Dirección Fiscal</DisplayText>
                    </Grid>
                    <Grid item xs={12}>
                        <InputForm data={form.address1} onChange={onChange}/>
                    </Grid>
                    <Grid item xs={12}>
                        <InputForm data={form.address2} onChange={onChange}/>
                    </Grid>
                    <Grid item xs={12} >
                        <SelectForm data={form.province} onChange={onChange}/>
                    </Grid>
                    <Grid item xs={12}>
                        <SelectForm data={form.city} onChange={onChange}/>
                    </Grid>
                    <Grid item xs={12} >
                        <SelectForm data={form.country} onChange={onChange}/>
                    </Grid>
                    <Grid item>
                        <ContainedButton color='primary' loading={sending} onClick={onSubmit}>Guardar</ContainedButton>
                    </Grid>
                </Grid>
            </SimpleCard>
        </div>
    )
}

export default OpticalAddress

const formData = {
    address1:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
          id:'address1',
          type:'text',
          fullWidth: true,
          label:'Dirección (Línea 1)',
          helperText:'El numero de caracteres debe ser entre 1 y 512'
        },
        rules:{
          type:'distance',
          max:512
        }
    },
    address2:{ // Departamento, barrio
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
          id:'address2',
          type:'text',
          fullWidth: true,
          label:'Barrio, etc...',
          helperText:'El numero de caracteres debe ser entre 1 y 512'
        },
        rules:{
          type:'distance',
          max:512
        }
    },
    city:{ // Departamento, barrio
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config:{
          id:'city',
          type:'select',
          fullWidth: true,
          label:'Ciudad',
          helperText:''
        },
        rules:{
          type:'select',
        }
    },
    province:{ // Departamento, barrio
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...catalogs.departments],
        config:{
          id:'province',
          type:'select',
          fullWidth: true,
          label:'Departamento',
          helperText:'Este campo es obligatorio'
        },
        rules:{
          type:'select',
        }
    },
    country:{ // Departamento, barrio
        value: 'Colombia',
        error: false,
        isVisited: true,
        isRequired: true,
        isValid: true,
        options:[{id:1, value:'Colombia', label:'Colombia'}],
        config:{
          id:'country',
          type:'select',
          fullWidth: true,
          label:'País',
          helperText:'Este campo es obligatorio'
        },
        rules:{
          type:'select',
        }
    },    
}