const { mainServer } = require("../../../variables/config");

export const request_admin_patients = async(params) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/patient/all`, {params:{...params}})
    return response.data.data
}

export const request_patients = async(id, params) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/patient/optical/${id}/all`, {params:{...params}})
    return response.data.data
}

export const request_create_patient = async(data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.post(`/patient`, data)
    return response.data.data
}

export const request_opticals = async() => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/opticals/all`)
    return response.data.data
}