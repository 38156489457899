import React from 'react'
import { CircularProgress, Grid, Icon, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core'
import DisplayText from '../../../../components/Texts/DisplayText'
import { onFormatNumber, onGetFullname } from '../../../../shared/utility'
import { blueGrey, grey } from '@material-ui/core/colors'
import SearchBar from './SearchBar'
import FilterButton from './FilterButton'
import ActionBar from './ActionBar'
import UserAvatarName from '../../../../components/Structure/DisplayData/UserAvatarName'
import empty from '../../../../assets/empty.png'
import UserStatus from '../../../../components/Structure/DisplayData/UserStatus'
import { nodata } from '../../../../variables/texts'

const statusCatalog = [{id:-1, value:-1, label:'Todos'}, {id:1, value:1, label:'Activo'},{id:0, value:0, label:'Inactivo'}]
const searchCatalog = [{id:1, value:'first_name', label:'Nombre '},{id:2, value:'username', label:'Usuario'}]

const header = [
    {id:1, label:'Nombre de la óptica'},
    {id:2, label:'Cédula/NIT'},
    {id:3, label:'Tipo'},
    {id:4, label:'Correo'},
    {id:5, label:'Departamento'},
    {id:6, label:'Estatus'},
    //{id:6, label:''},
]


const useStyles = makeStyles(theme => ({
    root:{},
    paper:{
        borderRadius:16,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    cell:{
        borderColor:grey[200],
    },
    row:{
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    cellHeader:{
        borderColor:grey[300],
        paddingTop:16,
        paddingBottom:16
    },
    button:{
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    toolbar:{
        marginBottom:16
    },
    actions:{
        marginTop:16
    },
    table:{
        minWidth:800
    },
    tableWrapper:{
        overflowX: 'auto',
    },
    nodata:{
        marginTop:40
    },
    div:{
        width:'100%',
        height:'100%'
    }
}))

const SimpleTable = props => {

    const classes = useStyles()
    const {data, onAddRegister, filter, onChangeFilter, total, tableFilter, onUpdateTableFilter, onSelectedItem, loading, onDeleteItem} = props

    const deletePrueba = () => {
        console.log('delete')
    }

    return(
        <div>
            <div className={classes.toolbar}>
                <Grid container justify='flex-end' spacing={2} alignItems='flex-end' > 
                    <Grid item>
                        <Grid container wrap='nowrap' spacing={2} alignItems='flex-end'>
                            <Grid item><SearchBar value={filter.query} onChange={(value) => onChangeFilter('query', value)} /></Grid>
                            <Grid item><IconButton className={classes.button} onClick={onAddRegister}><Icon>add</Icon></IconButton></Grid>
                        </Grid>
                    </Grid>
                    
                </Grid>
            </div>
            {loading ? (
                <Grid container justify='center'>
                    <Grid item><CircularProgress size={32}/></Grid>
                </Grid>
            ) : null}
            <Paper className={classes.paper}>
                {data && data.length ? (
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table}>
                            {header ? (
                                <TableHead>
                                    <TableRow>
                                        {header.map(item=>{
                                            return(
                                                <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                    <DisplayText variant='subtitle1' >{item.label}</DisplayText>
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                            ):null}
                            <TableBody>
                                {data.map((item,key) => {
                                    //const {first_name, last_name, email, image,status_id} = item
                                    //const fullname = onGetFullname(first_name, last_name)
                                    const {business_name,identification_card, business_type_id, image,status_id, email, billing_address} = item
                                    //console.log(billing_address)

                                    return(
                                        <TableRow key={key.toString()} className={classes.row} >
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><UserAvatarName name={business_name} image={image}/></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><DisplayText>{identification_card}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><DisplayText>{business_type_id === 1 ? 'Natural': 'Jurídica'}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><DisplayText>{email}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><DisplayText>{billing_address ? billing_address.city ? billing_address.city : nodata : nodata}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><UserStatus status_id={status_id}/></TableCell>
                                            {/*<TableCell className={classes.cell}><IconButton onClick={() => onDeleteItem(item)} ><Icon>delete</Icon></IconButton></TableCell>*/}
                                        </TableRow>         
                                    )
                                })}
                                
                            </TableBody>
                        </Table>
                    </div>
                    
                ): null}
            </Paper>
            {data && data.length ? <div className={classes.actions}>
                <ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div> : null}
            {data.length === 0 && !loading  ? (
                <div className={classes.nodata}>
                    <Grid container justify='center' alignItems='center' direction='column'>
                        <Grid item>
                            <img src={empty} alt='' width={180}/>
                        </Grid>
                        <Grid item>
                            <DisplayText>No existen ópticas registradas</DisplayText>
                        </Grid>
                    </Grid>
                </div>
                
            ) : null}
            
        </div>
    )
}

export default SimpleTable