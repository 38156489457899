import { USER_TYPES } from "../../variables/config";

export const sidebar_settings = {
    list:[
        /*{
            icon:'dashboard',
            name:'Dashboard',
            url:'/2',
            permissions:[1]
        },*/
        {
            icon:'manage_accounts',
            name:'Usuarios',
            url:'/users',
            permissions:[1]
        },
        {
            icon:'health_and_safety',
            name:'Optómetras',
            url:'/optometrists',
            permissions:[USER_TYPES.ADMIN, USER_TYPES.OPTICAL]
        },
        {
            icon:'assignment_ind',
            name:'Pacientes',
            url:'/patients',
            permissions:[]
        },
        {
            icon:'shopping_cart',
            name:'Pedidos',
            url:'/orders',
            permissions:[]
        },
        {
            icon:'local_offer',
            name:'Productos',
            url:'/products',
            permissions:[1]
        },
        {
            icon:'local_offer',
            name:'Productos',
            url:'/public-products',
            permissions:[2,3,4]
        },
        /*{
            icon:'groups',
            name:'Clientes',
            url:'/clients',
            permissions:[1]
        },*/
        {
            icon:'business',
            name:'Ópticas',
            url:'/opticals',
            permissions:[1]
        },
    ]
}