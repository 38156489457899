import React, {useState, useEffect} from 'react'
import { Button, CircularProgress, Dialog,  Grid, Grow, Icon, IconButton, Typography } from '@material-ui/core'
import Heading from '../../components/Texts/Heading'
import { blueGrey } from '@material-ui/core/colors'
import InputForm from '../../components/Forms/InputForm'
import { isFormValid } from '../../shared/utility'
import { useStyles } from './styles'
import DisplayText from '../../components/Texts/DisplayText'
import { request_login, request_profile } from './requests'
import {connect} from 'react-redux'
import * as actionTypes from '../../store/actions'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow  ref={ref} {...props} timeout={500}/>;
});

const SingInModal = props => {

    const {open, onClose, onOpenModal, onChangeAuthStatus, onUpdateUser} = props

    const classes = useStyles()
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [isValid, setIsValid] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')

    useEffect(() => {
        if(!open){
            setForm(JSON.parse(JSON.stringify(formData)))
            setError(''); setLoading(false)
        }
    }, [open])


    const onChange = (data) => {
        let temp = {...form}
        const id = data.config.id
        temp[id] = {...data}
        setForm(temp)
        setIsValid(isFormValid(temp))
    }

    const onSubmit = async() => {
        if(!isValid){setError('Llene los cambios solicitados')
        return
        }
        let data2send = {}
        Object.keys(form).forEach(item => {data2send = {...data2send, [item]:form[item].value}})
        setLoading(true)
        try {   
            const response = await request_login(data2send)
            await localStorage.setItem('token_data', JSON.stringify(response))
            const user = await request_profile()
            onUpdateUser(user)
            onChangeAuthStatus(true)
            onClose('signin')
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
        
    }

    return(
        <Dialog open={open} maxWidth='xs' fullWidth classes={{paper:classes.paper}} className={classes.root}
        TransitionComponent={Transition}>
            <div className={classes.buttonContainer}><IconButton onClick={() => onClose('signin')}><Icon >close</Icon></IconButton></div>
            <div className={classes.container}>
                <Typography variant='h4' color='primary' style={{fontWeight:500}}>¡Bienvenido!</Typography>
                <Typography variant='h6' style={{color:blueGrey[700]}}>Por favor ingresa tus datos</Typography>
                <div className={classes.form}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <InputForm data={form.email} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.password} onChange={onChange}/>
                        </Grid>
                    </Grid>
                </div>
                <Button variant='contained' color='primary' size='large' fullWidth onClick={onSubmit}>
                    Acceder
                    {loading ? (<CircularProgress style={{width:24, height:24,marginLeft:8,color:'white'}}/>) : null}
                </Button>
                <div className={classes.linkContainer}>  
                    <Grid container alignItems='center' spacing={1} wrap='nowrap' justify='center'>
                        <Grid item>
                            <Heading color='bluegrey_dark'>¿Aún no tienes una cuenta?</Heading>
                        </Grid>
                        <Grid item>
                            <div className={classes.link}> Regístrate</div>
                        </Grid>
                    </Grid>
                    <div className={classes.link} onClick={() => onOpenModal('forgottenpassword')}> Olvidé mi contraseña</div>
                </div>
                <DisplayText color='red' align='center'>{error}</DisplayText>
            </div>
        </Dialog>
    )
}

const mapDispatchToProps = dispatch => {
    return{
        onChangeAuthStatus: (isauth) => dispatch({type: actionTypes.CHANGE_AUTH_STATUS, isauth }),
        onUpdateUser: (user) => dispatch({type: actionTypes.AUTH_UPDATE_USER, user })
    }
}


export default connect(null, mapDispatchToProps)(SingInModal)


const formData = {
    email:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
          id:'email',
          type:'email',
          fullWidth: true,
          label:'Dirección de correo',
          helperText:'Dirección de correo no válido'
        },
        rules:{
          type:'email',
        }
    },
    password:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
          id:'password',
          type:'password',
          fullWidth: true,
          label:'Contraseña',
          helperText:'Debe contener 6 a 20 caracteres, incluyendo al menos 1 dígito, 1 mayúscula y 1 minúscula'
        },
        rules:{
          type:'none',
        }
    },
}