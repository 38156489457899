import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import Page from '../../../components/Structure/Page/Page'
import { USER_TYPES } from '../../../variables/config'
import SimpleTable from './components/SimpleTable'
import { onGetFiltersFromURL, onGetParamsFromState, onUpdateURLArgs } from './custom_functions'
import AddUserModal from './modals/AddUserModal/AddUserModal'
import {request_optometrists, request_admin_optometrists} from './requests'

const Optometrists = props => {

    const {location, history, user} = props

    const [loading, setLoading] = useState(false)
    const [optometrists, setOptometrists] = useState([])
    const [addModal, setAddModal] = useState(false)
    const [totalOptometrists, setTotalOptometrists] = useState(10)
    const [filter, setFilter] = useState({
        query:''
    })
    const [tableFilter, setTableFilter] = useState({
        limit:10,
        offset:0,
        order:1,
        order_by:'_id',
    })

    useEffect(() => {
        const initModule = async() => {try {
            if(!user) return
            setLoading(true)
            let _optometrists = await onGetFullRequest()
            setOptometrists(_optometrists.data)
            setTotalOptometrists(_optometrists.count)
            onUpdateURLArgs(filter, tableFilter)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }}
        initModule()
    }, [user, filter, tableFilter])

    const onGetFullRequest = async() => {
        let response = []
        try {
            const params = onGetParamsFromState(filter, tableFilter)
            if(user.user_type_id === 1) response = await request_admin_optometrists(params)
            if(user.user_type_id !== 1) response = await request_optometrists(user._id, params)
        } catch (error) {
            
        }
        return response

}

    useEffect(() => {
        const initModule = async() => {try {
            if(location.search){
                const response = onGetFiltersFromURL(location.search, filter, tableFilter)
                setFilter(response.filter)
                setTableFilter(response.tableFilter)
            }else{
                onUpdateURLArgs(filter, tableFilter)
            }
        } catch (error) {
        }}
        initModule()
    }, [])

    

    const onSelectedItem = (item) => {
        const {_id} = item
        history.push(`/optometrists/${_id}`)
    }
  
    const onChangeFilter = (tag, value) => { // Change generic filter
        let newFilter = onUpdateFilter(tag, value)
        setFilter(newFilter)
    }

    const onUpdateFilter = (id,value) => {
        let temp = {...filter}
        temp[id] = value
        return temp
    }

    const onUpdateCompleted = async() => {
        let _optometrists = await onGetFullRequest()
        setOptometrists(_optometrists.data)
        setTotalOptometrists(_optometrists.count)
        onUpdateURLArgs(filter, tableFilter)
        setAddModal(false)
    }


    let can_add = true
    if(user && user.user_type_id === USER_TYPES.ADMIN) can_add = false

    return(
        <Page title='Optómetras' >
            <AddUserModal history={history} admin={!can_add} userID={user ? user._id : null} open={addModal} onClose={() => setAddModal(false)} onUpdateCompleted={onUpdateCompleted}/>
            <SimpleTable
                data={optometrists} 
                filter={filter}
                tableFilter={tableFilter}
                total={totalOptometrists}
                loading={loading}
                //onAddRegister={can_add ? () => setAddModal(true) : null}
                onAddRegister={ () => setAddModal(true) }
                onChangeFilter={onChangeFilter}
                onUpdateTableFilter={(data) => setTableFilter(data)}
                onSelectedItem={onSelectedItem}/>
        </Page>
    )
}

const mapStateToProps = state => {
    return{
        user: state.user
    }
}

export default connect(mapStateToProps)(Optometrists)