import React from 'react'
import { CircularProgress, Grid, Icon, IconButton, LinearProgress, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core'
import DisplayText from '../../../../components/Texts/DisplayText'
import { onFormatNumber, onGetFormatNumber, onGetFullname } from '../../../../shared/utility'
import { blueGrey, grey } from '@material-ui/core/colors'
//import SearchBar from './SearchBar'
//import ActionBar from './ActionBar'
import empty from '../../../../assets/empty.png'
import moment from 'moment-timezone'
import { nodata } from '../../../../variables/texts'
import OrderStatus from '../../../../components/Structure/DisplayData/OrderStatus'
import FilterDate from '../../../../components/Actions/Buttons/FilterDate'
import FilterButton from '../../../../components/Actions/Buttons/FilterButton'
import {catalogs} from '../../../../texts/esp/catalogs'
import ActionBar from '../../../../components/Actions/ActionBar'
import SearchBar from '../../../../components/Actions/SearchBar'

//const statusCatalog = [{id:-1, value:-1, label:'Todos'}, {id:1, value:1, label:'Activo'},{id:0, value:0, label:'Inactivo'}]
//const searchCatalog = [{id:1, value:'first_name', label:'Nombre '},{id:2, value:'username', label:'Usuario'}]

const header = [
    {id:1, label:' ID'},
    {id:2, label:'Fecha de pedido'},
    //{id:3, label:'Paciente'},
    //{id:3, label:'Optómetra'},
    {id:4, label:'Total'},
    {id:5, label:'Estatus'},
]


const useStyles = makeStyles(theme => ({
    root:{},
    paper:{
        borderRadius:16,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    cell:{
        borderColor:grey[200],
    },
    row:{
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    cellHeader:{
        borderColor:grey[300],
        paddingTop:16,
        paddingBottom:16,
    },
    button:{
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    toolbar:{
        marginBottom:16
    },
    actions:{
        marginTop:16
    },
    table:{
        minWidth:800
    },
    tableWrapper:{
        overflowX: 'auto',
    },
    nodata:{
        marginTop:40
    }
}))

const SimpleTable = props => {

    const classes = useStyles()
    const {data, onAddRegister, filter, onChangeFilter, total, tableFilter, onUpdateTableFilter, onSelectedItem, loading,
            startDate, onChangeDate, endDate, status} = props
    return(
        <div>
            <div className={classes.toolbar}>
                <Grid container  spacing={2} >
                    <Grid item><FilterDate id='start_date' label='Fecha inicial' value={startDate} onChange={onChangeDate}/></Grid>
                    <Grid item><FilterDate id='end_date' label='Fecha final' value={endDate} onChange={onChangeDate}/></Grid>
                    <Grid item><FilterButton label='Estatus' options={catalogs.order_statuses} selected={status} onChange={(val) => onChangeFilter('status', val)}/></Grid>
                    <Grid item xs={12}><SearchBar value={filter.query} onChange={(value) => onChangeFilter('query', value)} /></Grid>
                    {onAddRegister ? <Grid item><IconButton className={classes.button} onClick={onAddRegister}><Icon>add</Icon></IconButton></Grid> : null}
                </Grid>
            </div>
            {loading ? (
                <div style={{marginBottom:16}}>
                <LinearProgress color='primary'/>
                </div>
            ) : null}
            {/* <Paper className={classes.paper}> */}
                {data && data.length ? (
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table}>
                            {header ? (
                                <TableHead>
                                    <TableRow>
                                        {header.map(item=>{
                                            return(
                                                <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                    <DisplayText variant='subtitle2' >{item.label}</DisplayText>
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                            ):null}
                            <TableBody>
                                {data.map((item,key) => {
                                    const {created_at, total, email, payment_status_id, status_id, order_id, payment, user, optometrist} = item

                                    const timezone = moment.tz.guess()
                                    let current_date = moment(created_at).tz(timezone)
                                    current_date = current_date.isValid() ? current_date.format('DD MMM YY') : nodata

                                    let _payment_status = null
                                    if(payment) _payment_status = payment.status_id

                                    return(
                                        <TableRow key={key.toString()} className={classes.row} onClick={() => onSelectedItem(item)}>
                                            <TableCell className={classes.cell}><DisplayText variant='subtitle1'>{order_id}</DisplayText></TableCell>
                                            <TableCell className={classes.cell}><DisplayText>{current_date}</DisplayText></TableCell>
                                            {/* <TableCell className={classes.cell}><DisplayText>{user ? user.email : nodata}</DisplayText></TableCell> */}
                                            {/* <TableCell className={classes.cell}><DisplayText>{optometrist ? optometrist.email : nodata}</DisplayText></TableCell> */}
                                            <TableCell className={classes.cell}><DisplayText>{onGetFormatNumber(total)}</DisplayText></TableCell>
                                            <TableCell className={classes.cell}><OrderStatus status_id={status_id}/></TableCell>
                                        </TableRow>
                                    )
                                })}
                                
                            </TableBody>
                        </Table>
                    </div>
                    
                ): null}
            {/* </Paper> */}
            {data && data.length ? <div className={classes.actions}>
                <ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div> : null}
            {data.length === 0 && !loading ? (
                <div className={classes.nodata}>
                    <Grid container justify='center' alignItems='center' direction='column'>
                        <Grid item>
                            <img src={empty} alt='' width={180}/>
                        </Grid>
                        <Grid item>
                            <DisplayText>No existen órdenes registradas</DisplayText>
                        </Grid>
                    </Grid>
                </div>
                
            ) : null}
            
        </div>
    )
}

export default SimpleTable