import React, {useCallback, useState} from 'react'
import { fade, makeStyles } from '@material-ui/core'
import { grey, teal } from '@material-ui/core/colors'
import {useDropzone} from 'react-dropzone'
import cx from 'classnames'
import DisplayText from '../../../../components/Texts/DisplayText'


const useStyles = makeStyles(theme => ({
    root:{},
    container:{
        background:grey[100],
        borderRadius:12,
        padding:32,
        transition: theme.transitions.create(["background"], {
            easing: theme.transitions.easing.sharp, 
            duration: 500,
        })
    },
    containerIn:{
        background:fade(theme.palette.primary.light,0.7),
        cursor:'pointer'
    }
}))

const CustomDropzone = props => {
    const classes = useStyles()
    const {onDropFile} = props
    const [enter, setEnter] = useState(false)
    const onDrop = useCallback((files) => {
        onDropFile(files[0])
        setEnter(false)
    })
    const onDragEnter = useCallback((files) => {
        setEnter(true)
    })
    const onDragLeave = useCallback((files) => {
        setEnter(false)
    })

    const {getRootProps, getInputProps} = useDropzone({onDrop,onDragEnter, onDragLeave})

    return(
        <div {...getRootProps()}
        className={cx({
            [classes.container]: true,
            [classes.containerIn]:enter
        })}>
            <input {...getInputProps()} />
            <DisplayText align='center'>
                Arrastre o de clic para subir una imagen
            </DisplayText>
        </div>
    )
}

export default CustomDropzone