import { blueGrey } from "@material-ui/core/colors";

export const general_theme = {
    palette: {
      primary: { main: '#2A368B' }, // Purple and green play nicely together. '#38baac'
      secondary: { main: '#0291ff' }, 
      text:{
        primary:blueGrey[900],
        secondary:blueGrey[500]
      }
    },
    typography: {
      useNextVariants: true,
      fontFamily:[
        'Work Sans',
      ].join(','),
    },
    overrides:{
      MuiTypography:{
          h1:{
            fontSize:'4.5rem',
            fontWeight:500
          },
          h2:{
            fontSize:'3.75rem',
          },
          h3:{
            fontSize:'3rem',
          },
          h4:{
            fontWeight:500,
            fontSize:'2.250rem',
          },
          h5:{
              fontSize:'1.875rem',
              fontWeight:500
          },
          h6:{
            fontSize:'1.750rem',
            fontWeight:500,
          },
          subtitle1:{
            fontSize:'1.125rem',
            fontWeight:500,
          },
          subtitle2:{
            fontSize:'1rem',
            fontWeight:500,
          },
          body1:{
              fontSize:'1.125rem'
          },
          body2:{
              fontSize:'1rem'
  
          },
          caption:{
          },
          button:{
            fontSize:'1.125rem'
        },
      }
    }
  }