import React, {useState, useEffect} from 'react'
import { Grid, Icon, IconButton, makeStyles } from '@material-ui/core'
//import { request_upload_image, request_upload_document, request_delete_image } from '../requests'
import {getFileExtension, isFormValid, onGetSelectedFormData, onInitForm, onSetErrorsToForm} from '../../../../shared/utility'
import DisplayText from '../../../../components/Texts/DisplayText'
import SimpleModal from '../../../../components/Overlays/SimpleModal'
import SimpleFile from '../../../../components/Forms/SimpleFile'
import ContainedButton from '../../../../components/Actions/ContainedButton'
import InputForm from '../../../../components/Forms/InputForm'
import SelectForm from '../../../../components/Forms/SelectForm'
import { catalogs } from '../../../../texts/esp/catalogs'
import { request_order, request_update_billing_address } from '../requests'


const useStyles = makeStyles(theme => ({
    paper:{
        borderRadius:30
    },
    button:{
        borderRadius:32,
        textTransform:'none'
    }
}))

const BillingAddressModal = props => {

    const classes = useStyles()
    
    //////////////////////////// MULTIPLE STATES AND PROPS  ///////////////////////////////
    const {inputdata, onClose, open, id, onUpdateData} = props
    const [file, setFile] = useState(null) 
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))


    useEffect(() => {
        if(open){
            setError(null)
            let _form = JSON.parse(JSON.stringify(formData))
            if(inputdata){
                _form = onInitForm(form, inputdata)
                if(inputdata.province){
                    const provice_catalog = catalogs.cities[inputdata.province]
                    if(provice_catalog) _form.city.options = [...provice_catalog]
                }
            }
            setForm(_form)
        }
    },[open])

    const onChange = (data) => {
        let temp = {...form}
        const id = data.config.id
        temp[id] = {...data}
        if(id === 'province'){
            console.log('Actualizar ciudades/barrios')
            temp.city.value = null
            temp.city.options = catalogs.cities[data.value]
        }
        setForm(temp)
    }

    const onSubmit = async() => {
        
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        let data2send = onGetSelectedFormData(form, inputdata)

        setLoading(true)
        try {
            await request_update_billing_address(id, data2send)
            const _order = await request_order(id)
            onUpdateData(_order)
            setLoading(false)
            setError(null)
            onClose()
        } catch (error) {
            console.log(error)
            setError('Hubo un error')
            setLoading(false)
        }
    }




    return(
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <DisplayText variant='h6'>Dirección de facturación</DisplayText>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <InputForm data={form.address1} onChange={onChange}/>
                            </Grid>
                            <Grid item xs={12}>
                                <InputForm data={form.address2} onChange={onChange}/>
                            </Grid>
                            <Grid item xs={12}>
                                <SelectForm data={form.province} onChange={onChange}/>
                            </Grid>
                            <Grid item xs={12}>
                                <SelectForm data={form.city} onChange={onChange}/>
                            </Grid>
                            
                            <Grid item xs={12}>
                                <SelectForm data={form.country} onChange={onChange}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {error ? <DisplayText variant='subtitle2' color='error' align='center'>{error}</DisplayText> : null}
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs/>
                            <Grid item>
                                <ContainedButton color='primary' onClick={onSubmit} loading={loading}>Guardar</ContainedButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </SimpleModal>
    )
}

export default BillingAddressModal

const formData = {
    address1:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
          id:'address1',
          type:'text',
          fullWidth: true,
          label:'Dirección (Línea 1)',
          helperText:'El numero de caracteres debe ser entre 1 y 512'
        },
        rules:{
          type:'distance',
          max:512
        }
    },
    address2:{ // Departamento, barrio
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
          id:'address2',
          type:'text',
          fullWidth: true,
          label:'Barrio, etc...',
          helperText:'El numero de caracteres debe ser entre 1 y 512'
        },
        rules:{
          type:'distance',
          max:512
        }
    },
    city:{ // Departamento, barrio
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config:{
          id:'city',
          type:'select',
          fullWidth: true,
          label:'Ciudad',
          helperText:''
        },
        rules:{
          type:'distance',
        }
    },
    province:{ // Departamento, barrio
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...catalogs.departments],
        config:{
          id:'province',
          type:'select',
          fullWidth: true,
          label:'Departamento',
          helperText:'Este campo es obligatorio'
        },
        rules:{
          type:'select',
        }
    },
    country:{ // Departamento, barrio
        value: 'Colombia',
        error: false,
        isVisited: true,
        isRequired: true,
        isValid: true,
        options:[{id:1, value:'Colombia', label:'Colombia'}],
        config:{
          id:'country',
          type:'select',
          fullWidth: true,
          label:'País',
          helperText:'Este campo es obligatorio'
        },
        rules:{
          type:'select',
        }
    },    
}
