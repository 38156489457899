import React, {useState} from 'react'
import { Grid, Grow, makeStyles, useTheme } from '@material-ui/core'
import ContainedButton from '../../../../components/Actions/ContainedButton';
import DisplayText from '../../../../components/Texts/DisplayText';
import SimpleModal from '../../../../components/Overlays/SimpleModal';
import payment_logo from '../../../../assets/cash-payment.png'
import { request_order, request_update_order, request_update_payment } from '../requests';
import { ORDER_STATUS_TYPES, PAYMENT_STATUSES } from '../../../../variables/config';
const Transition = React.forwardRef(function Transition(props, ref) {return <Grow ref={ref} {...props} timeout={500} />;});

const useStyles = makeStyles(theme => ({
    paper:{
        borderRadius:32, 
        padding:theme.spacing(5)
    },
    root:{
        paddingTop:0,
        paddingBottom:0
    }
}))

const CashPaymentModal = (props) => {

    const classes = useStyles()
    const {open, onClose, order,onUpdateCompleted} = props
    const theme = useTheme()
    const [loading, setLoading] = useState(false)

    //console.log(url)

    const onFinishCheckout = async() => {
        console.log('Pagar')
        try {
            setLoading(true)
            const order_id = order._id
            const data2send = {type_id:2, gateway:'cash', status_id:PAYMENT_STATUSES.COMPLETED}
            await request_update_payment(order_id, data2send)
            await request_update_order(order_id, {status_id:ORDER_STATUS_TYPES.CONFIRMED})
            const _updated_order = await request_order(order_id)
            onUpdateCompleted(_updated_order)
            onClose()
        } catch (error) {
            console.log(error.response)
        }
        setLoading(false)

    }

    return(
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div className={classes.root}>
                <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                    <Grid item>
                        <DisplayText variant='h6' color='primary' style={{color:theme.palette.primary.main ,fontWeight:500}}>Pago en efectivo</DisplayText>
                        <DisplayText>Por favor de clic en el botón para registrar el pago de la orden</DisplayText>
                    </Grid>
                    <Grid item>
                        <img src={payment_logo} alt='' width={120} />
                    </Grid>
                    <Grid item>
                        <ContainedButton color='primary' onClick={onFinishCheckout} loading={loading}> Pagar </ContainedButton>
                    </Grid>
                </Grid>
            </div>
        </SimpleModal>
    )
}

export default CashPaymentModal
