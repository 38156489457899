import React, {useState} from  'react'
import { Grid, makeStyles } from '@material-ui/core'
import SimpleButton from '../../../components/Actions/Buttons/SimpleButton'
import Heading from '../../../components/Texts/Heading'

import closedEye from '../../../assets/closedEye.png'
import openedEye from '../../../assets/opened_eye.png'
import { blueGrey } from '@material-ui/core/colors'
import { connect } from 'react-redux'
import * as actionTypes from '../../../store/actions'
import SingInModal from '../../../modals/SignInModal/SingInModal'
import SignUpModal from '../../../modals/SignUpModal/SignUpModal'
import ForgottenPasswordModal from '../../../modals/ForgottenPasswordModal/ForgottenPasswordModal'


const useStyles = makeStyles(theme => ({
    root:{
        paddingTop:100, paddingBottom:100,
        borderBottom:`1px solid ${blueGrey[50]}`
    },
    image:{
        width:124
    }
}))

const AuthentificationContainer = props => {

    const classes = useStyles()
    const {onGo2Page} = props

    const [modals, setModals] = useState({
        signin:false,
        signup:false,
        forgottenpassword:false
    })

    const onOpenModal = (item) => {
        console.log(item)
        let temp = {...modals}
        temp[item] = true
        setModals(temp)
    }

    const onCloseModal = (item) => {
        let temp = {...modals}
        temp[item] = false
        setModals(temp)
    }

    const onChangeModal = (from, to) => {
        let temp = {...modals}
        temp[from] = false
        temp[to] = true
        setModals(temp)
    }

    return(
        <div className={classes.root}>
            <SingInModal open={modals.signin} onClose={onCloseModal} onOpenModal={onOpenModal}/>
            <SignUpModal open={modals.signup} onClose={onCloseModal} onOpenModal={onOpenModal} onChangeModal={onChangeModal}/>
            <ForgottenPasswordModal open={modals.forgottenpassword} onClose={onCloseModal}/>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <Grid container justify='center' spacing={2} alignItems='center'>
                        <Grid item>
                            <img src={closedEye} alt='' className={classes.image}/>
                        </Grid>
                        <Grid item>
                            <Grid container direction='column' spacing={1}>
                                <Grid item><Heading size='extraLarge'>Soy un nuevo usuario</Heading></Grid>
                                <Grid item>
                                    <SimpleButton color='primary' size='large' onClick={() => onOpenModal('signup')}>REGISTRATE</SimpleButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container justify='center' spacing={2} alignItems='center'>
                        <Grid item>
                            <img src={openedEye} alt='' className={classes.image}/>
                        </Grid>
                        <Grid item>
                            <Grid container direction='column' spacing={1}>
                                <Grid item><Heading size='extraLarge'>Soy usuario registrado</Heading></Grid>
                                <Grid item>
                                    <SimpleButton color='primary' size='large' onClick={() => onOpenModal('signin')}>INICIAR SESIÓN</SimpleButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return{
        onChangeAuthStatus: (isauth) => dispatch({type: actionTypes.CHANGE_AUTH_STATUS, isauth })
    }
}

export default connect(null, mapDispatchToProps)(AuthentificationContainer)