import React, {useState} from 'react'
import { Avatar, Divider, Grid, Icon, IconButton, makeStyles } from '@material-ui/core'
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard'
import DisplayText from '../../../../components/Texts/DisplayText'
import { isValidDocument, onGetFullname } from '../../../../shared/utility'
import SearchPatient from './forms/SearchPatient'
import user_image from '../../../../assets/nouser.png'
import { grey } from '@material-ui/core/colors'
import { nodata } from '../../../../variables/texts'
import { ORDER_STATUS_TYPES, USER_TYPES } from '../../../../variables/config'
import AddUserModal from '../modals/AddUserModal'

const UserInfoCard = ({parentUser, userType, optical_id, user, setAddPatient2Order, onUpdateOrder, status_id}) => {

    //const {image, first_name, last_name, email, phone_number, setAddPatient2Order} = user
    const classes = useStyles()
    const [add_patient_modal, setAddPatientModal] = useState(false)

    
    
    const onDeletUser = () => {
        if(userType === USER_TYPES.ADMIN) onUpdateOrder({user_id:null, optical_id:null, optometrist_id:null})
        if(userType !== USER_TYPES.ADMIN) onUpdateOrder({user_id:null })
    }

    const onAddUser = (user_id) => {
        onUpdateOrder({user_id:user_id})
        setAddPatientModal(false)
    }    
    
   // const fullname = onGetFullname(first_name, last_name)

    return(
        <div>
            <SimpleCard>
                
                {!user ? (
                    <>
                        <AddUserModal user={parentUser} open={add_patient_modal} onClose={() => setAddPatientModal(false)} onUpdateCompleted={onAddUser}/>
                        <SearchPatient optical_id={optical_id} onAddPatient={() => setAddPatientModal(true)} setAddPatient2Order={onAddUser}/>
                    </>
                                
                ) : null}
                {user ? (
                    <div>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Grid container alignItems='center'>
                                    <Grid item xs>
                                        <DisplayText variant='subtitle1'>Datos del paciente</DisplayText>
                                    </Grid>
                                    {status_id === ORDER_STATUS_TYPES.PENDING ? <Grid item><IconButton size='small' onClick={onDeletUser}><Icon>close</Icon></IconButton></Grid> : null}
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <div>
                                    <Grid container wrap='nowrap' spacing={1}>
                                        <Grid item>
                                            <Avatar src={user_image} alt='' className={classes.avatar}/>
                                        </Grid>
                                        <Grid item xs>
                                            <DisplayText variant='body2'>{user ? onGetFullname(user.first_name, user.last_name) : nodata}</DisplayText>
                                            <DisplayText variant='body2' color='textSecondary'>{user ? user.email : nodata}</DisplayText>
                                            <DisplayText variant='body2' color='textSecondary'>{user && user.identification_card ? user.identification_card : nodata}</DisplayText>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                ) : null}
                    
                    
                    {/*<Grid item>
                        <UserAvatar image={image}/>
                    </Grid>
                    <Grid item xs={12}>
                        <DisplayText variant='subtitle1' align='center'>{fullname}</DisplayText>
                    </Grid>
                    <Grid item xs={12}>
                        <DisplayIconData icon='mail' data={email}/>
                    </Grid>
                    <Grid item xs={12}>
                        <DisplayIconData icon='phone' data={phone_number}/>
                    </Grid>*/}
            </SimpleCard>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    avatar:{
        width:48,
        height:48,
        border:`1px solid ${grey[300]}`
    }
}))

export default UserInfoCard