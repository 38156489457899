import React, {useState, useEffect} from 'react'
import { Button, CircularProgress, Dialog,  Grid, Grow, Icon, IconButton, makeStyles, Typography } from '@material-ui/core'
import { blueGrey } from '@material-ui/core/colors'
import InputForm from '../../components/Forms/InputForm'
import { isFormValid } from '../../shared/utility'
import { useStyles } from './styles'
import DisplayText from '../../components/Texts/DisplayText'
import { request_recover_password } from './requests'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow  ref={ref} {...props} timeout={500}/>;
});


const ForgottenPasswordModal = props => {

    const {open, onClose, onOpenModal} = props

    const classes = useStyles()
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [isValid, setIsValid] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')
    const [isEmailSent, setIsEmailSent] = useState(true)

    useEffect(() => {
        if(!open){
            setIsEmailSent(false)
            setForm(JSON.parse(JSON.stringify(formData)))
            setError(''); setLoading(false)
        }
    }, [open])



    const onChange = (data) => {
        let temp = {...form}
        const id = data.config.id
        temp[id] = {...data}
        setForm(temp)
        setIsValid(isFormValid(temp))
    }

    /* const onSubmit = async() => {
        if(!isValid){
            setError('Llene los cambios solicitados')
            return
        }
        let data2send = {}
        Object.keys(form).forEach(item => {
            data2send = {...data2send, [item]:form[item].value}
        })
        console.log(data2send)
        setLoading(true)
        try {
            const response = await onRecoverPassword({ variables: data2send })
            console.log(response.data.customerRecover)
            const {customerUserErrors} = response.data.customerRecover
            
            if(customerUserErrors && customerUserErrors.length){
                console.log('Hubo un error')
                console.log(customerUserErrors)
                if(customerUserErrors){
                    const errorMesage = ERROR_CODES[customerUserErrors[0].code]
                    console.log(errorMesage)
                    setError('Correo electrónico no identificado')
                }
            }else{
                console.log('Se puede acceder')
                setIsEmailSent(true)
                
            }
        } catch (error) {
            console.log(error)
            console.log(error.response)
            setError('Hubo un error')
        }
        setLoading(false)
        
    } */

    const onSubmit = async() => {
        if(!isValid){
            setError('Llene los cambios solicitados')
            return
        }
        let data2send = {}
        Object.keys(form).forEach(item => {
            data2send = {...data2send, [item]:form[item].value}
        })
        console.log(data2send)
        setLoading(true)
        try {

            await request_recover_password(data2send)
            setIsEmailSent(true)
        } catch (error) {
            console.log(error)
            console.log(error.response)
            setError('Hubo un error')
        }
        setLoading(false)
        
    }

    const onReturn = () => {
        onClose('forgottenpassword')
    }

    return(
        <Dialog open={open} maxWidth='xs' fullWidth classes={{paper:classes.paper}} className={classes.root} TransitionComponent={Transition}>
            <div className={classes.buttonContainer}><IconButton onClick={() => onClose('forgottenpassword')}><Icon >close</Icon></IconButton></div>
            <div className={classes.container}>
                <Typography variant='h4' color='primary' style={{fontWeight:500}}>Recupera tu contraseña</Typography>
                <div className={classes.form}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant='body1' style={{color:blueGrey[700]}}>
                                {!isEmailSent ? (
                                    'Por favor ingresa la dirección de correo con la que te registaste. Nosotros te enviaremos tu nueva contraseña'
                                ) : (
                                    'El correo ha sido enviado de manera exitosa, ahí encontraras los pasos a seguir para restaurar tu contraseña'
                                )}
                            </Typography>
                        </Grid>
                        {!isEmailSent ? (<Grid item xs={12}>
                            <InputForm data={form.email} onChange={onChange}/>
                        </Grid>) : null}
                        {isEmailSent ? (
                            <Grid item xs={12}>
                                <Grow in timeout={650}>
                                    <Grid container justify='center'>
                                        <Grid item><Icon className={classes.checkIcon}>done</Icon></Grid>
                                    </Grid>
                                </Grow>
                            </Grid>
                        ) : null}
                        
                    </Grid>
                </div>
                <Button variant='contained' color='primary' size='large' fullWidth 
                    onClick={ isEmailSent ? onReturn : onSubmit}>
                    {isEmailSent ? 'Regresar' : 'Enviar'}
                    {loading ? (<CircularProgress style={{width:24, height:24,marginLeft:8,color:'white'}}/>) : null}
                </Button>
                <div className={classes.linkContainer}>  
                    <DisplayText color='red' align='center'>{error}</DisplayText>
                </div>
            </div>
        </Dialog>
    )
}

export default ForgottenPasswordModal


const formData = {
    email:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
          id:'email',
          type:'email',
          fullWidth: true,
          label:'Dirección de correo',
          helperText:'Dirección de correo no válido'
        },
        rules:{
          type:'email',
        }
    },
}