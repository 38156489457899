const { mainServer } = require("../../../variables/config");

export const request_admin_optometrists = async(params) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/optometrist/all`, {params:{...params}})
    return response.data.data
}

export const request_optometrists = async(id, params) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/optometrist/optical/${id}/all`, {params:{...params}})
    return response.data.data
}

export const request_create_optometrist = async(data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.post(`/optometrist`, data)
    return response.data.data
}