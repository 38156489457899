import { Grid } from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard'
import DisplayText from '../../../../components/Texts/DisplayText'
import { onGetFiltersFromURL, onUpdateURLArgs } from '../../../Orders/Orders/custom_functions'
import SimpleTable from './SimpleTable'
import moment from 'moment'
import {request_orders} from '../requests'
import { onGetParamsFromState } from '../../Patients/custom_functions'

const Orders = (props) =>{
    
    const {onSelectedItem, location, id} = props

    const [loading, setLoading] = useState(false)

        /*---------ORDERS--------- */
    const [orders, setOrders] = useState([])
    const [total_orders, setTotalOrders] = useState(null)
    const [filter, setFilter] = useState({
        query:''
    })
    const [tableFilter, setTableFilter] = useState({
        limit:10,
        offset:0,
        order:-1,
        order_by:'created_at',
    })

    //filters
    const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'))
    const [status, setStatus] = useState(-1)

    useEffect(() => {
        const initModule = async() => {try {
            setLoading(true)
            //order
            const params = onGetParamsFromState(filter, tableFilter)
            params.start_date = startDate
            params.end_date = endDate
            params.patient_id = id
            if(status !== -1) params.status_id = status
            const _orders = await request_orders(params)
            setOrders(_orders.data)
            setTotalOrders(_orders.count)
            onUpdateURLArgs(filter, tableFilter)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }}
        initModule()
    }, [filter, tableFilter, startDate, endDate])

    useEffect(() => {
        const initModule = async() => {try {
            if(location.search){
                const response = onGetFiltersFromURL(location.search, filter, tableFilter)
                setFilter(response.filter)
                setTableFilter(response.tableFilter)
            }else{
                onUpdateURLArgs(filter, tableFilter)
            }
        } catch (error) {
        }}
        initModule()
    }, [])

    const onChangeDate = (value, id) => {
        let aux
        if(id === 'start_date'){
            aux = moment(value)
            console.log()
            if(moment(endDate).diff(aux) > 0) setStartDate(value)
        }
        if(id === 'end_date'){
            aux = moment(value)
            if(aux.diff(moment(startDate)) > 0) setEndDate(value)
        }
        console.log(endDate)
    }

    const onChangeFilter = (tag, value) => { // Change generic filter
        let newFilter = onUpdateFilter(tag, value)
        let newTableFilter = {...tableFilter}
        newTableFilter.offset = 0
        setFilter(newFilter)
        setTableFilter(newTableFilter)

        if(tag === 'status') setStatus(value)
    }

    const onUpdateFilter = (id,value) => {
        let temp = {...filter}
        temp[id] = value
        return temp
    }

    return(
        <SimpleCard>
            <Grid container spacing={4} justify='flex-end'>
                <Grid item xs={12}>
                    <DisplayText variant='subtitle1'>Pedidos</DisplayText>
                </Grid>
                <Grid item xs={12}>
                    <SimpleTable
                    data={orders} 
                    filter={filter}
                    tableFilter={tableFilter}
                    total={total_orders}
                    loading={loading}
                    //onAddRegister={ can_add ? () => setAddOrderModal(true) : null}
                    //onAddRegister = {can_add ? () => setAddOrderModal(true) : null}
                    onChangeFilter={onChangeFilter}
                    onUpdateTableFilter={(data) => setTableFilter(data)}
                    onSelectedItem={onSelectedItem}
                    onChangeDate={onChangeDate}
                    endDate={endDate}
                    startDate={startDate}
                    status={status}
                    />
                </Grid>
            </Grid>
        </SimpleCard>
    )
}

export default Orders