import React from 'react'
import { Button, Icon, makeStyles } from '@material-ui/core'
import { blueGrey } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
    button:{
        background:blueGrey[100],
        borderRadius:32,
        padding:'8px 24px',
        color:blueGrey[900],
        '&:hover':{background:blueGrey[200]}
    },
    icon:{
        marginRight:4
    }
}))

const ShortcutButton = ({icon, children, onClick}) => {
    const classes = useStyles()
    return(
        <Button onClick={onClick} className={classes.button}>
            {icon ? <Icon className={classes.icon}>{icon}</Icon> : null}
            {children}
        </Button>
    )
}

export default ShortcutButton