const { mainServer } = require("../../../variables/config");

export const request_orders = async(params) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/order/admin/all`, {params:{...params}})
    console.log(response.data)
    return response.data.data
}

export const request_orders_by_optical = async(id, params) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/order/opticals/${id}/all`, {params:{...params}})
    return response.data.data
}

export const request_create_order = async(data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.post(`/order/admin/`, data)
    return response.data.data.order_id
}

export const request_delete_order = async(id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    await mainServer.delete(`/order/admin/${id}`)
}


export const request_orders_report = async(params) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/order/admin/all/report`, {params:{...params}})
    return response.data.data
}
