export const ERROR_CODES = {
    106:'Correo y/o contraseña incorrectos',
    201:'La información enviada es inválida',
    301:'Error con el servidor o base de datos',
    402:'El correo electrónico ya está en uso',
    418:'Ya existe un registro similar',
    401:'Usuario no encontrado',
    403:'Usuario no confirmado',
    404:'Producto no encontrado',
    405:'Pedido no encontrado',
    406:'Pago no encontrado',
    407:'El pago no coincide con la orden',
    408:'Óptica no encontrada',
    409:'Óptometra no encontrado',
    410:'Paciente no encontrado'
}