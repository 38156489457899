import React from 'react';
import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import cx from 'classnames'
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
    root:{
        background:theme.palette.secondary.main,
        padding:'12px 28px',
        borderRadius:32,
        color:'white',
        '&:hover':{
            background:theme.palette.secondary.dark,
        },
        '&:disabled':{
            color:'white'
        }
    },
    progress:{
        color:'white',
        marginLeft:8
    },
    primary:{
        background:theme.palette.primary.main,
        '&:hover':{background:theme.palette.primary.dark}
    },
    red:{
        background:red[700],
        '&:hover':{background:red[900]}
    }
}))

const RoundedButton = ({loading, children, color, onClick, fullWidth, disabled}) => {

    const classes = useStyles()

    return ( 
        <Button onClick={onClick} fullWidth={fullWidth} disabled={disabled}
        className={cx({
            [classes.root]:true, 
            [classes.primary]: color==='primary',
            [classes.red]: color==='red'
        })}>
            {children}
            {loading ? (<CircularProgress size={24} className={classes.progress}/>) : null}
        </Button>
     );
}
 
export default RoundedButton;