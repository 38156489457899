const { mainServer } = require("../../../variables/config");

export const request_users = async(params) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/client/all`, {params:{...params}})
    return response.data.data
}

export const request_create_user = async(data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    await mainServer.post(`/client`, data)
}