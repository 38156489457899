import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import ActionModalBar from '../../../../components/Actions/ActionModalBar'
import { onGetErrorMessage, onGetFileExtension } from '../../../../shared/utility';
import { request_upload_document } from '../requests';
import DisplayText from '../../../../components/Texts/DisplayText';
import SimpleModal from '../../../../components/Overlays/SimpleModal';
import InputFile from '../../../../components/Forms/InputFile';

const UploadDocumentModal = ({id, selected_file, open, onClose, onUpdateCompleted}) => {

    const [file, setFile] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    useEffect(() => {
        if(!open){
            setFile(null)
            setError(null)
            setLoading(false)
        }
    },[open])

    const onSubmit = async() => {try {
        if(!file){
            setError('Debe adjuntar un documento')
            return
        }
        setLoading(true)

        const file2send = {document:selected_file.id,ext:onGetFileExtension(file.name)}
        await request_upload_document(id, file, file2send)
        onUpdateCompleted()

    } catch (error) {
        console.log(error)
        setError(onGetErrorMessage(error))   
    } setLoading(false)}

    let title = `Subir archivo`

    return ( 
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <DisplayText variant='h6' color='primary'>{title}</DisplayText>
                        <DisplayText variant='subtitle2'>{selected_file ? selected_file.name : ''}</DisplayText>
                    </Grid>
                    <Grid item xs={12}>
                        <InputFile id='file' file={file} label='Adjuntar archivo' 
                            onChange={(file) => setFile(file)} onDelete={() => setFile(null)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <ActionModalBar btnLabel='Subir' loading={loading} error={error} onSubmit={onSubmit}/>
                    </Grid>
                </Grid>
            </div>
        </SimpleModal>
     );
}
 
export default UploadDocumentModal;