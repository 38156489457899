import React, {useEffect, useState} from 'react'
import { Grid } from '@material-ui/core'
import Page from '../../../components/Structure/Page/Page'
import UserInfoCard from './components/UserInfoCard'
import SimpleCard from '../../../components/Structure/Cards/SimpleCard'
import DisplayText from '../../../components/Texts/DisplayText'
import { catalogs } from '../../../texts/esp/catalogs'
import InputForm from '../../../components/Forms/InputForm'
import SelectForm from '../../../components/Forms/SelectForm'
import { onGetFormData, onSetErrorsToForm, isFormValid, onInitForm, onGetSelectedFormData, onUpdateDocumentList } from '../../../shared/utility'
import ContainedButton from '../../../components/Actions/ContainedButton'
import { request_user, request_update_user, request_download_document, request_orders } from './requests'
import DocumentsCard from './components/DocumentsCard'
import UploadDocumentModal from './modals/UploadDocumentModal'
import DeleteDocumentModal from './modals/DeleteDocumentModal'
import ChangeImageModal from './modals/ChangeImageModal'
import UpdatePasswordModal from './modals/UpdatePasswordModal'
import BasicInfoForm from './components/BasicInfoForm'
import ShippingAddressForm from './components/ShippingAddressForm'
import BillingAddressForm from './components/BillingAddressForm'
import Orders from './components/Orders'
import TopbarButtonsPatient from './components/TopbarButtonsPatient'


const documents = [
    {id:'legal_document', name:'Aviso de privacidad', status:false},
]

const PatientView = props => {

    const {location, history, match} = props

    const [view, setView] = useState(1);

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [billing_sending, setBillingSending] = useState(false)
    const [shipping_sending, setShippingSending] = useState(false)

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [billing_address_form, setBillingAddressForm] = useState(JSON.parse(JSON.stringify(address_form)))
    const [shipping_address_form, setShippingAddressForm] = useState(JSON.parse(JSON.stringify(address_form)))

    const [document_list, setDocumentList] = useState([...documents])
    const [user, setUser] = useState(null)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(null)

    const [selected_file, setSelectedFile] = useState(null)
    const [upload_file_modal, setUploadFileModal] = useState(false)
    const [delete_file_modal, setDeleteFileModal] = useState(false)
    const [update_image_modal, setUpdateImageModal] = useState(false)
    const [password_modal, setPasswordModal] = useState(false)

    const userID = match.params.id

    useEffect(() => {
        const initModule = async() => {try {
            setLoading(true)
            const {id} = match.params
            const _user = await request_user(id)
            setUser(_user)
            const _form = onInitForm(form, _user)
            let _billing_form = onInitForm(billing_address_form, _user.billing_address)
            let _shipping_form = onInitForm(shipping_address_form, _user.shipping_address)
            const _documents = onUpdateDocumentList(document_list, _user)
            setForm(_form)
            //console.log(_user.billing_address.province)
            if(_user.billing_address && _user.billing_address.province){
                const provice_catalog = catalogs.cities[_user.billing_address.province]
                if(provice_catalog) _billing_form.city.options = [...provice_catalog]
            }
            if(_user.shipping_address && _user.shipping_address.province){
                const provice_catalog = catalogs.cities[_user.shipping_address.province]
                if(provice_catalog) _shipping_form.city.options = [...provice_catalog]
            }


            
            setShippingAddressForm(_shipping_form)
            setBillingAddressForm(_billing_form)
            setDocumentList(_documents)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }}
        initModule()
    }, [])

    const onUpdateCompleted = async() => {
        try {
            const _user = await request_user(userID)
            const _form = onInitForm(form, _user)
            const _documents = onUpdateDocumentList(document_list, _user)
            setUser(_user)
            setForm(_form)

            setDocumentList(_documents)
            setUploadFileModal(false)
            setDeleteFileModal(false)
            setUpdateImageModal(false)
            setPasswordModal(false)

        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onChangeBillingForm = (data) => {
        let temp = { ...billing_address_form }
        const id = data.config.id
        temp[id] = { ...data }
        if(id === 'province'){
            //console.log('Actualizar ciudades/barrios')
            temp.city.value = null
            temp.city.options = catalogs.cities[data.value]
        }
        setBillingAddressForm(temp)
    }

    const onChangeShippingForm = (data) => {
        let temp = { ...shipping_address_form }
        const id = data.config.id
        temp[id] = { ...data }
        if(id === 'province'){
            //console.log('Actualizar ciudades/barrios')
            temp.city.value = null
            temp.city.options = catalogs.cities[data.value]
        }
        setShippingAddressForm(temp)
    }
    
    

    const onUploadDocument = (data) => {
        console.log(data)
        setSelectedFile(data)
        setUploadFileModal(true)
    }

    const onDownloadDocument = async(data) => {try {
        await request_download_document(userID, {document:data.id})
    } catch (error) {
        console.log(error)
        setError('Hubo un error')
    }}

    const onDeleteDocument = data => {
        console.log(data)
        setSelectedFile(data)
        setDeleteFileModal(true)
    }

    const onSelectedItem = (item) => {
        const {_id} = item
        history.push(`/orders/${_id}`)
    }

    //const _document_list = document_list.
    const handleView = (newValue) => {
        setView(newValue);
        //console.log(value)
    };

    let viewRender = null

    switch(view){
        case 1:{
            viewRender= (<Grid container spacing={3}>
                <Grid item xs={12}>
                    <BasicInfoForm user={user} form={form} onChange={onChange} onUpdateData={(data) => setUser(data)}
                     onUpdateForm={(data) => setForm(data)} onDispatchSuccess={(message) => setSuccess(message)}/>
                </Grid>
                <Grid item xs={12}>
                    <ShippingAddressForm user_id={userID} address={user ? user.shipping_address : null} form={shipping_address_form} onChange={onChangeShippingForm} onUpdateData={(data) => setUser(data)}
                     onUpdateForm={(data) => setForm(data)} onDispatchSuccess={(message) => setSuccess(message)} />
                </Grid>
                <Grid item xs={12}>
                    <BillingAddressForm user_id={userID} address={user ? user.billing_address : null} form={billing_address_form} onChange={onChangeBillingForm} sending={billing_sending} onUpdateData={(data) => setUser(data)}
                     onUpdateForm={(data) => setForm(data)} onDispatchSuccess={(message) => setSuccess(message)}/>
                </Grid>
            </Grid>)
            break;
        }
        case 2:{
            viewRender= <Orders 
            location={location} onSelectedItem={onSelectedItem} id={userID} />
            break;
        }
    }

    const optionsTabs = [
        {label:'General', id:1},
        {label:'Pedidos', id:2},
    ]

    return(
        <Page title='Información del paciente' loading={loading} goback success={success} onCloseSuccess={() => setSuccess(null)}>
            <>
                <UpdatePasswordModal userID={userID} open={password_modal} onClose={() => setPasswordModal(false)} onUpdateCompleted ={onUpdateCompleted}/>
                <ChangeImageModal id={userID} open={update_image_modal} onClose={() => setUpdateImageModal(false)} onUpdateCompleted ={onUpdateCompleted}/>
                <UploadDocumentModal id={userID} selected_file={selected_file} open={upload_file_modal} onClose={() => setUploadFileModal(false)} onUpdateCompleted={onUpdateCompleted}/>
                <DeleteDocumentModal id={userID} selected_file={selected_file} open={delete_file_modal} onClose={() => setDeleteFileModal(false)} onUpdateCompleted={onUpdateCompleted}/>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={4}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <UserInfoCard {...user} 
                                onChangePicture={() => setUpdateImageModal(true)} 
                                onChangePassword={() => setPasswordModal(true)} 
                                view={view} handleView={handleView}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <DocumentsCard documents={document_list} onUploadDocument={onUploadDocument} onDownloadDocument={onDownloadDocument} onDeleteDocument={onDeleteDocument}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={8}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TopbarButtonsPatient optionsTabs={optionsTabs} view={view} handleView={handleView} />
                            </Grid>
                            <Grid item xs={12}>
                                {viewRender}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        </Page>
    )
}

export default PatientView

const formData = {
    first_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'first_name',
            type: 'text',
            fullWidth: true,
            label: 'Nombre(s)',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    last_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'last_name',
            type: 'text',
            fullWidth: true,
            label: 'Apellido(s)',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    email: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'email',
            fullWidth: true,
            label: 'Dirección de correo',
            helperText: 'Dirección de correo no válido',
            disabled:true,
        },
        rules: {
            type: 'email',
        }
    },
    phone_number: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'phone_number',
            type: 'number',
            fullWidth: true,
            label: 'Teléfono',
            helperText: 'Número de teléfono no válido',
        },
        rules: {
            type: 'phone',
        }
    },
    gender_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[...catalogs.genders],
        config: {
            id: 'gender_id',
            type: 'select',
            fullWidth: true,
            label: 'Género',
            helperText: 'Debe elegir una opción',
        },

    },
    identification_card: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'identification_card',
            type: 'text',
            fullWidth: true,
            label: 'Cédula ciudadana',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    status_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...catalogs.user_statuses],
        config: {
            id: 'status_id',
            type: 'select',
            fullWidth: true,
            label: 'Estatus',
            helperText: 'Debe elegir una opción',
        },

    },
}

const address_form = {
    address1:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
          id:'address1',
          type:'text',
          fullWidth: true,
          label:'Dirección (Línea 1)',
          helperText:'El numero de caracteres debe ser entre 1 y 512'
        },
        rules:{
          type:'distance',
          max:512
        }
    },
    address2:{ // Departamento, barrio
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
          id:'address2',
          type:'text',
          fullWidth: true,
          label:'Barrio, etc...',
          helperText:'El numero de caracteres debe ser entre 1 y 512'
        },
        rules:{
          type:'distance',
          max:512
        }
    },
    city:{ // Departamento, barrio
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config:{
          id:'city',
          type:'select',
          fullWidth: true,
          label:'Ciudad',
          helperText:''
        },
        rules:{
          type:'distance',
        }
    },
    province:{ // Departamento, barrio
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...catalogs.departments],
        config:{
          id:'province',
          type:'select',
          fullWidth: true,
          label:'Departamento',
          helperText:'Este campo es obligatorio'
        },
        rules:{
          type:'select',
        }
    },
    country:{ // Departamento, barrio
        value: 'Colombia',
        error: false,
        isVisited: true,
        isRequired: true,
        isValid: true,
        options:[{id:1, value:'Colombia', label:'Colombia'}],
        config:{
          id:'country',
          type:'select',
          fullWidth: true,
          label:'País',
          helperText:'Este campo es obligatorio'
        },
        rules:{
          type:'select',
        }
    },    
}