import { Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import SimpleCard from '../../../../components/Structure/Cards/SimpleCardOptical'
import Page from '../../../../components/Structure/Page/Page'
import DisplayText from '../../../../components/Texts/DisplayText'
import { onGetParamsFromState, onUpdateURLArgs } from '../../../Orders/Orders/custom_functions'
import { request_optometrists_by_optical } from '../requests'
import AddOptometristModal from './Optometrists/AddOptometristModal'
import SimpleTable from './Optometrists/SimpleTable'

const OpticalOptometristView = (props) => {

    const {id, history} = props

    const [loading, setLoading] = useState(false)
    const [optometrists, setOptometrists] = useState([])
    const [addModal, setAddModal] = useState(false)
    const [totalOptometrists, setTotalOptometrists] = useState(10)
    const [filter, setFilter] = useState({
        query:''
    })
    const [tableFilter, setTableFilter] = useState({
        limit:10,
        offset:0,
        order:1,
        order_by:'_id',
    })

    useEffect(() => {
        const initModule = async() => {try {
            setLoading(true)
            const params = onGetParamsFromState(filter, tableFilter)
            const _optometrists = await request_optometrists_by_optical(id, params)
            //console.log(_optometrists)
            setOptometrists(_optometrists.data)
            setTotalOptometrists(_optometrists.count)
            onUpdateURLArgs(filter, tableFilter)
            console.log(tableFilter)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }}
        initModule()
    }, [ filter, tableFilter])

    const onSelectedItem = (item) => {
        const {_id} = item
        history.push(`/optometrists/${_id}`)
    }
  
    const onChangeFilter = (tag, value) => { // Change generic filter
        let newFilter = onUpdateFilter(tag, value)
        setFilter(newFilter)
    }

    const onUpdateFilter = (id,value) => {
        let temp = {...filter}
        temp[id] = value
        return temp
    }
   
    return(
        <div>
            <AddOptometristModal history={history}  open={addModal} onClose={() => setAddModal(false)} id={id} />
            {/* <Grid container>
                <Grid item xs={12}>
                    <DisplayText variant='subtitle1'>Optómetras</DisplayText>
                </Grid>
                <Grid item xs={12}> */}
                    <SimpleTable
                    data={optometrists} 
                    filter={filter}
                    tableFilter={tableFilter}
                    total={totalOptometrists}
                    loading={loading}
                    //onAddRegister={can_add ? () => setAddModal(true) : null}
                    onAddRegister={ () => setAddModal(true) }
                    onChangeFilter={onChangeFilter}
                    onUpdateTableFilter={(data) => setTableFilter(data)}
                    onSelectedItem={onSelectedItem}/>  
                {/* </Grid>
            </Grid> */}
        </div>
    )
 

}

export default OpticalOptometristView