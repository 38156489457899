import { Grid } from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import OpticalAddress from '../components/OpticaIAddress'
import OpticalBankAccount from '../components/OpticaIBankAccount'
import OpticaGeneralInfo from '../components/OpticaIGeneralInfo'
import { request_download_document, request_optica} from '../requests'
import DocumentsCard from '../components/DocumentsCard'
import DocumentModal from '../modals/DocumentModal'
import DeleteDocumentModal from '../modals/DeleteDocumentModal'
import LoadingContainer from '../../../../components/Structure/LoadingContainer'
import { onUpdateDocumentList } from '../../../../shared/utility'
import UploadDocumentModal from '../modals/UploadDocumentModal'

const documents = [
    {id:'identification_card_document', name:'Documento de identificación', status:false},
    {id:'rut_document', name:'RUT', status:false},
    {id:'commerce_chamber_document', name:'Cámara de comercio', status:false},

]

const OpticalInfoView = (props) => {

    const {id, onUpdateView, history} = props

    /* -------------- GENERAL STATES --------------- */
    const [loading, setLoading] = useState(false)
    const [success, setSucess] = useState(null)
    const [document_list, setDocumentList] = useState([...documents])
    const [error, setError] = useState(null)

    /* -------------- SECTIONS' STATES --------------- */
    const [optical, setOptical] = useState(null)
    const [bank, setBank] = useState(null)
    const [address, setAddress] = useState(null)
    const [lr, setLr] = useState(null)

    /* -------------- MODAL'S STATES --------------- */
    const [document_modal, setDocumentModal] = useState(false)
    const [delete_document_modal, setDeleteDocumentModal] = useState(false)

    /* -------------- DOCUMENTS' STATES --------------- */
    const [selected_file, setSelectedFile] = useState(null)
    const [upload_file_modal, setUploadFileModal] = useState(false)
    const [delete_file_modal, setDeleteFileModal] = useState(false)

    const businessID = 1

    useEffect(() => {
        const initModule = async() => {try {
            setLoading(true)
            const _optical = await request_optica(id)
            console.log(_optical)
            setOptical(_optical)

            const _documents = onUpdateDocumentList(document_list, _optical)
            setDocumentList(_documents)
            setBank(_optical.bank_account)
            console.log(_optical.billing_address)
            setAddress(_optical.billing_address)
            setLr(_optical.legal_representative)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }}
        initModule()
    },[])


    /* -------------- DOCUMENTS' METHODS --------------- */
    const onUpdateCompleted = async() => {
        try {
            const _user = await request_optica(id)
            //const _form = onInitForm(form, _user)
            const _documents = onUpdateDocumentList(document_list, _user)
            setDocumentList(_documents)
            setUploadFileModal(false)
            setDeleteFileModal(false)

        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }

    const onUploadDocument = (data) => {
        console.log(data)
        setSelectedFile(data)
        setUploadFileModal(true)
    }

    const onDownloadDocument = async(data) => {try {
        await request_download_document(id, {document:data.id})
    } catch (error) {
        console.log(error)
        setError('Hubo un error')
    }}

    const onDeleteDocument = data => {
        console.log(data)
        setSelectedFile(data)
        setDeleteFileModal(true)
    }

    return(
        <LoadingContainer loading={loading} success={success} onCloseSuccess={() => setSucess(null)} >
            <UploadDocumentModal id={id} selected_file={selected_file} open={upload_file_modal} onClose={() => setUploadFileModal(false)} onUpdateCompleted={onUpdateCompleted}/>
            <DeleteDocumentModal id={id} selected_file={selected_file} open={delete_file_modal} onClose={() => setDeleteFileModal(false)} onUpdateCompleted={onUpdateCompleted}/>
            <Grid container spacing={3}>
                <Grid item md={7} xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}  >
                            <OpticaGeneralInfo data={optical} data_lr={lr} id={id} onUpdate={onUpdateView} 
                            onDispatchSuccess={(data) => setSucess(data)}/>
                        </Grid>
                        <Grid item xs={12} >
                            <OpticalAddress data={address} id={id} onDispatchSuccess={(data) => setSucess(data)}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={5} xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} >
                            <OpticalBankAccount data={bank} id={id} onDispatchSuccess={(data) => setSucess(data)}/>
                        </Grid>
                        <Grid item >
                            <DocumentsCard documents={document_list} onUploadDocument={onUploadDocument} onDownloadDocument={onDownloadDocument} onDeleteDocument={onDeleteDocument}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </LoadingContainer>
    )

}

export default OpticalInfoView