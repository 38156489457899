import React, {useState} from 'react'
import { Avatar, Grid, makeStyles } from '@material-ui/core'
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard'
import DisplayText from '../../../../components/Texts/DisplayText'
import user_image from '../../../../assets/nouser.png'
import { grey } from '@material-ui/core/colors'

const OpticalCard = ({user, setAddPatient2Order, onUpdateOrder, status_id}) => {

    //const {image, first_name, last_name, email, phone_number, setAddPatient2Order} = user
    const classes = useStyles()
    const [add_patient_modal, setAddPatientModal] = useState(false)

    
    
    const onDeletUser = () => {
        onUpdateOrder({user_id:null})
    }

    const onAddUser = (user_id) => {
        onUpdateOrder({user_id:user_id})
    }    
    
   // const fullname = onGetFullname(first_name, last_name)

    return(
        <div>
            <SimpleCard>
                    <div>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Grid container alignItems='center'>
                                    <Grid item xs>
                                        <DisplayText variant='subtitle1'>Datos de la óptica</DisplayText>
                                    </Grid>
                                    {/*status_id === ORDER_STATUS_TYPES.PENDING ? <Grid item><IconButton size='small' onClick={onDeletUser}><Icon>close</Icon></IconButton></Grid> : null*/}
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <div>
                                    <Grid container wrap='nowrap' spacing={1}>
                                        <Grid item>
                                            <Avatar src={user_image} alt='' className={classes.avatar}/>
                                        </Grid>
                                        <Grid item xs>
                                            <DisplayText variant='body2'>{user ? user.business_name : 'No disponible'}</DisplayText>
                                            <DisplayText variant='body2' color='textSecondary'>{user ? user.email : 'No disponible'}</DisplayText>

                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
            </SimpleCard>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    avatar:{
        width:48,
        height:48,
        border:`1px solid ${grey[300]}`
    }
}))

export default OpticalCard