import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import Page from '../../../components/Structure/Page/Page'
import SimpleTable from './components/SimpleTable'
import { onGetFiltersFromURL, onGetParamsFromState, onUpdateURLArgs } from './custom_functions'
import AddProductModal from './modals/AddProductModal/AddProductModal'
import {request_products} from './requests'

const PublicProducts = props => {

    const {location, history, user} = props

    const [loading, setLoading] = useState(false)
    const [products, setProducts] = useState([])
    const [addModal, setAddModal] = useState(false)
    const [totalItems, setTotalItems] = useState(10)
    const [filter, setFilter] = useState({
        query:''
    })
    const [tableFilter, setTableFilter] = useState({
        limit:10,
        offset:0,
        order:1,
        order_by:'_id',
    })

    useEffect(() => {
        const initModule = async() => {try {
            setLoading(true)
            const params = onGetParamsFromState(filter, tableFilter)
            const _users = await request_products(params)
            console.log(_users.data)
            setProducts(_users.data)
            setTotalItems(_users.count)
            onUpdateURLArgs(filter, tableFilter)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }}
        initModule()
    }, [filter, tableFilter])

    useEffect(() => {
        const initModule = async() => {try {
            if(location.search){
                const response = onGetFiltersFromURL(location.search, filter, tableFilter)
                setFilter(response.filter)
                setTableFilter(response.tableFilter)
            }else{
                onUpdateURLArgs(filter, tableFilter)
            }
        } catch (error) {
        }}
        initModule()
    }, [])

    

    const onSelectedItem = (item) => {
        const {_id} = item
        history.push(`/public-products/${_id}`)
    }
  
    const onChangeFilter = (tag, value) => { // Change generic filter
        let newFilter = onUpdateFilter(tag, value)
        let newTableFilter = {...tableFilter}
        newTableFilter.offset = 0
        setFilter(newFilter)
        setTableFilter(newTableFilter)
    }

    const onUpdateFilter = (id,value) => {
        let temp = {...filter}
        temp[id] = value
        return temp
    }
 
    //ck
    const onUpdateUsers = async() => {
        const params = onGetParamsFromState(filter, tableFilter)
        const _users = await request_products(params)
        setProducts(_users.data)
        setTotalItems(_users.count)
        setAddModal(false)
    }

    const userType = user ? user.user_type_id : null

    return(
        <Page title='Productos'>
            <AddProductModal open={addModal} onClose={() => setAddModal(false)} onRequestUsers={onUpdateUsers}/>
            <SimpleTable
                userType={userType}
                data={products} 
                filter={filter}
                tableFilter={tableFilter}
                total={totalItems}
                loading={loading}
                onAddRegister={() => setAddModal(true)}
                onChangeFilter={onChangeFilter}
                onUpdateTableFilter={(data) => setTableFilter(data)}
                onSelectedItem={onSelectedItem}/>
        </Page>
    )
}

const mapStateToProps = state => {
    return{
        user: state.user
    }
}

export default connect(mapStateToProps)(PublicProducts)
