import React, { useState, useEffect } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { isFormValid, onGetErrorMessage, onGetFormData, onSetErrorsToForm } from '../../../../shared/utility'
import InputForm from '../../../../components/Forms/InputForm'
import SimpleModal from '../../../../components/Overlays/SimpleModal'
import ContainedButton from '../../../../components/Actions/ContainedButton'
import { request_change_password } from '../requests'
import DisplayText from '../../../../components/Texts/DisplayText'


const UpdatePasswordModal = props => {

    const { id, open, onClose, onUpdateCompleted } = props

    const classes = useStyles()
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')

    useEffect(() => {
        if (!open) {
            setForm(JSON.parse(JSON.stringify(formData)))
            setError(''); setLoading(false);
        }
    }, [open])


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        if(id === 'password') temp['passwordConfirmation'].value2 = temp[id].value
        setForm(temp)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetFormData(form)
        delete data2send.passwordConfirmation
        console.log(data2send)
        console.log(id)

        setLoading(true)
        try {
            console.log(id)
            await request_change_password(id, data2send);
            //onUpdateCompleted()
            setLoading(false)
            onClose()
        } catch (error) {
            if(error.response && error.response.data){
                setError(error.response.data.error.message)
            }else{
                setError('Hubo un error interno')
            }
            //console.log(error.response.status)
            //setError(onGetErrorMessage(error))
            setLoading(false)
        }

    }

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div >
                <DisplayText variant='h6' color='primary' style={{ fontWeight: 500 }}>Cambio de contraseña</DisplayText>
                <DisplayText >Por favor ingresa los datos solicitados</DisplayText>
                <div className={classes.form}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <InputForm data={form.password} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.passwordConfirmation} onChange={onChange} />
                        </Grid>
                        
                    </Grid>
                </div>
                <ContainedButton color='primary' fullWidth onClick={onSubmit} loading={loading}>
                    Actualizar
                </ContainedButton>
                <DisplayText color='error' align='center'>{error}</DisplayText>
            </div>
        </SimpleModal>
    )
}

const useStyles = makeStyles(theme => ({
    form:{
        paddingTop:theme.spacing(4),
        paddingBottom:theme.spacing(4),
    },
}))

export default UpdatePasswordModal


const formData = {
    password: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'password',
            type: 'password',
            fullWidth: true,
            label: 'Contraseña',
            helperText: 'Debe contener de 1 a 256 caracteres',
            autoComplete:'new-password'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    passwordConfirmation: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'passwordConfirmation',
            type: 'password',
            fullWidth: true,
            label: 'Validar contraseña',
            helperText: 'Las contraseñas no coinciden'
        },
        rules: {
            type: 'equals',
        }
    },
    
}