import { Fade, Grid, makeStyles } from '@material-ui/core';
import { blueGrey } from '@material-ui/core/colors';
import React, { useState, useEffect } from 'react';
import noproduct from '../../../../../assets/orders/noproduct.png'
import ActionModalBar from '../../../../../components/Actions/ActionModalBar';
import ContainedButton from '../../../../../components/Actions/ContainedButton';
import SelectAutocompleteForm from '../../../../../components/Forms/SelectAutocompleteForm';
import ProductImage from '../../../../../components/Structure/Images/ProductImage';
import DisplayText from '../../../../../components/Texts/DisplayText';
import { isFilterFormValid, onGetFormatNumber, onGetFormData, onSetErrorsToForm } from '../../../../../shared/utility';
import { catalogs } from '../../../../../texts/esp/catalogs';
import { PRODUCT_TYPES } from '../../../../../variables/config';
import { nodata } from '../../../../../variables/texts';
import FormulaContainer from './FormulaContainer';
import {request_add_product} from '../../requests'
const filter_products = {
    [PRODUCT_TYPES.MYIOPIA]: ['quantity', 'sphere'],
    [PRODUCT_TYPES.ASTIGMATISM]: ['quantity', 'sphere', 'cylinder', 'axis'],
    [PRODUCT_TYPES.MULTIFOCAL]: ['quantity', 'sphere', 'addition'],
    [PRODUCT_TYPES.COSMETICS]: ['quantity', 'color'],
}

const ProductView = ({orderID, product, formula, onUpdateCompleted}) => {

    const classes = useStyles()

    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))
    const [eye, setEye] = useState('EQUAL')
    const [product_type, setProducType] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)




    useEffect(() => {
        if(product){
            let _form = JSON.parse(JSON.stringify(form_data))
            const {tags} = product
            let _type = null
            if(tags.includes('MIOPIA')) _type = PRODUCT_TYPES.MYIOPIA
            if(tags.includes('ASTIGMATISMO')) _type = PRODUCT_TYPES.ASTIGMATISM
            if(tags.includes('PRESBICIA')) _type = PRODUCT_TYPES.MULTIFOCAL
            if(tags.includes('COSMETICO')) _type = PRODUCT_TYPES.COSMETICS
            setProducType(_type)

            // Actualizar catalogos
            let variants = null
            try {variants = JSON.parse(product.variants)                
            } catch (error) {console.log(error)}
            if(variants){
                const {adds, axes, colors, cylinders, dominances, spheres} = variants
                _form.sphere.options = spheres
                _form.addition.options = adds
                _form.axis.options = axes
                _form.color.options = colors
                _form.cylinder.options = cylinders
                _form.dominance.options = dominances
            }
            // Actualizar CON FORMULA
            if(formula){
                Object.keys(formula).forEach(item => {
                    let element
                    switch (item) {
                        case 'sphere':
                            element = _form[item].options.find(el => {return parseFloat(el.label.replace('+','').trim()) === parseFloat(formula[item].trim())})
                            if(element){_form[item].value = element.value; _form[item].isValid = true }
                            break;
                        case 'cylinder':
                            element = _form[item].options.find(el => {
                                return parseFloat(el.label.replace('+','').trim()) === parseFloat(formula[item].trim())
                            })
                            if(element){_form[item].value = element.value; _form[item].isValid = true }
                            break;
                        case 'axis':
                            element = _form[item].options.find(el => {return parseFloat(el.label.trim()) === parseFloat(formula[item].trim())})
                            if(element){_form[item].value = element.value; _form[item].isValid = true }
                            break;
                        case 'addition':
                            element = _form[item].options.find(el => {return parseFloat(el.label.trim()) === parseFloat(formula[item].trim())})
                            if(element){_form[item].value = element.value; _form[item].isValid = true }
                            break;
                        case 'color':
                            element = _form[item].options.find(el => {return el.label.trim() === formula[item].trim()})
                            if(element){_form[item].value = element.value; _form[item].isValid = true }
                            break;
                    
                        default:
                            break;
                    }
                })
            }

            setForm(_form)
            setLoading(false)

        }
    }, [product])

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
        if(error) setError(null)
    }

    useEffect(() => {
        let catalogs = []
        for(let i=1; i<11; i++){
            catalogs.push({value:i, label:`${i.toString()}`})
        }
        //console.log(JSON.stringify({axis:catalogs}))
    },[])

    const onSubmit = async() => {

        const filter = filter_products[product_type]
        console.log(filter)
        const errors = isFilterFormValid(form, filter)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            setError('Ingrese toda la información solicitada')
            return
        }

        //Extract data
        let data2send = onGetFormData(form)

        let details = {}

        if(data2send.sphere){
            const _temp = form.sphere.options.find(el => el.value === data2send.sphere)
            if(_temp) details.sphere = _temp.label
        }
        if(data2send.cylinder){
            const _temp = form.cylinder.options.find(el => el.value === data2send.cylinder)
            if(_temp) details.cylinder = _temp.label
        }
        if(data2send.axis){
            const _temp = form.axis.options.find(el => el.value === data2send.axis)
            if(_temp) details.axis = _temp.label
        }
        if(data2send.addition){
            const _temp = form.addition.options.find(el => el.value === data2send.addition)
            if(_temp) details.addition = _temp.label
        }
        if(data2send.dominance){
            const _temp = form.dominance.options.find(el => el.value === data2send.dominance)
            if(_temp) details.dominance = _temp.label
        }
        if(data2send.color){
            const _temp = form.color.options.find(el => el.value === data2send.color)
            if(_temp) details.color = _temp.label
        }

        if(product_type){
            details.type = eye
        }

        let final_data = {
            product_id:product._id,
            quantity: data2send.quantity,
            details: details ? JSON.stringify(details) : ''
        }

        console.log(final_data)
        try {
            setLoading(true)
            await request_add_product(orderID, final_data)
            onUpdateCompleted()
        } catch (error) {
            console.log(error)
            setLoading(false)
        }

    }


    let noproduct_content = (
        <div className={classes.noproduct}>
            <Grid container direction='column' alignItems='center' spacing={2}>
                <Grid item>
                    <img src={noproduct} alt='' width={120} />
                </Grid>
                <Grid item>
                    <DisplayText align='center'>No has seleccionado un producto</DisplayText>
                </Grid>
            </Grid>
        </div>
    )

    let product_content = null

    if(product){
        noproduct_content = null
        //console.log(product)
        
    }

    return ( 
        <div className={classes.root}>
                {noproduct_content}
                <Fade in={Boolean(product)} >
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <ProductImage type='MODAL' src={product ? product.image : null}/>
                                </Grid>
                                <Grid item xs>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <DisplayText variant='h6' style={{fontSize:20}}>{product ? product.title : nodata}</DisplayText>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DisplayText>{product ? onGetFormatNumber(product.price) : nodata}</DisplayText>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div style={{marginTop:32, width:200}}>
                                                <SelectAutocompleteForm data={form.quantity} onChange={onChange} />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {product_type ? <Grid item xs={12}>
                            <FormulaContainer eye={eye} form={form} product={product} product_type={product_type} onChange={onChange} onEyeChange={(data) =>  setEye(data)}/>
                        </Grid> : null}
                        <Grid item xs={12}>
                            <ActionModalBar btnLabel='Agregar' loading={loading} error={error} onSubmit={onSubmit}/>
                        </Grid>
                    </Grid>
                </Fade>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root:{
        //background:blueGrey[50],
        width:'100%',
        height:'100%',
        boxSizing:'border-box',
        padding:24,
        position:'relative'
    },
    noproduct:{
        position:'absolute',
        top:'50%', left:'50%', transform:'translate(-50%, -50%)'
    }
}))
 
export default ProductView;

const form_data = {
    quantity: {
        value: 1,
        error: false,
        isVisited: true,
        isRequired: true,
        isValid: true,
        options:catalogs.quantities,
        config: {
            id: 'quantity',
            type: 'select',
            fullWidth: true,
            label: 'Cantidad',
            helperText: 'Valor oblicatorio'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    sphere: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config: {
            id: 'sphere',
            type: 'select',
            fullWidth: true,
            label: 'ESF/PWR',
            helperText: ''
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    cylinder: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config: {
            id: 'cylinder',
            type: 'select',
            fullWidth: true,
            label: 'CYL',
            helperText: ''
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    addition: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config: {
            id: 'addition',
            type: 'select',
            fullWidth: true,
            label: 'ADD',
            helperText: ''
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    axis: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config: {
            id: 'axis',
            type: 'select',
            fullWidth: true,
            label: 'EJE',
            helperText: ''
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    color: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config: {
            id: 'color',
            type: 'select',
            fullWidth: true,
            label: 'COLOR',
            helperText: ''
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    dominance: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config: {
            id: 'dominance',
            type: 'select',
            fullWidth: true,
            label: 'DOM',
            helperText: ''
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
}