import { Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import DisplayText from '../../../../components/Texts/DisplayText'

const useStyles = makeStyles((theme) => ({
    available:{
        width:10,
        height:10,
        backgroundColor:'green',
        borderRadius:'50%',
        marginTop:7
    },
    notAvailable:{
        width:10,
        height:10,
        backgroundColor:'red',
        borderRadius:'50%',
        marginTop:7
    },
}));

const DisplayStatus = (props) =>{

    const {status} = props

    const classes = useStyles()

    return(
        <Grid container spacing={1}>
            <Grid item ><div className={status == 1? classes.available : classes.notAvailable}></div></Grid>
            <Grid item ><DisplayText variant='subtitle2'>{status == 1? 'Disponible' : 'No disponible' }</DisplayText></Grid>
        </Grid>
    )
}

export default DisplayStatus