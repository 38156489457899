import axios from 'axios'
import { mainServer } from '../../../variables/config'

export const request_product = async(id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/product/admin/${id}`)
    return response.data.data
}

export const request_update_product = async(id, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.patch(`/product/${id}`, data)
    return response.data.data
}

export const request_update_user = async(id,data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.patch(`/admin/${id}`, data)
    return response.data.data
}

export const request_upload_image = async(id, file, ext) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    let response = await mainServer.post(`/product/${id}/image`, ext)
    console.log(response.data.data)
    const options = {headers: {'Content-Type': file.type}}
    delete axios.defaults.headers.common["Authorization"]
    await axios.put(response.data.data.upload_url, file, options);
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    return response.data.data.product   
    //response = await mainServer.instance.get(`/patient/${id}`);
    //return response.data.data.patient
}

export const request_delete_image = async (id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.delete(`/product/${id}/image`)
    return response.data.data
} 