import React, {useEffect, useState} from 'react'
import Page from '../../../components/Structure/Page/Page'
import SimpleTable from './components/SimpleTable'
import { onGetFiltersFromURL, onGetParamsFromState, onUpdateURLArgs } from './custom_functions'
import AddUserModal from './modals/AddUserModal/AddUserModal'
import {request_users} from './requests'

const Users = props => {

    const {location, history} = props

    const [loading, setLoading] = useState(false)
    const [users, setUsers] = useState([])
    const [addModal, setAddModal] = useState(false)
    const [totalUsers, setTotalUsers] = useState(10)
    const [filter, setFilter] = useState({
        query:''
    })
    const [tableFilter, setTableFilter] = useState({
        limit:10,
        offset:0,
        order:1,
        order_by:'_id',
    })

    useEffect(() => {
        const initModule = async() => {try {
            setLoading(true)
            const params = onGetParamsFromState(filter, tableFilter)
            const _users = await request_users(params)
            setUsers(_users.data)
            console.log(_users.data)
            setTotalUsers(_users.count)
            onUpdateURLArgs(filter, tableFilter)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }}
        initModule()
    }, [filter, tableFilter])

    useEffect(() => {
        const initModule = async() => {try {
            if(location.search){
                const response = onGetFiltersFromURL(location.search, filter, tableFilter)
                setFilter(response.filter)
                setTableFilter(response.tableFilter)
            }else{
                onUpdateURLArgs(filter, tableFilter)
            }
        } catch (error) {
        }}
        initModule()
    }, [])

    

    const onSelectedItem = (item) => {
        const {_id} = item
        history.push(`/users/${_id}`)
    }
  
    const onChangeFilter = (tag, value) => { // Change generic filter
        let newFilter = onUpdateFilter(tag, value)
        setFilter(newFilter)
    }

    const onUpdateFilter = (id,value) => {
        let temp = {...filter}
        temp[id] = value
        return temp
    }

    const onUpdateUsers = async() => {
        const params = onGetParamsFromState(filter, tableFilter)
        const _users = await request_users(params)
        setUsers(_users.data)
        setTotalUsers(_users.count)
        setAddModal(false)
    }

    return(
        <Page title='Usuarios' >
            <AddUserModal open={addModal} onClose={() => setAddModal(false)} onRequestUsers={onUpdateUsers} history={history}/>
            <SimpleTable
                data={users} 
                filter={filter}
                tableFilter={tableFilter}
                total={totalUsers}
                loading={loading}
                onAddRegister={() => setAddModal(true)}
                onChangeFilter={onChangeFilter}
                onUpdateTableFilter={(data) => setTableFilter(data)}
                onSelectedItem={onSelectedItem}/>
        </Page>
    )
}

export default Users