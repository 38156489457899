import React, { useState, useEffect } from 'react';
import { Card, CircularProgress, ClickAwayListener, Grid, Grow, Icon, makeStyles, Popper } from '@material-ui/core';
import OrderStatus from '../../../../components/Structure/DisplayData/OrderStatus';
import PaymentStatus from '../../../../components/Structure/DisplayData/PaymentStatus';
import DisplayText from '../../../../components/Texts/DisplayText';
import { nodata } from '../../../../variables/texts';
import moment from 'moment-timezone'
import { ORDER_STATUS_TYPES, USER_TYPES } from '../../../../variables/config';
import { catalogs } from '../../../../texts/esp/catalogs';
import { blueGrey, grey } from '@material-ui/core/colors';



const HeaderContainer = ({userType, order, sending, onUpdateOrder}) => {

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState()
    const [selected, setSelected] = useState(1)
    const [options, setOptions] = useState([])


    const onOpenMenu = (e, id) => {
        if(!order) return
        if(id === 1){
            if(order.status_id !== ORDER_STATUS_TYPES.PENDING){
                let order_statuses = JSON.parse(JSON.stringify(catalogs.order_statuses))
                order_statuses.shift()
                order_statuses.shift()
                setAnchorEl(e.currentTarget)

                setOptions(order_statuses)
                setSelected(id)
            }
        }
    }

    const onInnerItemSelected = (identifier) => {
        setAnchorEl(null)
        if(selected === 1){
            onUpdateOrder({status_id:identifier})
        }
        //if(onItemSelected) onItemSelected(identifier)
    }

    
    let date = ''
    let payment_status
    if(order){
        const timezone = moment.tz.guess()
        date = moment(order.created_at).tz(timezone)
        date = date.isValid() ? date.format('DD MMM YYYY, HH:mm:ss') : nodata
        if(order.payment) payment_status = order.payment.status_id
    }


    const buttonContent = <Card className={classes.card}>
        <div className={classes.menu}>
            <Grid container>
                {options.map(item => {
                    return(
                        <Grid item xs={12}>
                            <OptionComponent {...item} key={item.value.toString()} onItemSelected={onInnerItemSelected}/>

                        </Grid>
                    )
                })}
            </Grid>
        </div>
    </Card>

    return ( 
        <div>
            <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement='bottom-end' disablePortal style={{zIndex:10}}>
                <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    <Grow in={Boolean(anchorEl)} timeout={350}>
                        {buttonContent}
                    </Grow>
                </ClickAwayListener>
            </Popper>
            <Grid container spacing={1} alignItems='center'>
                <Grid item>
                    <DisplayText variant='h6'>{`#${order ? order.order_id : nodata}`}</DisplayText>
                </Grid>
                <Grid item>
                    <div onClick={userType === USER_TYPES.ADMIN ? (e) => onOpenMenu(e, 1) : null} style={{cursor:'pointer'}}><OrderStatus status_id={order ? order.status_id : null}/></div>
                </Grid>
                <Grid item>
                    <div onClick={(e) => onOpenMenu(e, 2)} style={{cursor:'pointer'}}><PaymentStatus status_id={payment_status}/></div>
                </Grid>
                <Grid item>
                    {sending ? <CircularProgress size={32}/> : null}
                </Grid>
                <Grid item xs={12}>
                    <DisplayText color='textSecondary'>{date}</DisplayText>
                </Grid>
            </Grid>
        </div>
     );
}

const useStyles = makeStyles(theme=>({
    root:{},
    card:{marginTop:8, borderRadius:16, boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',},
    menu:{marginTop:10, marginBottom:10, width:188, zIndex:4},
    iconButton:{
        background:'white',
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
        '&:hover':{
            background:'white',
        }
    }
}))
 
export default HeaderContainer;

const useStyles4Option = makeStyles(theme=>({
    root:{
        padding:theme.spacing(1),
        paddingLeft:theme.spacing(2),
        paddingRight:theme.spacing(2),
        transition:theme.transitions.create(['background'],{
            duration: theme.transitions.duration.standard,
            easing:theme.transitions.easing.easeIn
        }),
        '&:hover':{
            cursor:'pointer',
            background:blueGrey[50],
            transition:theme.transitions.create(['background'],{
                duration: theme.transitions.duration.standard,
                easing:theme.transitions.easing.easeOut
            }),
        }
    },
    icon:{color:theme.palette.primary.main}
}))

const OptionComponent = props => {

    const {value, color, label, onItemSelected} = props
    const classes = useStyles4Option()

    return(
        <div className={classes.root} onClick={() => onItemSelected(value)}>
           <Grid container alignItems='center' spacing={1} wrap='nowrap'>
                <Grid item><Icon style={{marginTop:-8, fontSize:14, color: color ? color : grey[300]}}>brightness_1</Icon></Grid>
                <Grid item><DisplayText variant='body2'>{label}</DisplayText></Grid>
            </Grid>
        </div>
    )
}