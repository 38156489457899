import queryString from 'query-string'
import { onGetFullname } from '../../../shared/utility'
import moment from 'moment-timezone'
import { catalogs } from '../../../texts/esp/catalogs'
export const onGetParamsFromState = (filter, tableFilter) => {
    let params2send = {...tableFilter}
    params2send.offset = params2send.offset*params2send.limit
    params2send.query = filter.query    
    return params2send
}

export const onUpdateURLArgs = (filter, tableFilter) => {
    let params = {...tableFilter}
    params.offset = params.offset*params.limit
    params.query = filter.query    
    const urlwithargs = `?${queryString.stringify(params, {sort: false})}`
    window.history.replaceState(null, null, urlwithargs);
}

export const onGetFiltersFromURL = (search, filter, tableFilter) => {
    const args = queryString.parse(search)
    const {limit, offset, order, order_by, query} = args
    let newFilter = {...filter}
    let newTableFilter = {...tableFilter}

    if(limit !== undefined) newTableFilter.limit = parseInt(limit)
    if(offset !== undefined) newTableFilter.offset = parseInt(offset)/parseInt(limit)
    if(order !== undefined) newTableFilter.order = parseInt(order)
    if(order_by !== undefined) newTableFilter.order_by = order_by

    if(query !== undefined) newFilter.query = query

    return{
        filter: newFilter,
        tableFilter: newTableFilter
    }

}

export const onCleanData2Excel = (data) => {
  
    let table = [['Cantidad', 'Producto','Laboratorio','No. de orden','Fecha','Hora','Paciente','Cédula','Óptica','Optómetra', 
    'Precio publico','Descuento','Precio optica', 'Costo del producto','Total', 'Total Óptica', 'Costo','Ganancia Óptica','Ganancia Ubelens','Estatus']]
    if(data && data.length){
      data.forEach(item => {

          let quantity = 0
          let product_name = ''
          let product_manufacturer = ''
          let product_brand = ''
          let product_detail = ''
          let product_price = 0
          let product_discount = 0
          let product_optical_price = 0
          let product_supplier_price = 0
          let product_total_price = 0
          let product_total_optical_price = 0
          let product_total_supplier_price = 0

          if(item.line_items){
              quantity = item.line_items.quantity
              product_name = item.line_items.title
              product_detail = item.line_items.details
              product_price = item.line_items.price
              product_discount = item.line_items.price*(item.discount ? item.discount : 0) 
              product_optical_price = item.line_items.optical_price
              product_supplier_price = item.line_items.supplier_price
              product_total_price = item.line_items.total_price - product_discount
              product_total_optical_price = item.line_items.total_optical_price
              product_total_supplier_price = item.line_items.total_supplier_price
              if(item.line_items.product){
                product_manufacturer = item.line_items.product.manufacturer
                product_brand = item.line_items.product.brand
              }
          }

          //let total = item.subtotal ? item.subtotal : 0
          //let optical_total = item.optical_subtotal ? item.optical_subtotal : 0
          //let supplier_total = item.supplier_subtotal ? item.supplier_subtotal : 0

          const optical_gain = product_total_price - product_total_optical_price
          const ubelens_gain = product_total_optical_price - product_total_supplier_price

          let status = ''
          if(item.status_id){
              const temp = catalogs.order_statuses.find(el => el.value === item.status_id)
              if(temp) status = temp.label
          }

          table.push([
            quantity,
            product_name,
            product_manufacturer,
            item.order_id,
            moment(item.created_at).format('YYYY-MM-DD'),
            moment(item.created_at).format('HH:mm:ss'),
            item.user ? onGetFullname(item.user.first_name,item.user.last_name) : '',
            item.user && item.user.identification_card ? item.user.identification_card : '',
            item.optical ? item.optical.business_name : '',
            item.optometrist ? onGetFullname(item.optometrist.first_name,item.optometrist.last_name) : '',
            product_price, 
            product_discount,
            product_optical_price,
            product_supplier_price,
            product_total_price,
            product_total_optical_price,
            product_total_supplier_price,
            optical_gain,
            ubelens_gain,
            status
          ])
      })
    }
    return table
}