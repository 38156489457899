import React from 'react'
import { TextField, FormControl, InputLabel, Select, MenuItem, makeStyles, FormHelperText } from '@material-ui/core'
import { ruleValidation } from './customFunctions'

const useStyles = makeStyles(theme => ({
    margin:{},
    paper:{
        maxHeight:300
    }
}))

const SelectForm = props => {

    const {data, onChange} = props
    const classes = useStyles()

    const onInnerChange = event => {
        const response = event.target.value;
        let temp = {...data};
        temp.value = response;
        temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
        temp.isVisited = true;
        onChange(temp);
    } 

    const {config, value, isValid, isVisited, options} = data
    const {label, helperText} = config

    return(
        <div>
            <FormControl className={classes.margin} color='secondary' fullWidth
            error={isVisited && !isValid} variant='outlined' >
                <InputLabel id="label" style={{background:'white'}}>{label}</InputLabel>
                <Select labelId="label" id="select" value={value} 
                 onChange={onInnerChange}
                 MenuProps={{
                     classes:{paper:classes.paper},
                     variant:'menu'
                 }}
                 >
                    {options.map((item,key)=>{
                        return(
                        <MenuItem value={item.value} key={key.toString()}>{item.label}</MenuItem>
                        )
                    })}
                </Select>
                {isVisited && !isValid ? <FormHelperText error >{helperText}</FormHelperText> : null}
            </FormControl>
        </div>
    )
}

export default SelectForm