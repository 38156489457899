import React from 'react'
import { makeStyles, Breadcrumbs, Link, Icon, Grid, CircularProgress, IconButton, Snackbar } from '@material-ui/core'
import clsx from 'clsx'
import { blue, blueGrey, green } from '@material-ui/core/colors'
import { withRouter } from 'react-router-dom'
import ErrorModal from '../../../modals/ErrorModal'
import DisplayText from '../../Texts/DisplayText'

const useStyles = makeStyles(theme => ({
    root:{
        margin:'auto',
        padding:16,
        boxSizing:'border-box',
    },
    link:{
        color:blueGrey[500],
        fontWeight:400,
        '&:hover':{
            cursor:'pointer'
        }
    },
    selectedLink:{
        color:blueGrey[700],
        fontWeight:500
    },
    icon:{
        color:blueGrey[500],
        paddingTop:4,
        
    },
    loading:{
        height:`calc(100vh - 400px)`
    },
    success:{
        background:green[700]
    },
    sending:{
        background:blue[700]
    }
}))

const Page = props => {

    const classes = useStyles()
    const {children, breadcrumbs, loading, nopadding, error, onCloseErrorModal, title, goback, success, onCloseSuccess, sending} = props

    const onSelectedLink = (url) => {
        if(url) props.history.push(url)
    }

    let titleContent = null
    if(title){
        titleContent = (
            <Grid item xs={12}>
                <Grid container wrap='nowrap' alignItems='center'>
                    {goback ? <Grid item>
                        <IconButton size='small' onClick={() => props.history.goBack()}><Icon fontSize='large'>navigate_before</Icon></IconButton>
                    </Grid> : null}
                    <Grid item xs>
                        <DisplayText variant='h6'>{title}</DisplayText>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    let breadcrumbsContent = null
    if(breadcrumbs){
        breadcrumbsContent = (
            <Grid item xs={12}>
                <Breadcrumbs>
                    <Link className={classes.link} onClick={() => onSelectedLink('/')}
                    ><Icon className={classes.icon}>home</Icon></Link>
                    {breadcrumbs.map((item,key)=>{
                        return(
                            <Link key={key.toString()} 
                            onClick={() => onSelectedLink(item.url)}
                            className={clsx({
                                [classes.link]:true,
                                [classes.selectedLink]:item.selected
                            })}>
                                {item.label}
                            </Link>
                        )
                    })}
                </Breadcrumbs>
            </Grid>
        )
    }

    let contentView = children

    if(loading){
        contentView = (
            <Grid container justify='center'>
                <Grid item>
                    <div className={classes.loading}>
                        <CircularProgress />
                    </div>
                    
                </Grid>
            </Grid>
        )
    }

    const successContent = <Snackbar anchorOrigin={{ vertical:'bottom', horizontal:'left' }} ContentProps={{className:classes.success}} open={Boolean(success)} onClose={onCloseSuccess}  autoHideDuration={1500} 
    message={<Grid container alignItems='center' spacing={1}>
        <Grid item><Icon>done</Icon></Grid>
        <Grid item><DisplayText style={{color:'white'}}>{success}</DisplayText></Grid>
    </Grid>}/>

    const sendingContent = <Snackbar  ContentProps={{className:classes.sending}} open={Boolean(sending)} 
    message={<Grid container alignItems='center' spacing={1}>
        <Grid item><CircularProgress size={18} style={{color:'white'}}/></Grid>
        <Grid item><DisplayText style={{color:'white'}}>Enviando...</DisplayText></Grid>
    </Grid>}  anchorOrigin={{vertical:'bottom', horizontal:'left'}}/>


    return(
        <div className={classes.root} style={nopadding ? {marginTop:0} : undefined}>
            <ErrorModal open={Boolean(error)} message={error} onClose={onCloseErrorModal}/>
            <Grid container spacing={2}>
                {sendingContent}
                {successContent}
                {breadcrumbsContent}
                {titleContent}
                <Grid item xs={12}>
                    {contentView}
                </Grid>
            </Grid>
        </div>
    )
}

export default withRouter(Page)