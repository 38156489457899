const { mainServer } = require("../../../variables/config");

/* export const request_users = async(params) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/opticals/all`, {params:{...params}})
    return response.data.data
} */

/* export const request_opticals = async() => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/opticals/all`)
    return response.data.data
} */
export const request_opticals = async(params) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/opticals/all`, {params:{...params}})
    return response.data.data
}

export const request_create_optica = async(data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.post(`/opticals`, data)
    return response.data.data
}


export const request_delete_optica = async(_id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    await mainServer.delete(`/opticals/${_id}`)
}