import React from 'react';
import SelectForm from '../../../../../components/Forms/SelectForm';
import { catalogs } from '../../../../../texts/esp/catalogs';

const QuantityButton = ({value, onChange}) => {

    const onInnerChange = (data) => {
        onChange(data.value)
    }

    let input_form = {...formData.quantity}
    if(value) input_form.value = value

    return ( 
        <div style={{width:100}}>
            <SelectForm data={input_form} onChange={onInnerChange} />
        </div>
     );
}
 
export default QuantityButton;

const formData = {
    quantity: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:catalogs.quantities,
        config: {
            id: 'quantity',
            type: 'selected',
            fullWidth: true,
            label: 'Cantidad',
            helperText: 'Seleccione al menos una opción',
        },
        rules: {
            type: 'select',
        }
    },
}