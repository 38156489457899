import { Grid, makeStyles} from '@material-ui/core'
import React from 'react'
import DisplayText from '../../../../components/Texts/DisplayText';

const useStyles = makeStyles((theme) => ({
    root:{
        marginTop:32
    },
    normal:{
        padding:'8px 34px',
        textAlign:'center',
        '&:hover':{
            cursor:'pointer'
        }
    },
    selected:{
        padding:'8px 34px',
        textAlign:'center',
        color:theme.palette.primary.main,
        borderBottom: '3px solid',
        borderBottomColor:theme.palette.primary.main
    }
}));

const TopbarOptical = (props) =>{

    const classes = useStyles()

    const {view, optionsTabs, handleView} = props
    
    return(
        <div className={classes.root}>
            <Grid container >
                {optionsTabs.map((item)=>{
                    return(
                        <Grid item xs={12} md key={item.id}>
                            <div className={ view == item.id ? classes.selected : classes.normal}  onClick={()=>{handleView(item.id)}}>
                                <DisplayText variant='subtitle2' >{item.label}</DisplayText>
                            </div>
                        </Grid>
                    )
                })}    
            </Grid>
        </div>
    )


}

export default TopbarOptical
