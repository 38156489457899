import { Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import SimpleModal from '../../../../components/Overlays/SimpleModal';
import DisplayText from '../../../../components/Texts/DisplayText';
import ProducListContainer from '../components/products/ProductListContainer';
import ProductView from '../components/products/ProductView';

const ProductModal = ({orderID, open, onClose, products, formula, onUpdateCompleted}) => {

    const [selected_product, setSelectedProduct] = useState(null)

    useEffect(() => {
        if(!open) setSelectedProduct(null)
    }, [open])

    const onSelectedProduct = (data) => {
        //console.log(data)
        setSelectedProduct(data)
    }
    
    return ( 
        <SimpleModal open={open} onClose={onClose} maxWidth='md'>
            <div>
                <DisplayText color='primary' variant='subtitle1' style={{marginBottom:16}} >Seleccione un producto</DisplayText>
                <Grid container>
                    <Grid item>
                        <ProducListContainer products={products} onSelectedProduct={onSelectedProduct}/>
                    </Grid>
                    <Grid item xs>
                        <ProductView orderID={orderID} product={selected_product} formula={formula} onUpdateCompleted={onUpdateCompleted}/>
                    </Grid>
                </Grid>
            </div>
        </SimpleModal>
    );
}
 
export default ProductModal;