import { CircularProgress, Grid, Icon, IconButton, makeStyles, TextField } from '@material-ui/core';
import { blueGrey } from '@material-ui/core/colors';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useState, useEffect } from 'react';
import DisplayText from '../../../../../components/Texts/DisplayText';
import { onGetFullname } from '../../../../../shared/utility';
import { request_search_optometrists } from '../../requests';

//const add_register = {value:-1, label:'Agregar paciente', type:'add'}

const SearchOptometrist = ({optical_id, onAddOptometrist, setAddOptometrist2Order}) => {

    const classes = useStyles()

    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState([]);
    const [value, setValue] = useState(null)

    const onChange = (e, item) => {
        if(item){
            if(item.type === 'add'){
                onAddOptometrist()
                return
            }
            setValue(item)
            setAddOptometrist2Order(item.value)
        }else{
            setValue(null)
        }
        
    }

    const onInputChange = async(e) => {
        if(!e || e.target.value === 0) return
        //setInputValue(e.target.value)
        try {
            setLoading(true)
            let _response = await request_search_optometrists({
                limit:20, offset:0, order_by:'first_name', 
                order:-1, optical_id:optical_id, query:e.target.value
            })
            setOptions(onGetCatalog(_response.data))
                
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }

    const onGetCatalog = (data) => {
        if(!data) return []
        return data.map(item => {return {value:item._id, label: onGetFullname(item.first_name, item.last_name)}})
    }


    return ( 
        <div>
            <DisplayText variant='subtitle1'>Buscar optómetra</DisplayText>
           <Autocomplete
                id="search-user-id"
                getOptionLabel={(option) => option.label}
                options={options}
                loading={loading} fullWidth
                value={value}
                disabled={!optical_id}
                onChange={onChange}
                onInputChange={onInputChange}
                renderInput={(params) => (
                    <TextField {...params} variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                        <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                        ),
                    }}
                    />
                )}
                renderOption={(option, {selected}) => {

                    if(option.type === 'add'){
                        return(
                            <React.Fragment>
                                <div >
                                    <Grid container alignItems='center'>
                                        <Grid item><Icon color='primary' className={classes.add_icon}>add</Icon></Grid>
                                        <Grid item xs><DisplayText>{option.label}</DisplayText></Grid>
                                    </Grid>
                                    
                                </div>
                            </React.Fragment>
                        )
                    }

                    return (
                        <React.Fragment>
                            <div>
                                <DisplayText>{option.label}</DisplayText>
                            </div>
                        </React.Fragment>
                    )
                }}
            />
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    add_icon:{
        background:theme.palette.primary.main,
        color:'white',
        borderRadius:'50%',
        marginTop:6, marginRight:8
    }
}))
 
export default SearchOptometrist;