import React, {useRef} from 'react'
import ProductCard from '../../../components/Structure/ProductCard/ProductCard';
import { Grid, makeStyles, IconButton, Icon } from '@material-ui/core';
import SimpleButton from '../../../components/Actions/Buttons/SimpleButton';
import { blueGrey } from '@material-ui/core/colors';
import Heading from '../../../components/Texts/Heading';
import DisplayText from '../../../components/Texts/DisplayText';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";


const useStyles = makeStyles(theme => ({
    root:{
        paddingTop:100, paddingBottom:100,
        borderBottom:`1px solid ${blueGrey[50]}`
    },
    product:{
        width:150,
        paddingLeft:16
    },
    iconButton:{
        '&:hover':{background:'transparent'},
        color:blueGrey[700]
    }
}))



const ProductContainer  = props => {

    const classes = useStyles()
    const {onSelectedProduct, onSelectedCollection, data, title, description, button, url} = props
    const sliderRef = useRef(null)

    const settings = {
        dots:true,
        infinite:false,
        speed:500,
        centerPadding: "8px",
        slidesToShow:5,
        slidesToScroll:1,
        initialSlide:0,
        dots:false,
        responsive:[
            {
                breakpoint: 1280,
                settings: {
                    dots:true,
                    infinite:false,
                    speed:500,
                    centerPadding: "8px",
                    slidesToShow:4,
                    slidesToScroll:1,
                    initialSlide:0,
                    dots:false,
                }
            },
        ]
    };

    let contentView

    if(data){
        contentView = (
            <Slider {...settings} ref={sliderRef}>
                {data.map((item,key)=>{
                    return(
                        <div className={classes.product} key={key.toString()}>
                            <ProductCard {...item} onClick={onSelectedProduct}/>
                        </div>
                    )
                })}
            </Slider>
        )
    }else{
        contentView = null
    }

    return(
        <div className={classes.root}>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Heading size='superLarge' align='center'>{title}</Heading>
                        </Grid>
                        <Grid item xs={12}>
                            <DisplayText size='normal' color='bluegrey' align='center'>{description}</DisplayText>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container justify='flex-end' spacing={0}>
                                <Grid item><IconButton size='small' className={classes.iconButton} onClick={() => sliderRef.current.slickPrev()}><Icon>arrow_back_ios</Icon></IconButton> </Grid>
                                <Grid item><IconButton size='small' className={classes.iconButton} onClick={() => sliderRef.current.slickNext()}><Icon>arrow_forward_ios</Icon></IconButton> </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {contentView}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify='center'>
                        <Grid item variant='rounded'><SimpleButton onClick={() => onSelectedCollection(url)} >{button}</SimpleButton> </Grid>
                    </Grid>
                    
                </Grid>
            </Grid>
        </div>
    )
}

export default ProductContainer