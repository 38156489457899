import React from 'react'
import { makeStyles, Grid } from '@material-ui/core'
import Heading from '../../../components/Texts/Heading'
import DisplayText from '../../../components/Texts/DisplayText'

import cellphone from  '../../../assets/cellphone.png'
import chat from  '../../../assets/chat.png'
import shipping from  '../../../assets/free-shipping.png'
import location from  '../../../assets/location.png'
import { blueGrey } from '@material-ui/core/colors'


const useStyles = makeStyles(theme => ({
    root:{
        paddingTop:0,
        paddingBottom:40,
        //borderTop:`1px solid ${blueGrey[50]}`,
        borderBottom:`1px solid ${blueGrey[50]}`
    },
    image:{
        width:48,
        marginBottom:16
    }
}))

const UbelensBenefits = props => {

    const classes = useStyles()

    return(
        <div className={classes.root}>
            <Grid container>
                {content.options.map((item,key)=>{
                    return(
                        <Grid item xs={6} md={3} key={key.toString()}>
                            <div>
                                <Grid container direction='column' alignItems='center'>
                                    <Grid item>
                                        <img src={item.image} alt='' className={classes.image}/>
                                    </Grid>
                                    <Grid item>
                                        <Heading>{item.title}</Heading>
                                    </Grid>
                                    <Grid item>
                                        <DisplayText>{item.subtitle}</DisplayText>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    )
                })}
            </Grid>
        </div>
    )
}

export default UbelensBenefits


const content = {
    options:[
        {
            id:1, 
            title:'ENVÍO GRATIS',
            subtitle:'Todos los pedidos',
            image:shipping
        },
        {
            id:2, 
            title:'TELEFONO',
            subtitle:'+57 (322) 261 2513',
            image:cellphone
        },
        {
            id:3, 
            title:'NUESTRO CHAT',
            subtitle:'',
            image:chat,
        },
        {
            id:4, 
            title:'TIENDAS',
            subtitle:'Conoce nuestras tiendas',
            image:location
        },

    ]
}