import React from 'react'
import { Grid, makeStyles, Radio } from '@material-ui/core'
import { blueGrey } from '@material-ui/core/colors'
import DisplayText from '../../../../components/Texts/DisplayText'
import { nodata } from '../../../../variables/texts'

const useStyles = makeStyles(theme => ({
    root:{
        //border:`1px solid ${blueGrey[100]}`,
        borderRadius:4,
        padding:'8px 16px',
        paddingLeft:2,
        
        '&:hover':{
            cursor:'pointer'
        }
    }
}))

const PaymentOption = props => {

    const {selected, onChange, label, image} = props
    const classes = useStyles()


    return(
        <div className={classes.root}>
            <Grid container alignItems='center' onClick={onChange}>
                <Grid item>
                    <Radio checked={selected} />
                </Grid>
                <Grid item xs>
                    <Grid container>
                        <Grid item xs={12}>
                            <DisplayText variant='body2'>{label ? label : nodata}</DisplayText>
                        </Grid>
                        {image ? (
                            <Grid item xs={12}>
                                <img {...image} />
                            </Grid>
                        ):null}
                    </Grid>
                </Grid>
                
            </Grid>
        </div>
    )
}

export default PaymentOption