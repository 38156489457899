import React, {useState} from 'react'
import { Avatar, Divider, Grid, Icon, IconButton, makeStyles } from '@material-ui/core'
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard'
import DisplayIconData from '../../../../components/Structure/DisplayData/DisplayIconData'
import UserAvatar from '../../../../components/Structure/DisplayData/UserAvatar'
import DisplayText from '../../../../components/Texts/DisplayText'
import { isValidDocument, onGetFullname } from '../../../../shared/utility'
import SearchOptometrist from './forms/SearchOptometrist'
import user_image from '../../../../assets/nouser.png'
import { grey } from '@material-ui/core/colors'
import { nodata } from '../../../../variables/texts'
import { ORDER_STATUS_TYPES } from '../../../../variables/config'

const OptometristCard = ({optical_id, optometrist, setAddPatient2Order, onUpdateOrder, status_id}) => {

    //const {image, first_name, last_name, email, phone_number, setAddPatient2Order} = optometrist
    const classes = useStyles()
    const [add_optometrist_modal, setAddOptometristModal] = useState(false)

    
    
    const onDeletUser = () => {
        onUpdateOrder({optometrist_id:null})
    }

    const onAddOptometrist = (optometrist_id) => {
        onUpdateOrder({optometrist_id:optometrist_id})
    }    
    
   // const fullname = onGetFullname(first_name, last_name)

    return(
        <div>
            <SimpleCard>
                
                {!optometrist ? (
                        <SearchOptometrist optical_id={optical_id} onAddOptometrist={() => setAddOptometristModal(true)} setAddOptometrist2Order={onAddOptometrist}/>
                ) : null}
                {optometrist ? (
                    <div>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Grid container alignItems='center'>
                                    <Grid item xs>
                                        <DisplayText variant='subtitle1'>Datos del optómetra</DisplayText>
                                    </Grid>
                                    {status_id === ORDER_STATUS_TYPES.PENDING ? <Grid item><IconButton size='small' onClick={onDeletUser}><Icon>close</Icon></IconButton></Grid> : null}
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <div>
                                    <Grid container wrap='nowrap' spacing={1}>
                                        <Grid item>
                                            <Avatar src={user_image} alt='' className={classes.avatar}/>
                                        </Grid>
                                        <Grid item xs>
                                            <DisplayText variant='body2'>{optometrist ? onGetFullname(optometrist.first_name, optometrist.last_name) : nodata}</DisplayText>
                                            <DisplayText variant='body2' color='textSecondary'>{optometrist ? optometrist.email : nodata}</DisplayText>

                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                ) : null}
                    
                    
                    {/*<Grid item>
                        <UserAvatar image={image}/>
                    </Grid>
                    <Grid item xs={12}>
                        <DisplayText variant='subtitle1' align='center'>{fullname}</DisplayText>
                    </Grid>
                    <Grid item xs={12}>
                        <DisplayIconData icon='mail' data={email}/>
                    </Grid>
                    <Grid item xs={12}>
                        <DisplayIconData icon='phone' data={phone_number}/>
                    </Grid>*/}
            </SimpleCard>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    avatar:{
        width:48,
        height:48,
        border:`1px solid ${grey[300]}`
    }
}))

export default OptometristCard