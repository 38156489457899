import { Grid, Typography } from '@material-ui/core'
import React, {useState, useEffect} from 'react'
import Page from '../../../../components/Structure/Page/Page'
import { onUpdateURLArgs } from '../../../Orders/Orders/custom_functions'
import { request_patients_by_optical } from '../requests'
import SimpleTable from './Patients/SimpleTable'
import AddPatientModal from './Patients/AddPatientModal'
import SimpleCard from '../../../../components/Structure/Cards/SimpleCardOptical'
import DisplayText from '../../../../components/Texts/DisplayText'

const OpticalPatientsView = (props) => {

    const {id, history} = props

    const [loading, setLoading] = useState(false)
    const [patients, setPatients] = useState([])
    const [addModal, setAddModal] = useState(false)
    const [totalPatients, setTotalPatients] = useState(10)
    const [filter, setFilter] = useState({
        query:''
    })
    const [tableFilter, setTableFilter] = useState({
        limit:10,
        offset:0,
        order:1,
        order_by:'_id',
    })

    useEffect(() => {
        const initModule = async() => {try {
            setLoading(true)
            const _patients = await request_patients_by_optical(id)
            console.log(_patients)
            setPatients(_patients.data)
            setTotalPatients(_patients.count)
            onUpdateURLArgs(filter, tableFilter)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }}
        initModule()
    }, [ filter, tableFilter])

    const onSelectedItem = (item) => {
        const {_id} = item
        history.push(`/patients/${_id}`)
    }
  
    const onChangeFilter = (tag, value) => { // Change generic filter
        let newFilter = onUpdateFilter(tag, value)
        setFilter(newFilter)
    }

    const onUpdateFilter = (id,value) => {
        let temp = {...filter}
        temp[id] = value
        return temp
    }
   
    return(
        <div  >
            <AddPatientModal history={history}  open={addModal} onClose={() => setAddModal(false)} id={id} />
            {/* <Grid container>
                <Grid item xs={12}>
                    <DisplayText variant='subtitle1'>Pacientes</DisplayText>
                </Grid>
                <Grid item xs={12}> */}
                    <SimpleTable
                    data={patients} 
                    filter={filter}
                    tableFilter={tableFilter}
                    total={totalPatients}
                    loading={loading}
                    //onAddRegister={can_add ? () => setAddModal(true) : null}
                    onAddRegister={ () => setAddModal(true) }
                    onChangeFilter={onChangeFilter}
                    onUpdateTableFilter={(data) => setTableFilter(data)}
                    onSelectedItem={onSelectedItem}/>
                {/* </Grid>
            </Grid> */}
        </div>
    )

}

export default OpticalPatientsView