import { updateObject } from '../shared/utility'
import * as actionTypes from './actions'

const initialState = {
    user:null,
    isauth:false,
    cartItems:0,
    recover_password_modal:false,
}

const reducer = (state = initialState, action) => {

    switch(action.type){
        case actionTypes.CHANGE_AUTH_STATUS:
            return updateObject(state, {isauth:action.isauth})

        case actionTypes.AUTH_UPDATE_USER:
            return updateObject(state, {user:action.user})
        case actionTypes.AUTH_RECOVER_PASSWORD_MODAL:
            console.log('Ejectura')
            return updateObject(state, {recover_password_modal:action.recover_password_modal})
        default:
            return state;

    }
}

export default reducer;