import { Grid } from '@material-ui/core'
import React, {useState, useEffect} from 'react'
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard'
import DisplayText from '../../../../components/Texts/DisplayText'
import { request_offices } from '../requests'
import AddOfficeModal from './Office/AddOfficeModal'
import EditOfficeModal from './Office/EditOfficeModal'
import SimpleTable from './Office/SimpleTable'

const OpticalOfficeView = ({id}) => {

    const [loading, setLoading] = useState(false)
    const [offices, setOffices] = useState([])
    const [total_offices, setTotalOffices] = useState(null)
    const [add_office_modal, setAddOfficeModal] = useState (false)
    const [edit_office_modal, setEditOfficeModal] = useState (false)
    const [officeID, setOfficeID] = useState (null)

    useEffect(() => {
        const initModule = async() => {try {
            setLoading(true)
            //order
            /* const params = onGetParamsFromState(filter, tableFilter)
            params.startDate = startDate
            params.endDate = endDate
            params.optical_id = id
            if(status !== -1) params.status_id = status */
            const params = {optical_id: id}
            const _offices = await request_offices(params)
            console.log(_offices.data)
            setOffices(_offices.data)
            setTotalOffices(_offices.count)
            //onUpdateURLArgs(filter, tableFilter)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }}
        initModule()
    }, [])

    const onSelectedItem = (item) => {
        const {_id} = item
        console.log(_id)
        setOfficeID(_id)
        setEditOfficeModal(true)
    }

    const onUpdateCompleted = async () =>{
        try {
            const params = {optical_id: id}
            const _offices = await request_offices(params)
            setOffices(_offices.data)
            setTotalOffices(_offices.count)
            setAddOfficeModal(false)
            setEditOfficeModal(false)
        } catch (error) {
            console.log(error)
        }
    }

    return(
        <div>
            <AddOfficeModal  open={add_office_modal} onClose={() => setAddOfficeModal(false)} id={id} onUpdateCompleted={onUpdateCompleted} />
            <EditOfficeModal  open={edit_office_modal} onClose={() => setEditOfficeModal(false)} id={officeID} onUpdateCompleted={onUpdateCompleted} />
            {/* <Grid container spacing={4} justify='flex-end'>
                <Grid item xs={12}>
                    <DisplayText variant='subtitle1'>Sedes</DisplayText>
                </Grid>
                <Grid item xs={12}> */}
                    <SimpleTable
                    data={offices} 
                    //filter={filter}
                    //tableFilter={tableFilter}
                    total={total_offices}
                    loading={loading}
                    onAddRegister = {() => setAddOfficeModal(true)}
                    //onChangeFilter={onChangeFilter}
                    //onUpdateTableFilter={(data) => setTableFilter(data)}
                    onSelectedItem={onSelectedItem}
                    //onChangeDate={onChangeDate}
                    //endDate={endDate}
                    //startDate={startDate}
                    //status={status}
                    />
        </div>
    )


}


export default OpticalOfficeView