import { Avatar, Button, Divider, Grid } from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard'
import DisplayText from '../../../../components/Texts/DisplayText'
import { useStyles } from './styles'
import { onSetErrorsToForm, isFormValid, onInitForm, onGetSelectedFormData } from '../../../../shared/utility'
import InputForm from '../../../../components/Forms/InputForm'
import SelectForm from '../../../../components/Forms/SelectForm'
import { catalogs } from '../../../../texts/esp/catalogs'
import ContainedButton from '../../../../components/Actions/ContainedButton'
import { request_update_optica, request_update_legalrepresentative } from '../requests'
import OpticalLegalRepresentative from './OpticaILegalRepresentative'



const OpticaGeneralInfo = (props) => {

    const {data, data_lr, id, onUpdate, onDispatchSuccess} = props

    const classes = useStyles()

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [optical, setOptical] = useState(null)
    const [error, setError] = useState(null)

    useEffect(() => {
        const initModule = async() => {try {
            setLoading(true)
            if(data){
                const _form = onInitForm(form, data)
                setOptical(data)
                setForm(_form)

            }
           // console.log(_form)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }}
        initModule()
    }, [data])

    //console.log(lr)

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            console.log('hay errores')
            console.log(errors)
            return
        }

        //Extract data
        let data2send = onGetSelectedFormData(form, optical)
        //console.log(data2send)
        //console.log(id)

        setSending(true)
        try {
            const _optical = await request_update_optica(id, data2send);
            setOptical(_optical)
            onUpdate(_optical)
            setSending(false)
            onDispatchSuccess('Acción exitosa')
            //funcion para actualizar info_card
            

        } catch (error) {
            console.log(error)
            setError('Hubo un error')
            setSending(false)
        }

    }

    let opticaType = null

    if(form.business_type_id.value== 2){
        form.lr_name.isRequired=true
        form.lr_identification_card.isRequired=true
        opticaType = <Grid container spacing={4} justify='flex-end'>
                        <Grid item xs={12}>
                            <DisplayText variant='subtitle1'>Representante Legal</DisplayText>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputForm data={form.lr_name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputForm data={form.lr_identification_card} onChange={onChange} />
                        </Grid>
                    </Grid>
    }
    if(form.business_type_id.value == 1){
        form.lr_name.isRequired=false
        form.lr_identification_card.isRequired=false
    }


    return(
        <div>
            {/* <Grid container spacing={3}>
                <Grid item md={8} xs={12}> */}
                    <SimpleCard>
                        <Grid container spacing={4} justify='flex-end'>
                            <Grid item xs={12}>
                                <DisplayText variant='subtitle1'>Información general</DisplayText>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} sm={8} >
                                        <InputForm data={form.business_name} onChange={onChange} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} >
                                        <SelectForm data={form.business_type_id} onChange={onChange} />
                                    </Grid>                                 
                                    <Grid item xs={12} sm={6}>
                                        <InputForm data={form.identification_card} onChange={onChange} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <InputForm data={form.email} onChange={onChange} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <InputForm data={form.phone_number} onChange={onChange} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <InputForm data={form.cellphone} onChange={onChange} />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <SelectForm data={form.status_id} onChange={onChange} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {opticaType}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <ContainedButton color='primary' loading={sending} onClick={onSubmit}>Guardar</ContainedButton>
                            </Grid>
                        </Grid>
                    </SimpleCard>
                {/* </Grid>
            </Grid> */}
        </div>
    )
}

export default OpticaGeneralInfo

const formData = {
    //GENERAL
    business_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'business_name',
            type: 'text',
            fullWidth: true,
            label: 'Nombre de la óptica',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    business_type_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...catalogs.opticals_types],
        config: {
            id: 'business_type_id',
            type: 'select',
            fullWidth: true,
            label: 'Tipo de negocio',
            helperText: 'Debe elegir una opción',
        },

    },
    identification_card: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'identification_card',
            type: 'text',
            fullWidth: true,
            label: 'Cédula/NIT',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    email: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'email',
            fullWidth: true,
            label: 'Dirección de correo',
            helperText: 'Dirección de correo no válido',
            disabled:true,
        },
        rules: {
            type: 'email',
        }
    },
    phone_number: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'phone_number',
            type: 'text',
            fullWidth: true,
            label: 'Número de telefono',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    cellphone: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'cellphone',
            type: 'text',
            fullWidth: true,
            label: 'Celular',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    lr_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'lr_name',
            type: 'text',
            fullWidth: true,
            label: 'Nombre',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    lr_identification_card: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'lr_identification_card',
            type: 'text',
            fullWidth: true,
            label: 'Cédula',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    status_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...catalogs.user_statuses],
        config: {
            id: 'status_id',
            type: 'select',
            fullWidth: true,
            label: 'Estatus',
            helperText: 'Debe elegir una opción',
        },

    },
}