import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { blueGrey, grey } from '@material-ui/core/colors'
import DisplayText from '../../../../components/Texts/DisplayText'
import Heading from '../../../../components/Texts/Heading'

const useStyles = makeStyles(theme => ({
    row:{
        borderTop:`1px solid ${blueGrey[50]}`,
        paddingTop:theme.spacing(2),
        paddingBottom:theme.spacing(2)
    },
    table:{
        paddingTop:theme.spacing(3)
    }
}))

const DescriptionContainer = props => {

    const {data} = props
    const classes =useStyles()

    return(
        <div>
            <Grid container>
                <Grid item xs={12}>
                    <div className={classes.table}>
                        <Grid container>
                            {data.map((item,key)=>{
                                return(
                                    <Grid item xs={12} key={key.toString()}>
                                        <div className={classes.row}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={4}>
                                                    <Heading >{item.label}</Heading>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <DisplayText  >{item.value}</DisplayText>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </div>
                </Grid>

            </Grid>
        </div>
    )
}

export default DescriptionContainer