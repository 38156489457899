import React from 'react'
import Slider from "react-slick";
import { makeStyles, Grid } from '@material-ui/core';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Heading from '../../Texts/Heading';
import { blueGrey } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
    root:{
        paddingTop:60,
        paddingBottom:40,
        //borderTop:`1px solid ${blueGrey[50]}`,
        //borderBottom:`1px solid ${blueGrey[50]}`
    },
    container:{
        padding:theme.spacing(1),
        paddingTop:theme.spacing(2)
    }

}))

const CustomerSlider = props => {

    const {brands} = props
    const classes = useStyles()

    const settings = {
        centerMode: true,
        infinite: true,
        centerPadding: "8px",
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 3500,
        cssEase: "linear",
        arrows:true,
        
    };
    

    return(
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Heading align='center' size='extraLarge' >Nuestras marcas</Heading>
                </Grid>
                <Grid item xs={12}>
                    <Slider {...settings}>
                        {brands.map((item,key)=>{
                            return(
                                <div key={key.toString()}>
                                    <img src={item.image} alt={''} className={classes.image}/>
                                </div>
                            )
                        })}
                    </Slider>
                </Grid>
            </Grid>
            
        </div>
    )
}

export default CustomerSlider

