import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { useStyles } from './styles'
import SimpleModal from '../../../../../components/Overlays/SimpleModal'
import DisplayText from '../../../../../components/Texts/DisplayText'
import InputForm from '../../../../../components/Forms/InputForm'
import ContainedButton from '../../../../../components/Actions/ContainedButton'
import { isFormValid, onGetFormData, onSetErrorsToForm } from '../../../../../shared/utility'
import {request_create_office} from '../../requests'


const AddOfficeModal = props => {

    const { open, onClose, id , onUpdateCompleted} = props

    const classes = useStyles()
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')

    useEffect(() => {
        if (!open) {
            setForm(JSON.parse(JSON.stringify(formData)))
            setError(''); setLoading(false);
        }
    }, [open])


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        if(id === 'password') temp['passwordConfirmation'].value2 = temp[id].value
        setForm(temp)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetFormData(form)
        data2send.optical_id = id
        data2send.address = {address1 : data2send.address1}
        delete data2send.address1
        console.log(data2send)
        //delete data2send.passwordConfirmation

        //falta probrar*
        setLoading(true)
        try {
            await request_create_office(data2send);
            setLoading(false)
            onUpdateCompleted()
        } catch (error) {
            console.log(error)
            setError('Hubo un error')
            setLoading(false)
        }

    }

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div >
                <DisplayText variant='h5' color='primary' style={{ fontWeight: 500 }}>Agregar sede</DisplayText>
                <DisplayText >Por favor ingresa los datos solicitados</DisplayText>
                <div className={classes.form}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <InputForm data={form.branch_name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.phone} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.cellphone} onChange={onChange} />
                        </Grid>
                    </Grid>
                </div>
                <ContainedButton color='primary' fullWidth onClick={onSubmit} loading={loading}>
                    Agregar
                </ContainedButton>
                <DisplayText color='error' align='center'>{error}</DisplayText>
            </div>
        </SimpleModal>
    )
}

export default AddOfficeModal


const formData = {
    branch_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'branch_name',
            type: 'text',
            fullWidth: true,
            label: 'Nombre',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    phone: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'phone',
            type: 'number',
            fullWidth: true,
            label: 'Teléfono',
            helperText: 'El valor ingresado no es válido'
        },
        rules: {
            type: 'phone',
        }
    },
    cellphone: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'cellphone',
            type: 'number',
            fullWidth: true,
            label: 'Celular',
            helperText: 'El valor ingresado no es válido'
        },
        rules: {
            type: 'phone',
        }
    },
    
}

