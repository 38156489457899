import { Button, Grid, makeStyles} from '@material-ui/core'
import { blueGrey } from '@material-ui/core/colors';
import React from 'react'


const useStyles = makeStyles((theme) => ({
    root:{
        //marginTop:32
    },
    normal:{
        padding:'8px 34px',
        textAlign:'center',
        '&:hover':{
            cursor:'pointer'
        }
    },
    selected:{
        borderRadius:16,
        padding:'8px 24px',
        backgroundColor:theme.palette.primary.main,
        color:'white',
        '&:hover':{
            backgroundColor:theme.palette.primary.main,
            color:'white',
        }
    },
    button:{
        borderRadius:16,
        padding:'8px 24px',
        backgroundColor:blueGrey[100],
        color:'black',
        '&:hover':{
            backgroundColor:blueGrey[100],
            color:'black',
        }
    }
}));

const TopbarButtons = (props) =>{

    const classes = useStyles()

    const {view, optionsTabs, handleView} = props
    
    return(
        <div className={classes.root}>
            <Grid container spacing={2} >
                {optionsTabs.map((item)=>{
                    return(
                        <Grid item key={item.id}>
                            <Button className={ view == item.id ? classes.selected : classes.button}  onClick={()=>{handleView(item.id)}}>
                                {item.label}
                            </Button>
                        </Grid>
                    )
                })}    
            </Grid>
        </div>
    )


}

export default TopbarButtons
