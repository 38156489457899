import React from 'react'
import { Button, CircularProgress, Grid, Icon, IconButton, LinearProgress, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core'
import DisplayText from '../../../../components/Texts/DisplayText'
import { onFormatNumber, onGetFormatNumber, onGetFullname } from '../../../../shared/utility'
import { blueGrey, grey } from '@material-ui/core/colors'
import SearchBar from './SearchBar'
import ActionBar from './ActionBar'
import empty from '../../../../assets/empty.png'
import moment from 'moment-timezone'
import { nodata } from '../../../../variables/texts'
import OrderStatus from '../../../../components/Structure/DisplayData/OrderStatus'
import StatusMenu from './StatusMenu'
import FilterDate from './FilterDate'
import FilterButton from './FilterButton2'
import {catalogs} from '../../../../texts/esp/catalogs'
import { ORDER_STATUS_TYPES } from '../../../../variables/config'
import { isAbstractType } from 'graphql'

//const statusCatalog = [{id:-1, value:-1, label:'Todos'}, {id:1, value:1, label:'Activo'},{id:0, value:0, label:'Inactivo'}]
//const searchCatalog = [{id:1, value:'first_name', label:'Nombre '},{id:2, value:'username', label:'Usuario'}]

const header = [
    {id:1, label:' ID'},
    {id:2, label:'Fecha de pedido'},
    {id:3, label:'Paciente'},
    {id:3, label:'Cédula'},
    {id:6, label:'Óptica'},
    {id:3, label:'Optómetra'},
    {id:4, label:'Total'},
    {id:5, label:'Estatus'},
    {id:7, label:''}
]


const useStyles = makeStyles(theme => ({
    root:{},
    paper:{
        borderRadius:16,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    cell:{
        borderColor:grey[200],
    },
    row:{
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    cellHeader:{
        borderColor:grey[300],
        paddingTop:16,
        paddingBottom:16,
    },
    button:{
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    toolbar:{
        marginBottom:16
    },
    actions:{
        marginTop:16
    },
    table:{
        minWidth:800
    },
    tableWrapper:{
        overflowX: 'auto',
    },
    nodata:{
        marginTop:40
    },
    reportButton:{
        background:theme.palette.primary.main,
        color:'white',
        padding:'10px 16px',
        borderRadius:32,
        '&:hover':{
            background:theme.palette.primary.dark,
        }

    }
}))

const SimpleTable = props => {

    const classes = useStyles()
    const {data, onAddRegister, filter, onChangeFilter, total, tableFilter, onUpdateTableFilter, onSelectedItem, loading, onUpdateOrder,
            startDate, onChangeDate, endDate, status, onGenerateReport, isadmin, onDeleteItem} = props
    
    
    return(
        <div>
            <div className={classes.toolbar}>
                {/* <Grid container justify='flex-end' spacing={2} alignItems='flex-end' >  */}
                   
                        <Grid container  spacing={2} alignItems='flex-end'>
                            <Grid item><FilterDate id='start_date' label='Fecha inicial' value={startDate} onChange={onChangeDate}/></Grid>
                            <Grid item><FilterDate id='end_date' label='Fecha final' value={endDate} onChange={onChangeDate}/></Grid>
                            <Grid item><FilterButton label='Estatus' options={catalogs.order_statuses} selected={status} onChange={(val) => onChangeFilter('status', val)}/></Grid>
                            <Grid item xs/>
                            {onGenerateReport ? <Grid item><Button onClick={onGenerateReport} className={classes.reportButton}>Reporte<Icon>download</Icon></Button></Grid> : null}
                            <Grid item><SearchBar value={filter.query} onChange={(value) => onChangeFilter('query', value)} /></Grid>
                            {onAddRegister ? <Grid item><IconButton className={classes.button} onClick={onAddRegister}><Icon>add</Icon></IconButton></Grid> : null}
                        </Grid>
                    
                    
                    {/* <Grid item><IconButton className={classes.button} onClick={onAddRegister}><Icon>add</Icon></IconButton></Grid> */}
                {/* </Grid> */}
            </div>
            {loading ? (
                <div style={{marginBottom:16}}>
                <LinearProgress color='primary'/>
                </div>
            ) : null}
            <Paper className={classes.paper}>
                {data && data.length ? (
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table}>
                            {header ? (
                                <TableHead>
                                    <TableRow>
                                        {header.map(item=>{
                                            return(
                                                <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                    <DisplayText variant='subtitle2' >{item.label}</DisplayText>
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                            ):null}
                            <TableBody>
                                {data.map((item,key) => {
                                    const {created_at, total, email, payment_status_id, status_id, order_id, payment, user, optometrist, optical, _id} = item

                                    const timezone = moment.tz.guess()
                                    let current_date = moment(created_at).tz(timezone)
                                    current_date = current_date.isValid() ? current_date.format('DD MMM YY') : nodata

                                    let _payment_status = null
                                    if(payment) _payment_status = payment.status_id
                                    //console.log(user)
                                    return(
                                        <TableRow key={key.toString()} className={classes.row} >
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><DisplayText variant='subtitle1'>{order_id}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><DisplayText>{current_date}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><DisplayText>{onGetFullname(user ? user.first_name : null,user ? user.last_name : null)  }</DisplayText></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><DisplayText>{user && user.identification_card ? user.identification_card : nodata}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><DisplayText>{optical ? optical.business_name : nodata}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><DisplayText>{onGetFullname(optometrist ? optometrist.first_name : null,optometrist ? optometrist.last_name : null)}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><DisplayText>{onGetFormatNumber(total)}</DisplayText></TableCell>
                                            {/* <TableCell className={classes.cell}><OrderStatus status_id={status_id}/></TableCell> */}
                                            <TableCell className={classes.cell}><StatusMenu orderStatus={status_id} onUpdateOrder={onUpdateOrder} orderID={_id} isadmin={isadmin}/></TableCell>
                                            { isadmin ? <TableCell className={classes.cell}><IconButton disabled={status_id !== ORDER_STATUS_TYPES.PENDING} onClick={() => onDeleteItem(_id)}><Icon>delete</Icon></IconButton></TableCell> : <TableCell className={classes.cell}></TableCell>}
                                        </TableRow>
                                    )
                                })}
                                
                            </TableBody>
                        </Table>
                    </div>
                    
                ): null}
            </Paper>
            {data && data.length ? <div className={classes.actions}>
                <ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div> : null}
            {data.length === 0 && !loading ? (
                <div className={classes.nodata}>
                    <Grid container justify='center' alignItems='center' direction='column'>
                        <Grid item>
                            <img src={empty} alt='' width={180}/>
                        </Grid>
                        <Grid item>
                            <DisplayText>No existen órdenes registradas</DisplayText>
                        </Grid>
                    </Grid>
                </div>
                
            ) : null}
            
        </div>
    )
}

export default SimpleTable