import { Avatar, Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import nouser from '../../../assets/nouser.png'
import { isValidDocument } from '../../../shared/utility'
import { config_data } from '../../../variables/config'
import DisplayText from '../../Texts/DisplayText'



const useStyles = makeStyles(theme => ({
    avatar:{

    }
}))

const UserAvatarName = props => {

    const classes = useStyles()
    const {image, name} = props
    const isValid = isValidDocument(image)
    const selectedImage = isValid ? `${config_data.S3_SERVER_URL}${image}` : nouser

    return(
        <div>
            <Grid container alignItems='center' wrap='nowrap' spacing={1}>
                <Grid item>
                    <Avatar src={selectedImage} alt='' className={classes.avatar}/>
                </Grid>
                <Grid item>
                    <DisplayText variant='subtitle1'>{name}</DisplayText>
                </Grid>
            </Grid>
        </div>
    )
}

export default UserAvatarName