import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { isFormValid, onGetErrorMessage, onGetFormData, onSetErrorsToForm } from '../../../../../shared/utility'
import { useStyles } from './styles'
import DisplayText from '../../../../../components/Texts/DisplayText'
import SelectForm from '../../../../../components/Forms/SelectForm'
import { catalogs } from '../../../../../texts/esp/catalogs'
import InputForm from '../../../../../components/Forms/InputForm'
import SimpleModal from '../../../../../components/Overlays/SimpleModal'
import ContainedButton from '../../../../../components/Actions/ContainedButton'
import InputCheckbox from '../../../../../components/Forms/InputCheckbox'
import { request_create_patient } from '../../../../Patients/Patients/requests'


const AddPatientModal = props => {

    const { id, open, onClose, history } = props

    const classes = useStyles()
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')

    useEffect(() => {
        if (!open) {
            setForm(JSON.parse(JSON.stringify(formData)))
            setError(''); setLoading(false);
        }
    }, [open])


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        if(id === 'password') temp['passwordConfirmation'].value2 = temp[id].value
        setForm(temp)
        setError(null)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetFormData(form)
        data2send.optical_id = id
        delete data2send.passwordConfirmation

        if(!data2send.legal){
            setError('Debe aceptar los términos y condiciones')
            return
        }

        console.log(data2send)
        /* if(user && user.user_type_id === USER_TYPES.OPTICAL){
            data2send.optical_id = user._id
        }

        if(user && user.user_type_id === USER_TYPES.OPTOMETRIST){
            data2send.optometrist_id = user._id
            data2send.optical_id = user.optical_id
        } */


        setLoading(true)
        try {
            const _user = await request_create_patient(data2send);
            history.push(`/patients/${_user._id}`)
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
            setLoading(false)
        }

    }

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div >
                <DisplayText variant='h5' color='primary' style={{ fontWeight: 500 }}>Agregar paciente</DisplayText>
                <DisplayText >Por favor ingresa los datos solicitados</DisplayText>
                <div className={classes.form}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <InputForm data={form.first_name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputForm data={form.last_name} onChange={onChange} />
                        </Grid>
                        
                        <Grid item xs={12}>
                            <InputForm data={form.email} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <SelectForm data={form.gender_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputCheckbox data={form.marketing} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputCheckbox data={form.legal} onChange={onChange} />
                        </Grid>
                        
                    </Grid>
                </div>
                <ContainedButton color='primary' fullWidth onClick={onSubmit} loading={loading}>
                    Agregar
                </ContainedButton>
                <DisplayText color='error' align='center'>{error}</DisplayText>
            </div>
        </SimpleModal>
    )
}

export default AddPatientModal


const formData = {
    first_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'first_name',
            type: 'text',
            fullWidth: true,
            label: 'Nombre(s)',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    last_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'last_name',
            type: 'text',
            fullWidth: true,
            label: 'Apellido(s)',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    email: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'email',
            fullWidth: true,
            label: 'Dirección de correo',
            helperText: 'Dirección de correo no válido'
        },
        rules: {
            type: 'email',
        }
    },
    gender_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...catalogs.genders],
        config: {
            id: 'gender_id',
            type: 'select',
            fullWidth: true,
            label: 'Género',
            helperText: 'Debe elegir una opción',
        },

    },
    legal: {
        value: false,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        config: {
            id: 'legal',
            type: 'checbox',
            fullWidth: true,
            label: 'He leído y acepto la Política de Tratamiento de Datos Personales de Ubelens',
            helperText: '',
        },
    },
    marketing: {
        value: true,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        config: {
            id: 'marketing',
            type: 'checbox',
            fullWidth: true,
            label: 'Acepto recibir publicidad o información promocional de Ubelens Colombia S.A.S',
            helperText: 'Debe elegir una opción',
        },
    },
}