import React from 'react'
import { Avatar, Grid, makeStyles } from '@material-ui/core'
import { isValidDocument } from '../../../shared/utility'
import nouser from '../../../assets/nouser.png'
import { config_data } from '../../../variables/config'

const useStyles = makeStyles(theme => ({
    avatar:{
        width:72,
        height:72,
        border:`6px solid ${theme.palette.primary.main}`,
        padding:16
    }
}))

const UserAvatar = (props) => {

    const classes = useStyles()

    const {image, name} = props
    const isValid = isValidDocument(image)
    const selectedImage = isValid ? `${config_data.S3_SERVER_URL}${image}` : nouser
    

    /* const im = "products/image-817436cd-9fcf-4935-afcb-02c6c60f9edb.jpg"
    //const im ="optical-shops/image-db7a1689-f6c8-498c-aa9d-d2db4179d466.png"
    const imgSrc = im ? `${config_data.S3_SERVER_URL}${im}` : null
    console.log(imgSrc)
    console.log(selectedImage) */

    return(
        <div>
            <Avatar src={selectedImage} alt='' className={classes.avatar}/>
        </div>
    )
}

export default UserAvatar