import React, {useEffect, useState} from 'react'
import { Grid, Hidden } from '@material-ui/core'
import Page from '../../../components/Structure/Page/Page'
import OpticaInfoCard from './components/OpticaInfoCard'
import { request_optica} from './requests'
import { useStyles } from '../Opticals/modals/AddOpticaModal/styles'
import OpticalInfoView from './views/OpticalInfoView'
import OpticalOptometristView from './views/OpticalOptometristView'
import OpticalOrdersView from './views/OpticalOrdersView'
import OpticalPatientsView from './views/OpticalPatientsView'
import PictureModal from './modals/PictureModal'
import ChangeImageModal from './modals/ChangeImageModal'
import UpdatePasswordModal from './modals/UpdatePasswordModal'
import TopbarButtons from '../../../components/Forms/TopbarButtons'
import OpticalOfficeView from './views/OpticalOfficeView'
import PageMenuMobile from '../../../components/Forms/PageMenuMobile'

const UserView = props => {

    const {location, history, match} = props

    const classes = useStyles()
    
    const [loading, setLoading] = useState(false)
    const [optical, setOptical] = useState(null)
    const [view, setView] = useState(1);
    const [img, setImg] = useState(null)

    /*-------------MODALS STATES-------------- */
    const [profile_modal, setProfileModal] = useState(false)
    const [update_image_modal, setUpdateImageModal] = useState(false)
    const [password_modal, setPasswordModal] = useState(false)

    const optical_id = match.params.id

    useEffect(() => {
        const initModule = async() => {try {
            setLoading(true)
            const _optical = await request_optica(optical_id)
            setOptical(_optical)
            //console.log('optical hook')
            console.log(_optical)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }}
        initModule()
    }, [])

    const handleView = (newValue) => {
        setView(newValue);
        //console.log(value)
    };

    const onUpdateInfoCard = (newOptical) =>{
        setOptical(newOptical)
        console.log('hello')
    }

    /*-------------IMAGE MODAL'S METHODS-------------- */
    const onUpdateCompleted = async() => {
        try {
            const _optical = await request_optica(optical_id)
            setOptical(_optical)
            setUpdateImageModal(false)
        } catch (error) {
            console.log(error)
        }
        
    }

    const onUpdateImg = (newImg) =>{
        setImg(newImg)
    }

    //orders
    const onSelectedItem = (item) => {
        const {_id} = item
        history.push(`/orders/${_id}`)
    }

    let viewRender = <OpticalInfoView onUpdateView={onUpdateInfoCard} id={optical_id}/>

    switch(view){
        case 1:{
            viewRender= <OpticalInfoView onUpdateView={onUpdateInfoCard} id={optical_id} history={history}/>
            break;
        }
        case 2:{
            viewRender= <OpticalOptometristView id={optical_id} history={history} />
            break;
        }
        case 3:{
            viewRender= <OpticalOrdersView id={optical_id} location={location} onSelectedItem={onSelectedItem}/>
            break;
        }
        case 4:{
            viewRender= <OpticalPatientsView id={optical_id} history={history} />
            break;
        }
        case 5:{
            viewRender= <OpticalOfficeView id={optical_id} />
            break;
        }
    }

    //const general_info_data = {...optical.optical_shop, ...optical.user_info}
    //const businessID = 1
    const optionsTabs = [
        {label:'General', id:1},
        {label:'Optómetras', id:2},
        {label:'Pedidos', id:3},
        {label:'Pacientes', id:4},
        {label:'Sedes', id:5},
    ]

    return(
        <Page title='Información de la óptica' loading={loading} goback>
            <UpdatePasswordModal id={optical_id} open={password_modal} onClose={() => setPasswordModal(false)} onUpdateCompleted ={onUpdateCompleted}/>
            <ChangeImageModal id={optical_id} open={update_image_modal} onClose={() => setUpdateImageModal(false)} onUpdateCompleted ={onUpdateCompleted}/>
            <Grid container spacing={3}>
                <Grid item xs={12} >
                    <OpticaInfoCard 
                    {...optical} 
                    view={view} 
                    handleView={handleView} 
                    onChangePicture={() => setUpdateImageModal(true)}
                    img={img}
                    onChangePassword={() => setPasswordModal(true)}  
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Hidden smDown>
                                <TopbarButtons optionsTabs={optionsTabs} view={view} handleView={handleView} />
                            </Hidden>
                            <Hidden mdUp>
                                <PageMenuMobile selected={view} onChange={handleView} options={optionsTabs} />
                            </Hidden>
                        </Grid>
                        <Grid item xs={12}>
                            {viewRender}
                        </Grid>
                    </Grid>
                </Grid> 
            </Grid>
        </Page>
    )
}


export default UserView

/* const formData = {
    business_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'business_name',
            type: 'text',
            fullWidth: true,
            label: 'Nombre de la óptica',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    business_type_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...catalogs.opticals_types],
        config: {
            id: 'business_type_id',
            type: 'select',
            fullWidth: true,
            label: 'Tipo de negocio',
            helperText: 'Debe elegir una opción',
        },

    },
    identification_card: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'identification_card',
            type: 'text',
            fullWidth: true,
            label: 'Cédula',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    email: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'email',
            fullWidth: true,
            label: 'Dirección de correo',
            helperText: 'Dirección de correo no válido',
            disabled:true,
        },
        rules: {
            type: 'email',
        }
    },
    phone_number: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'phone_number',
            type: 'text',
            fullWidth: true,
            label: 'Número de telefono',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    cellphone: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'cellphone',
            type: 'text',
            fullWidth: true,
            label: 'Celular',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    legal_representative:{
        value:'',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'legal_representative',
            type: 'text',
            fullWidth: true,
            label: 'Nombre del representante legal',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    //ADDRESS
    prueba: {
        first_name_p: {
            value: '',
            error: false,
            isVisited: false,
            isRequired: true,
            isValid: false,
            config: {
                id: 'first_name_p',
                type: 'text',
                fullWidth: true,
                label: 'Nombre prueba',
                helperText: 'Este campo debe de contener al menos un caracter'
            },
            rules: {
                type: 'distance',
                min: 1, max: 256
            }
        },
        last_name_p: {
            value: '',
            error: false,
            isVisited: false,
            isRequired: true,
            isValid: false,
            config: {
                id: 'last_name_p',
                type: 'text',
                fullWidth: true,
                label: 'Apellidos prueba',
                helperText: 'Este campo debe de contener al menos un caracter'
            },
            rules: {
                type: 'distance',
                min: 1, max: 256
            }
        },
    },
    first_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'first_name',
            type: 'text',
            fullWidth: true,
            label: 'Nombre(s)',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    last_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'last_name',
            type: 'text',
            fullWidth: true,
            label: 'Apellidos',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    phone: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'phone',
            type: 'text',
            fullWidth: true,
            label: 'Teléfono',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    address1: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'address1',
            type: 'text',
            fullWidth: true,
            label: 'Dirección',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    province: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'province',
            type: 'text',
            fullWidth: true,
            label: 'Provincia',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    city: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'city',
            type: 'text',
            fullWidth: true,
            label: 'Ciudad',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    country: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'country',
            type: 'text',
            fullWidth: true,
            label: 'País',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    zip: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'zip',
            type: 'text',
            fullWidth: true,
            label: 'Código postal',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
} */