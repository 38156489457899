import { Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import SimpleModal from '../../../../components/Overlays/SimpleModal';
import DisplayText from '../../../../components/Texts/DisplayText';
import SearchBar from '../../Orders/components/SearchBar';
import ProducListContainer from '../components/products/ProductListContainer';
import ProductView from '../components/products/ProductView';

const DropsModal = ({orderID, open, onClose, products, formula, onUpdateCompleted}) => {

    const [selected_product, setSelectedProduct] = useState(null)

    const [search, setSearch] = useState('')
    const [filter, setFilter] = useState([])

    let filter_products = []

    useEffect(() => {
        if(!open) {
            setSelectedProduct(null)
            setSearch('')
            
        }else{
            onChangeFilter('')
        }
    }, [open, products])


    const onSelectedProduct = (data) => {
        //console.log(data)
        setSelectedProduct(data)
    }

    const onChangeFilter = (value) => {
        setSearch(value)
    
        products.forEach(item => {
            const title = item.title
            if(title.toLowerCase().includes(value.toLowerCase())) filter_products.push(item)
        })
        setFilter(filter_products)
    }
        

    return ( 
        <SimpleModal open={open} onClose={onClose} maxWidth='md'>
            <div>
                <DisplayText color='primary' variant='subtitle1' style={{marginBottom:16}} >Ingrese el nombre de un producto</DisplayText>
                <SearchBar value={search} onChange={(value) => onChangeFilter(value)} fullWidth/>
                <Grid container>
                    <Grid item>
                        <ProducListContainer products={filter} onSelectedProduct={onSelectedProduct}/>
                    </Grid>
                    <Grid item xs>
                        <ProductView orderID={orderID} product={selected_product} formula={formula} onUpdateCompleted={onUpdateCompleted}/>
                    </Grid>
                </Grid>
            </div>
        </SimpleModal>
    );
}
 
export default DropsModal;