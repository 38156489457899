import React from 'react'
import { Grid, Icon, makeStyles } from '@material-ui/core'
import { nodata } from '../../../variables/texts'
import DisplayText from '../../Texts/DisplayText'
import { blueGrey } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
    icon:{
        color:theme.palette.primary.main,
        border:`1px solid ${blueGrey[100]}`,
        borderRadius:'50%',
        padding:4    
    }
}))

const DisplayIconData = ({icon, data}) => {

    const classes = useStyles()

    return(
        <Grid container spacing={1} wrap='nowrap'>
            <Grid item>
                <Icon className={classes.icon}>{icon}</Icon>
            </Grid>
            <Grid item xs>
                <DisplayText>{data ? data : nodata}</DisplayText>
            </Grid>
        </Grid>
    )
}

export default DisplayIconData