import { Grid, makeStyles } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import React from 'react'
import visa from '../../../../assets/cards/visa.png'
import mastercard from '../../../../assets/cards/mastercard.png'
import amex from '../../../../assets/cards/american-express.png'
import codensa from '../../../../assets/cards/codensa.png'
import diners from '../../../../assets/cards/diners-club.png'
import DisplayText from '../../../Texts/DisplayText'

const useStyles = makeStyles(theme => ({
    cards:{
        width:58,
        paddingTop:'66%',
        boxSizing:'border-box',
        borderRadius:4,
        background:grey[200],
        position:'relative'
    },
    image:{
        width:40,
        position:'absolute',
        top:'50%',
        left:'50%',
        transform:'translate(-50%, -50%)'
    }
}))

const PaymentContent = props => {

    const classes = useStyles()

    return(
        <Grid container spacing={2} justify='center'>
            <Grid item>
                <div className={classes.cards}>
                    <img src={visa} alt='' className={classes.image}/>
                </div>
            </Grid>
            <Grid item>
                <div className={classes.cards}>
                    <img src={mastercard} alt='' className={classes.image}/>
                </div>
            </Grid>
            <Grid item>
                <div className={classes.cards}>
                    <img src={amex} alt='' className={classes.image}/>
                </div>
            </Grid>
            <Grid item>
                <div className={classes.cards}>
                    <img src={diners} alt='' className={classes.image}/>
                </div>
            </Grid>
            <Grid item>
                <div className={classes.cards}>
                    <img src={codensa} alt='' className={classes.image}/>
                </div>
            </Grid>
            <Grid item xs={12}>
                <DisplayText align='center' color='bluegrey'>Ubelens.com © 2020</DisplayText>
            </Grid>
        </Grid>
    )
}

export default PaymentContent