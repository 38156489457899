import React, {useEffect, useState} from 'react'
import { Grid } from '@material-ui/core'
import Page from '../../../components/Structure/Page/Page'
import UserInfoCard from './components/UserInfoCard'
import SimpleCard from '../../../components/Structure/Cards/SimpleCard'
import DisplayText from '../../../components/Texts/DisplayText'
import { catalogs } from '../../../texts/esp/catalogs'
import InputForm from '../../../components/Forms/InputForm'
import SelectForm from '../../../components/Forms/SelectForm'
import { onGetFormData, onSetErrorsToForm, isFormValid, onInitForm, onGetSelectedFormData } from '../../../shared/utility'
import ContainedButton from '../../../components/Actions/ContainedButton'
import { request_client, request_update_client } from './requests'

const ClientView = props => {

    const {location, history, match} = props

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [user, setUser] = useState(null)
    const [error, setError] = useState(null)


    useEffect(() => {
        const initModule = async() => {try {
            setLoading(true)
            const {id} = match.params
            const _user = await request_client(id)
            setUser(_user)
            const _form = onInitForm(form, _user)
            console.log(_form)
            setForm(_form)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }}
        initModule()
    }, [])

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }
    
    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetSelectedFormData(form, user)
        setSending(true)
        try {
            const _user = await request_update_client(user._id, data2send);
            setUser(_user)
            setSending(false)
        } catch (error) {
            console.log(error)
            setError('Hubo un error')
            setSending(false)
        }

    }


    return(
        <Page title='Información de usuario' loading={loading} goback>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4} xl={3}>
                    <UserInfoCard {...user}/>
                </Grid>
                <Grid item xs={12} md={8} xl={9}>
                    <SimpleCard>
                        <Grid container spacing={4} justify='flex-end'>
                            <Grid item xs={12}>
                                <DisplayText variant='subtitle1'>Editar perfil</DisplayText>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} >
                                        <InputForm data={form.first_name} onChange={onChange} />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <InputForm data={form.last_name} onChange={onChange} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputForm data={form.email} onChange={onChange} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputForm data={form.phone_number} onChange={onChange} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SelectForm data={form.gender_id} onChange={onChange} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SelectForm data={form.status_id} onChange={onChange} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <ContainedButton color='primary' loading={sending} onClick={onSubmit}>Guardar</ContainedButton>
                            </Grid>
                        </Grid>
                    </SimpleCard>
                </Grid>
            </Grid>
        </Page>
    )
}

export default ClientView

const formData = {
    first_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'first_name',
            type: 'text',
            fullWidth: true,
            label: 'Nombre(s)',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    last_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'last_name',
            type: 'text',
            fullWidth: true,
            label: 'Apellido(s)',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    email: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'email',
            fullWidth: true,
            label: 'Dirección de correo',
            helperText: 'Dirección de correo no válido',
            disabled:true,
        },
        rules: {
            type: 'email',
        }
    },
    phone_number: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'phone_number',
            type: 'number',
            fullWidth: true,
            label: 'Teléfono',
            helperText: 'Número de teléfono no válido',
        },
        rules: {
            type: 'phone',
        }
    },
    gender_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...catalogs.genders],
        config: {
            id: 'gender_id',
            type: 'select',
            fullWidth: true,
            label: 'Género',
            helperText: 'Debe elegir una opción',
        },

    },
    status_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...catalogs.user_statuses],
        config: {
            id: 'status_id',
            type: 'select',
            fullWidth: true,
            label: 'Estatus',
            helperText: 'Debe elegir una opción',
        },

    },
}