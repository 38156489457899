import React from 'react'
import { Grid, Icon, IconButton, LinearProgress, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Paper, withStyles } from '@material-ui/core'
import DisplayText from '../../../../../components/Texts/DisplayText'
import { onGetCompleteAddress } from '../../../../../shared/utility'
import { blueGrey, grey } from '@material-ui/core/colors'
import empty from '../../../../../assets/empty.png'
import moment from 'moment-timezone'
import { nodata } from '../../../../../variables/texts'
import OrderStatus from '../../../../../components/Structure/DisplayData/OrderStatus'
import FilterDate from '../../../../../components/Actions/Buttons/FilterDate'
import FilterButton from '../../../../../components/Actions/Buttons/FilterButton'
import {catalogs} from '../../../../../texts/esp/catalogs'
import ActionBar from '../../../../../components/Actions/ActionBar'
import SearchBar from '../../../../../components/Actions/SearchBar'

const header = [
    {id:1, label:'Nombre'},
    {id:2, label:'Teléfono'},
    {id:3, label:'Celular'},
    {id:4, label:'Dirección'},
]


const useStyles = makeStyles(theme => ({
    root:{},
    paper:{
        borderRadius:16,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    cell:{
        borderColor:grey[200],
    },
    row:{
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    cellHeader:{
        borderColor:grey[300],
        paddingTop:16,
        paddingBottom:16,
    },
    button:{
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    toolbar:{
        marginBottom:16
    },
    actions:{
        marginTop:16
    },
    table:{
        minWidth:800
    },
    tableWrapper:{
        overflowX: 'auto',
    },
    nodata:{
        marginTop:40
    }
}))

const SimpleTable = props => {

    const classes = useStyles()
    const {data, onSelectedItem, loading, onAddRegister, 
            //filter, onChangeFilter, total, tableFilter, onUpdateTableFilter, 
            //startDate, onChangeDate, endDate, status
            } = props

    return(
        <div>
            <div className={classes.toolbar}>
                <Grid container  spacing={2} alignItems='flex-end' justify='flex-end' >
                    <Grid item><IconButton className={classes.button} onClick={onAddRegister} ><Icon>add</Icon></IconButton></Grid>
                </Grid>
            </div>
            {loading ? (
                <div style={{marginBottom:16}}>
                <LinearProgress color='primary'/>
                </div>
            ) : null}
            <Paper className={classes.paper}>
                {data && data.length ? (
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table}>
                            {header ? (
                                <TableHead>
                                    <TableRow>
                                        {header.map(item=>{
                                            return(
                                                <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                    <DisplayText variant='subtitle2' >{item.label}</DisplayText>
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                            ):null}
                            <TableBody>
                                {data.map((item,key) => {
                                    const {branch_name, phone, cellphone, address, _id} = item

                                    return(
                                        <TableRow key={key.toString()} className={classes.row} onClick={() => onSelectedItem(item)}>
                                            <TableCell className={classes.cell}><DisplayText >{branch_name}</DisplayText></TableCell>
                                            <TableCell className={classes.cell}><DisplayText>{phone ? phone : nodata}</DisplayText></TableCell>
                                            <TableCell className={classes.cell}><DisplayText>{cellphone ? cellphone : nodata}</DisplayText></TableCell>
                                            <TableCell className={classes.cell}><DisplayText>{onGetCompleteAddress(address)}</DisplayText></TableCell>
                                        </TableRow>
                                    )
                                })}  
                            </TableBody>
                        </Table>
                    </div>
                    
                ): null}
            </Paper>
            {/* {data && data.length ? <div className={classes.actions}>
                <ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div> : null} */}
            {data.length === 0 && !loading ? (
                <div className={classes.nodata}>
                    <Grid container justify='center' alignItems='center' direction='column'>
                        <Grid item>
                            <img src={empty} alt='' width={180}/>
                        </Grid>
                        <Grid item>
                            <DisplayText>No existen órdenes registradas</DisplayText>
                        </Grid>
                    </Grid>
                </div>
                
            ) : null}
            
        </div>
    )
}

export default SimpleTable