import React from 'react'
import { Grid, Icon, InputBase, makeStyles } from '@material-ui/core'
import { blueGrey } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
    root:{
        background:'white',
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
        padding:'8px 16px',
        borderRadius:32,
        //minWidth:255
    },
    icon:{
        color:blueGrey[500],

    }
}))

const SearchBar = props => {
    const classes = useStyles()
    const {value, onChange} = props

    const onInnerChange = (e) => {
        onChange(e.target.value)
    }

    return(
        <div className={classes.root}>
            <Grid container alignItems='center' spacing={1} wrap='nowrap'>
                <Grid item>
                    <Icon className={classes.icon} fontSize='default'>search</Icon>
                </Grid>
                <Grid item>
                    <InputBase value={value} placeholder='Buscar...' onChange={onInnerChange} />
                </Grid>
            </Grid>
        </div>
    )
}

export default SearchBar