import { makeStyles } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import React from 'react'
import { catalogs } from '../../../texts/esp/catalogs'
import { nodata } from '../../../variables/texts'

const useStyles = makeStyles(theme => ({
    root:{
        fontFamily:theme.typography.fontFamily,
        fontSize:14,
        color:'white',
        display:'inline-block',
        padding:'8px 16px',
        borderRadius:32
    },
}))


const PaymentStatus = ({status_id}) => {

    const classes = useStyles()
    const status = catalogs.payment_statuses.find(el=>el.value === status_id)

    return(
        <div className={classes.root} style={{background: status ? status.color : grey[400]}}>
            {status ? status.label : 'No realizado'}
        </div>
    )
}

export default PaymentStatus