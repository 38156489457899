import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import clsx from  'clsx'
import { blueGrey } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
    menuItem:{
        textAlign:'center',
        fontFamily:'Roboto',
        fontSize:18,
        color:blueGrey[500],
        fontWeight:400,
        padding:theme.spacing(2),
        fontFamily:theme.typography.fontFamily,
        //textTransform:'uppercase',
        '&:hover':{
            cursor:'pointer'
        }
    },
    selected:{
        color:theme.palette.primary.main,
        fontWeight:500,
        borderBottom:`3px solid ${theme.palette.primary.main}`
    }
}))

const ProductMenu = props => {

    const {options, selected, onChange} = props
    const classes = useStyles()

    return(
        <div>
            <Grid container>
                {options.map((item,key)=>{
                    return(
                        <Grid item key={key.toString()}>
                            <div className={clsx({
                                [classes.menuItem]:true,
                                [classes.selected]:item.id === selected
                            })} onClick={() => onChange(item.id)}>{item.label}</div>
                        </Grid>
                    )
                })}
            </Grid>
        </div>
    )
}

export default ProductMenu