import { Avatar, makeStyles } from '@material-ui/core'
import { indigo } from '@material-ui/core/colors'
import React from 'react'
import { isValidDocument } from '../../../../shared/utility'
import { config_data, USER_TYPES } from '../../../../variables/config'

const useStyles = makeStyles(theme => ({
    avatar:{
        width:54,
        height:54,
        //border:'1p sli',
        background:indigo[500],
        fontWeight:500
    },
}))

const UserAvatar = ({first_name, last_name, user_type_id, business_name, image}) => {

    const classes = useStyles()

    let acronym =  `${first_name ? first_name[0].toUpperCase() : ''}${last_name ? last_name[0].toUpperCase() : ''}`
    if(user_type_id === USER_TYPES.OPTICAL) acronym = business_name.substring(0,2).toUpperCase()
    
    let avatar_content = (
        <Avatar className={classes.avatar}>
            {acronym}
        </Avatar>
    )

    const valid_image = isValidDocument(image)
    if(valid_image){
        avatar_content = <Avatar src={`${config_data.S3_SERVER_URL}${image}`} className={classes.avatar}/>
    }

    return(
        <div>
            {avatar_content}
        </div>
    )
}

export default UserAvatar