import React, { useState } from 'react';
import PictureModal from '../../../../components/Overlays/PictureModal';
import { onGetErrorMessage, onGetFileExtension, onGetImageURL2Blob } from '../../../../shared/utility';
import { request_upload_image, request_delete_image } from '../requests';

const ChangeImageModal = (props) => {

    const {id, open, onClose, onUpdateCompleted} = props
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const onSubmit = async(file) => {
        if(!file){
            setError('Debe adjuntar/seleccionar una imagen para continuar')
            return
        }
        setError(null)
        try {
            setLoading(true)
            console.log(file)
            const _file = await onGetImageURL2Blob(file)
            await request_upload_image(id, _file, {type:onGetFileExtension(_file.name)})
            onUpdateCompleted()
        } catch (error) {
            console.log(error.response)
            setError(onGetErrorMessage(error))
        }
        setLoading(false)
    }

    const onDelete = async() => {try {
        setError(null)
        setLoading(true)
        await request_delete_image(id)
        onUpdateCompleted()
    } catch (error) {
        console.log(error.response)
        setError(onGetErrorMessage(error))
    }
    setLoading(false)
        //const {id_document} = selected_document
    }

    return ( 
        <PictureModal open={open} onClose={onClose} loading={loading} error={error}
            title='Cambiar imagen' btnLabel='Guardar' onChangeFile={onSubmit} onDeleteFile={onDelete}/>
     );
}
 
export default ChangeImageModal;