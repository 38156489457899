import React, {Component} from 'react'
import { withStyles, Grid, IconButton, Icon } from '@material-ui/core'
import Dropzone from 'react-dropzone'
import FileView from './FileView'
import { grey, teal } from '@material-ui/core/colors'

import cx from 'classnames'
import DisplayText from '../Texts/DisplayText'


class SimpleFile extends Component {


    state = {
        onEnter:false,
    }

    render(){

        const {classes, file, onDocumentAttached, label} = this.props
        const {onEnter} = this.state
        
        return(
            <div>
                <Grid container spacing={1}>
                    {label ? (
                        <Grid item xs={12}>
                            <DisplayText variant='subtitle1'>{label}</DisplayText>
                        </Grid>
                    ) : null}
                    <Grid item xs={12}>
                        <Dropzone 
                            onDragLeave={this.onDragLeave}
                            onDragEnter={this.onDragEnter}
                            onDrop={this.onDrop}>
                            {({getRootProps, getInputProps}) => (
                                <div {...getRootProps()} className={cx({
                                    [classes.container]: true,
                                    [classes.containerIn]:onEnter
                                })}>
                                    <input {...getInputProps()} />
                                    <FileView file={file} onDocumentAttached={onDocumentAttached}/>
                                </div>
                            )}
                        </Dropzone>
                    </Grid>
                    {file ? <Grid item xs={12}>
                        <Grid container wrap='nowrap' alignItems='center'>
                            <Grid item xs>
                                <DisplayText variant='body2'>
                                    Si desea modificar el archivo de click o arrastre uno nuevo en el recuadro
                                </DisplayText>
                            </Grid>
                            <Grid item><IconButton size='small' onClick={this.onDocumentRemove}><Icon fontSize='small'>delete</Icon></IconButton></Grid>
                        </Grid>
                    </Grid> : null}
                </Grid>
                
            </div>
        )
    }

    onDrop = (acceptedFiles) => {
        const {id} = this.props
        this.props.onDocumentAttached(id, acceptedFiles)
        this.setState({files:[...acceptedFiles], onEnter:false})
    }

    onDocumentRemove = () => {
        const {onDocumentRemove, id} = this.props
        onDocumentRemove(id)
    }

    onDragEnter = () => {
        this.setState({onEnter:true})
    }

    onDragLeave = () => {
        this.setState({onEnter:false})
    }

}

const styles = theme => ({
    root:{},
    container:{
        background:grey[100],
        borderRadius:12,
        padding:32,
        transition: theme.transitions.create("background", {
            easing: theme.transitions.easing.sharp, 
            duration: 500,
        })
    },
    containerIn:{
        background:teal[100],
        cursor:'pointer'
    },
    button:{
        textTransform:'none',
        borderRadius:8
    },
    input:{
        display:'none',
        background:'grey'

    }
})

export default withStyles(styles)(SimpleFile)

