import React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import Clients from '../views/Clients/Clients/Clients'
import ClientView from '../views/Clients/ClientView/ClientView'
import Home from '../views/Home/Home'
import Orders from '../views/Orders/Orders/Orders'
import OrderView from '../views/Orders/OrdersView/OrderView'
import Products from '../views/Products/Products/Products'
import ProductView from '../views/Products/ProductView/ProductView'
import Users from '../views/Users/Users/Users'
import UserView from '../views/Users/UserView/UserView'
import Opticals from '../views/Opticals/Opticals/Opticals'
import OpticalsView from '../views/Opticals/OpticalsView/OpticalsView'
import Optometrists from '../views/Optometrists/Optometrists/Optometrists'
import OptometristView from '../views/Optometrists/OptometristView/OptometristView'
import Patients from '../views/Patients/Patients/Patients'
import PatientView from '../views/Patients/PatientView/PatientView'
import ProfileView from '../views/ProfileView/ProfileView'
import PublicProducts from '../views/PublicProductsView/Products/PublicProducts'
import PublicProductView from '../views/PublicProductsView/ProductView/PublicProductView'

const PageRouter = props => {
    return(
        <Switch>
            <Route component={UserView} path='/users/:id' exact/>
            <Route component={Users} path='/users' />
            <Route component={PatientView} path='/patients/:id' />
            <Route component={Patients} path='/patients' />
            <Route component={OptometristView} path='/optometrists/:id' />
            <Route component={Optometrists} path='/optometrists' />
            <Route component={ProductView} path='/products/:id' exact/>
            <Route component={Products} path='/products' />
            <Route component={OrderView} path='/orders/:id' exact/>
            <Route component={Orders} path='/orders' />
            <Route component={OpticalsView} path='/opticals/:id' exact />
            <Route component={Opticals} path='/opticals' />
            <Route component={ProfileView} path='/profile' />
            <Route component={PublicProductView} path='/public-products/:id' exact/>
            <Route component={PublicProducts} path='/public-products' />
            <Redirect from='/' to='/orders'/>
        </Switch>
    )
}

export default PageRouter