import React, { useState } from 'react';
import PictureModal from '../../../components/Overlays/PictureModal';
import { onGetErrorMessage, onGetFileExtension, onGetImageURL2Blob } from '../../../shared/utility';
import { request_delete_image_admin, request_delete_image_optical, request_delete_image_optometrist, request_upload_image_admin, request_upload_image_optical, request_upload_image_optometrist } from '../requests';
import { USER_TYPES } from "../../../variables/config";

const ChangeImageModal = (props) => {

    const {id, open, onClose, onUpdateCompleted, type} = props
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    //console.log(type)

    const onSubmit = async(file) => {
        if(!file){
            setError('Debe adjuntar/seleccionar una imagen para continuar')
            return
        }
        setError(null)
        try {
            setLoading(true)
            console.log(file)
            const _file = await onGetImageURL2Blob(file)
            if(type === USER_TYPES.ADMIN) await request_upload_image_admin(id, _file, {type:onGetFileExtension(_file.name)})
            if(type === USER_TYPES.OPTOMETRIST) await request_upload_image_optometrist(id, _file, {type:onGetFileExtension(_file.name)})
            if(type === USER_TYPES.OPTICAL) await request_upload_image_optical(id, _file, {type:onGetFileExtension(_file.name)})
            onUpdateCompleted()
        } catch (error) {
            console.log(error.response)
            setError(onGetErrorMessage(error))
        }
        setLoading(false)
    }

    const onDelete = async() => {try {
        setError(null)
        setLoading(true)
        if(type === USER_TYPES.ADMIN) await request_delete_image_admin(id)
        if(type === USER_TYPES.OPTOMETRIST) await request_delete_image_optometrist(id)
        if(type === USER_TYPES.OPTICAL) await request_delete_image_optical(id)
        onUpdateCompleted()
    } catch (error) {
        console.log(error.response)
        setError(onGetErrorMessage(error))
    }
    setLoading(false)
        //const {id_document} = selected_document
    }

    return ( 
        <PictureModal open={open} onClose={onClose} loading={loading} error={error}
            title='Cambiar imagen' btnLabel='Guardar' onChangeFile={onSubmit} onDeleteFile={onDelete}/>
     );
}
 
export default ChangeImageModal;