import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { useStyles } from './styles'
import SimpleModal from '../../../../../components/Overlays/SimpleModal'
import DisplayText from '../../../../../components/Texts/DisplayText'
import InputForm from '../../../../../components/Forms/InputForm'
import ContainedButton from '../../../../../components/Actions/ContainedButton'
import {catalogs} from '../../../../../texts/esp/catalogs'
import { isFormValid, onGetFormData, onGetSelectedFormData, onInitForm, onSetErrorsToForm } from '../../../../../shared/utility'
import {request_office, request_update_office, request_update_office_address} from '../../requests'
import SelectForm from '../../../../../components/Forms/SelectForm'
/* import OfficeAddress from './OfficeAddress'
import OfficeGeneral from './OfficeGeneral' */

const EditOfficeModal = props => {

    const { open, onClose, id, onUpdateCompleted } = props

    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [error, setError] = useState(' ')
    const [office, setOffice] = useState (null)
    const [officeAddress, setOfficeAddress] = useState (null)

    useEffect(() => {
        const initModule = async() => {try {
            if (!open) {
                setForm(JSON.parse(JSON.stringify(formData)))
                setError(''); setLoading(false);
                return
            }
            setLoading(true)
            console.log(id)
            if(id !== null){
                const _office = await request_office(id)
                //console.log(_office)
                setOffice(_office)
                let _address = _office.address
                setOfficeAddress(_address)
                const _officeForm = {..._office, ..._address}
                //console.log(_officeForm)
                const _form = onInitForm(form, _officeForm)
                _form.phone.value = _office.phone
                _form.phone.isValid = true
                _form.phone.isVisited = true
                if(_address && _address.province){
                    const provice_catalog = catalogs.cities[_address.province]
                    if(provice_catalog) _form.city.options = [...provice_catalog]
                }
                setForm(_form)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }}
        initModule()
    }, [id, open])

    /* const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    } */

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        if(id === 'province'){
            //console.log('Actualizar ciudades/barrios')
            temp.city.value = null
            temp.city.options = catalogs.cities[data.value]
        }
        setForm(temp)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetSelectedFormData(form, office)
        delete data2send.address1
        delete data2send.address2
        delete data2send.city
        delete data2send.province
        delete data2send.country
        //if(form.cellphone.value === '' || form.cellphone.value === ' ')  data2send.cellphone = '@null!!!'
        console.log(data2send)
        if(Object.keys(data2send).length !== 0) console.log('se edito info general')

        //Address data
        let data2send2 = onGetSelectedFormData(form, officeAddress)
        delete data2send2.branch_name
        delete data2send2.cellphone
        delete data2send2.phone
        console.log(data2send2)
        if(Object.keys(data2send2).length !== 0) console.log('se edito la direccion')
        //delete data2send.passwordConfirmation

        setLoading(true)
        try {
            if(Object.keys(data2send).length !== 0) await request_update_office(id, data2send);
            if(Object.keys(data2send2).length !== 0) await request_update_office_address(id, data2send2);
            onUpdateCompleted()
        } catch (error) {
            console.log(error)
            setError('Hubo un error') 
        }
        setLoading(false)

    }

    


    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div >
                <DisplayText variant='h5' color='primary' style={{ fontWeight: 500 }}>Editar sede</DisplayText>
                <DisplayText >Por favor ingresa los datos solicitados</DisplayText>
                <div className={classes.form}>
                    <Grid container spacing={2} >
                        {/* <Grid item xs={12}>
                            <OfficeGeneral id={id} onUpdateCompleted={onUpdateCompleted}/>
                        </Grid>
                        <Grid item xs={12}>
                            <OfficeAddress id={id} onUpdateCompleted={onUpdateCompleted}/>
                        </Grid> */}
                        <Grid item xs={12}>
                            <DisplayText variant='subtitle1'>Información General</DisplayText>
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.branch_name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={6} >
                            <InputForm data={form.phone} onChange={onChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <InputForm data={form.cellphone} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <DisplayText variant='subtitle1'>Dirección fiscal</DisplayText>
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.address1} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.address2} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <SelectForm data={form.province} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SelectForm data={form.city} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SelectForm data={form.country} onChange={onChange}/>
                        </Grid>
                    </Grid>
                </div>
                <ContainedButton color='primary' loading={loading} fullWidth onClick={onSubmit}>Guardar</ContainedButton>
                <DisplayText color='error' align='center'>{error}</DisplayText>
            </div>
        </SimpleModal>
    )
}

export default EditOfficeModal


const formData = {
    branch_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'branch_name',
            type: 'text',
            fullWidth: true,
            label: 'Nombre',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    phone: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'phone',
            type: 'number',
            fullWidth: true,
            label: 'Telefono',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 10, max: 256
        }
    },
    cellphone: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'cellphone',
            type: 'number',
            fullWidth: true,
            label: 'Celular',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 10, max: 256
        }
    },
    address1:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
          id:'address1',
          type:'text',
          fullWidth: true,
          label:'Dirección (Línea 1)',
          helperText:'El numero de caracteres debe ser entre 1 y 512'
        },
        rules:{
          type:'distance',
          max:512
        }
    },
    address2:{ // Departamento, barrio
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
          id:'address2',
          type:'text',
          fullWidth: true,
          label:'Dirección (Línea 2)',
          helperText:'El numero de caracteres debe ser entre 1 y 512'
        },
        rules:{
          type:'distance',
          max:512
        }
    },
    city:{ // Departamento, barrio
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config:{
          id:'city',
          type:'select',
          fullWidth: true,
          label:'Ciudad',
          helperText:''
        },
        rules:{
          type:'select',
        }
    },
    province:{ // Departamento, barrio
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...catalogs.departments],
        config:{
          id:'province',
          type:'select',
          fullWidth: true,
          label:'Departamento',
          helperText:'Este campo es obligatorio'
        },
        rules:{
          type:'select',
        }
    },
    country:{ // Departamento, barrio
        value: 'Colombia',
        error: false,
        isVisited: true,
        isRequired: true,
        isValid: true,
        options:[{id:1, value:'Colombia', label:'Colombia'}],
        config:{
          id:'country',
          type:'select',
          fullWidth: true,
          label:'País',
          helperText:'Este campo es obligatorio'
        },
        rules:{
          type:'select',
        }
    }, 
    
}

