import React from 'react'
import { Dialog, Grow, Icon, IconButton, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} timeout={500} />;
});

const useStyles = makeStyles(theme => ({
    root:{
        position:'relative'
    },
    paper:{
        borderRadius:32, 
        padding:theme.spacing(7),
        boxSizing:'border-box',
        [theme.breakpoints.down('sm')]:{
            borderRadius:0, 
            padding:theme.spacing(3),
            paddingTop:theme.spacing(7)
        }
    },
    buttonContainer:{
        position:'absolute',
        top:16,
        right:16
    },
    container:{
        //padding:theme.spacing(4),
        boxSizing:'border-box'
    },
}))



const SimpleModal = ({open, maxWidth, children, onClose}) => {

    const classes = useStyles()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    return(
        <Dialog open={open} maxWidth={maxWidth ? maxWidth :'xs'} scroll='body' fullScreen={isMobile}
        fullWidth classes={{ paper: classes.paper }} className={classes.root}
            TransitionComponent={Transition}>
            <div className={classes.buttonContainer}><IconButton onClick={onClose}><Icon >close</Icon></IconButton></div>
            <div className={classes.container}>
                {children}
            </div>
            
        </Dialog>
    )
}

export default SimpleModal