import React from 'react'
import { Button, Grid } from '@material-ui/core'
import ContainedButton from '../../../../components/Actions/ContainedButton'
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard'
import DisplayText from '../../../../components/Texts/DisplayText'
import { onGetFullname } from '../../../../shared/utility'

const AddressContainer = ({id, title,  onEdit, address, onAutoFill}) => {

    

    return(
        <SimpleCard>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs>
                            <DisplayText variant='subtitle1'>{title ? title : ''}</DisplayText>
                        </Grid>
                        <Grid item><Button color='secondary' size='small' onClick={() => onAutoFill(id)}>Auto</Button></Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {address ? 
                        <div>
                            <DisplayText variant='body2'>{address ? address.address1 : null}</DisplayText>
                            <DisplayText variant='body2'>{address ? address.address2 : null}</DisplayText>
                            <DisplayText variant='body2'>{address ? address.province : null}</DisplayText>
                            <DisplayText variant='body2'>{address ? address.city : null}</DisplayText>
                            <DisplayText variant='body2'>{address ? address.country : null}</DisplayText>
                        </div>
                    
                    : (
                        <div>
                            <DisplayText variant='body2'>No hay una dirección registrada</DisplayText>
                        </div>
                    )}
                    
                </Grid>
                {onEdit ? (
                    <Grid item xs={12}>
                        <Grid container justify='flex-end'>
                            <Grid item>
                                <ContainedButton color='primary' onClick={onEdit}>Editar</ContainedButton>
                            </Grid>
                        </Grid>
                    </Grid>
                )  : null}
            </Grid>
        </SimpleCard>
    )
}

export default AddressContainer