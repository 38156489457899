import { Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import ShortcutButton from '../../../../components/Actions/ShortcutButton';
import { ORDER_STATUS_TYPES } from '../../../../variables/config';
import ProductModal from '../modals/ProductModal';
import { request_order } from '../requests';
import AstigmatismButton from './buttons/AstigmatismButton';
import CosmeticsButton from './buttons/CosmeticsButton';
import MultifocalButton from './buttons/MultifocalButton';
import MyopiaButton from './buttons/MyopiaButton';
import DropsAndSolutionsButton from './buttons/DropsAndSolutionsButton'
import DropsModal from '../modals/DropsModal'

const ToolbarContainer = ({orderID, status_id, onDownloadInvoice, onDownloadDelivery, onUpdateData}) => {

    const [formula, setFormula] = useState()
    const [type, setType] = useState()
    const [product_list, setProductList] = useState([])
    const [product_modal, setProductModal] = useState(false)
    const [drops_modal, setDropsModal] = useState(false)

    const onInjectProductSearch = (formula, products, type) => {
        setFormula(formula)
        setProductList(products)
        setType(type)
        //const data2send = {formula:formula, products:products, type:type}
        //localStorage.setItem('state', JSON.stringify(data2send))
        setProductModal(true)
    }

    const onInjectDropsSearch = (formula, products, type) => {
        console.log('Abrir otros productos')
        setDropsModal(true)
        setProductList(products)
    }

    useEffect(() => {
        let state = localStorage.getItem('state')
        if(state){
            state = JSON.parse(state)
            //console.log(state)
            setFormula(state.formula)
            setProductList(state.products)
            setType(state.type)
        }
    }, [])

    const onInnerUpdateCompleted = async() => {
        try {
            const _order = await request_order(orderID)
            onUpdateData(_order)
        } catch (error) {
            
        }
        setProductModal(false)
        setDropsModal(false)
    }


    let receipt_button = <Grid item><ShortcutButton icon='receipt' onClick={onDownloadInvoice}>Factura</ShortcutButton></Grid>
    let delivery_button = <Grid item><ShortcutButton icon='local_shipping' onClick={onDownloadDelivery}>Formato de entrega</ShortcutButton></Grid>
    if(status_id){
        if(status_id === ORDER_STATUS_TYPES.PENDING){
            receipt_button = null
            delivery_button = null
        }
    
    }


    return ( 
        <div>
            <ProductModal orderID={orderID} open={product_modal} onClose={() => setProductModal(false)} formula={formula}
            type={type} products={product_list} onUpdateCompleted={onInnerUpdateCompleted}/>
            <DropsModal orderID={orderID} open={drops_modal} onClose={() => setDropsModal(false)} formula={formula}
            type={type} products={product_list} onUpdateCompleted={onInnerUpdateCompleted}/>
            <Grid container spacing={1}>
                {status_id === ORDER_STATUS_TYPES.PENDING ? <Grid item><MyopiaButton onInjectProductSearch={onInjectProductSearch}/></Grid> : null}
                {status_id === ORDER_STATUS_TYPES.PENDING ? <Grid item><AstigmatismButton onInjectProductSearch={onInjectProductSearch}/></Grid> : null}
                {status_id === ORDER_STATUS_TYPES.PENDING ? <Grid item><MultifocalButton onInjectProductSearch={onInjectProductSearch}/></Grid> : null}
                {status_id === ORDER_STATUS_TYPES.PENDING ? <Grid item><CosmeticsButton onInjectProductSearch={onInjectProductSearch}/></Grid> : null}
                {status_id === ORDER_STATUS_TYPES.PENDING ? <Grid item><DropsAndSolutionsButton onInjectProductSearch={onInjectDropsSearch}/></Grid> : null}
                {receipt_button}
                {delivery_button}
            </Grid>  
        </div>  
    );
}
 
export default ToolbarContainer;