import { Grid, Icon, IconButton } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import ProductImage from '../../../../../components/Structure/Images/ProductImage';
import DisplayText from '../../../../../components/Texts/DisplayText';
import { onGetFormatNumber } from '../../../../../shared/utility';
import { EYE_CONFIG, ORDER_STATUS_TYPES } from '../../../../../variables/config';
import DeleteLineItemModal from '../../modals/DeleteLineItemModal';
import QuantityButton from './QuantityButton';

const ProductCheckoutList = (props) => {

    const {image, quantity, title, details, price, _id, product_id, onUpdateLineItem, total_price, onDeleteItem, status_id, history} = props

    const onChangeLineItem = (data) => {
        console.log(data)
        onUpdateLineItem(_id, {quantity:data})

    }


    const singlePrice = onGetFormatNumber(total_price)

    let details_content = null
    if(details){
        try {
            const _details = JSON.parse(details)
            const {sphere, addition, cylinder, dominance, color, axis, type} =_details
            details_content = (
                <Grid container>
                    <Grid itme xs={12}>
                        <DetailsInfo data={EYE_CONFIG[type]}/>
                    </Grid>
                    <Grid itme xs={12}>
                        <DetailsInfo label='ESF' data={sphere}/>
                    </Grid>
                    <Grid itme xs={12}>
                        <DetailsInfo label='CYL' data={cylinder}/>
                    </Grid>
                    <Grid itme xs={12}>
                        <DetailsInfo label='AXIS' data={axis}/>
                    </Grid>
                    <Grid itme xs={12}>
                        <DetailsInfo label='ADD' data={addition}/>
                    </Grid>
                    <Grid itme xs={12}>
                        <DetailsInfo label='DOM' data={dominance}/>
                    </Grid>
                    <Grid itme xs={12}>
                        <DetailsInfo label='COLOR' data={color}/>
                    </Grid>
                </Grid>
            )
        } catch (error) {
            
        }
    }

    return ( 
        <div>
            <Grid container spacing={2} alignItems='center' wrap='nowrap'>
                <Grid item>
                    <a style={{cursor:'pointer', textDecoration:'none', color:'inherit'}} href={`/public-products/${product_id}`} target='_blank'>
                        <ProductImage src={image} type='CHECKOUT'  quantity={quantity}/>
                    </a>
                </Grid>
                <Grid item xs>
                    <a style={{cursor:'pointer', textDecoration:'none', color:'inherit'}} href={`/public-products/${product_id}`} target='_blank'>
                        <Grid container>
                            <Grid item xs={12}>
                                <DisplayText variant='subtitle1'>{title}</DisplayText>
                            </Grid>
                            <Grid item xs={12}>
                                {details_content}
                            </Grid>
                        </Grid>
                    </a>
                </Grid>
                {status_id === ORDER_STATUS_TYPES.PENDING ? (
                    <Grid item>
                        <QuantityButton value={quantity} onChange={onChangeLineItem} />
                    </Grid>
                ) : null}
                
                {status_id === ORDER_STATUS_TYPES.PENDING ? <Grid item>
                    <IconButton onClick={() => onDeleteItem(props)}><Icon>delete</Icon></IconButton>
                </Grid> : null}
                <Grid item>
                    <DisplayText>{singlePrice}</DisplayText> 
                </Grid>
            </Grid>
        </div>
     );
}
 
export default withRouter(ProductCheckoutList);

const DetailsInfo = ({label, data}) => {
    if(!data) return null
    return(
       <Grid container wrap='nowrap' spacing={1} alignItems='baseline'>
           {label ? <Grid item>
                <DisplayText color='textSecondary' variant='body2'>{label}</DisplayText>
           </Grid> : null}
           <Grid item>
           <    DisplayText variant={!label ? 'subtitle2' : 'body2'}
           color={!label ? 'textSecondary' : 'inherit'}>{data}</DisplayText>
           </Grid>
       </Grid> 
    )
}