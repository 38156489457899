import { Avatar, fade, Grid, Icon, makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import React, { useState } from 'react';
import { isValidDocument } from '../../../shared/utility';
import { config_data } from '../../../variables/config';
import DisplayText from '../../Texts/DisplayText';

const AvatarPicture = ({nopicture, imgSrc, onChangePicture, can_edit}) => {
    const classes = useStyles()
    const [hover, setHover] = useState(false)

    let selectedImage = nopicture

    if(imgSrc){
        const isValid = isValidDocument(imgSrc)
        selectedImage = isValid ? `${config_data.S3_SERVER_URL}${imgSrc}` : nopicture
    }


    return ( 
        <div onClick={can_edit ? onChangePicture : null} className={classes.avatarContainer} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} >
            {hover && can_edit ? (<div className={classes.overlay}>
                <div className={classes.picture}>
                    <Grid container direction='column' alignItems='center'>
                        <Grid item>
                            <Icon style={{color:grey[300]}} fontSize='large'>photo_camera</Icon>
                        </Grid>
                        <Grid item>
                            <DisplayText style={{color:grey[300]}}>Editar</DisplayText>
                        </Grid>
                    </Grid>
                </div>
            </div>) : null}
            <Avatar src={selectedImage} alt='' className={classes.image}/>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    image:{
        width:120,
        height:120,
    },
    container:{
        padding:32,
        borderRadius:16,
        border:`1px solid ${grey[300]}`
    },
    avatarContainer:{
        position:'relative',
        //border:`4px solid ${theme.palette.primary.main}`,
        borderRadius:'50%'
    },
    overlay:{
        position:'absolute',
        top:0,
        left:0,
        background: fade('#000',0.5),
        zIndex:2,
        width:'100%',
        height:'100%',
        borderRadius:'50%',
        '&:hover':{
            cursor:'pointer'
        }
    },
    picture:{
        position:'absolute',
        top:'50%',
        left:'50%',
        transform:'translate(-50%, -50%)'
    }
}))
 
export default AvatarPicture;