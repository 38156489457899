import React, { useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard';
import DisplayText from '../../../../components/Texts/DisplayText';
import { onGetFormatNumber } from '../../../../shared/utility';
import { grey } from '@material-ui/core/colors';
import { ORDER_STATUS_TYPES } from '../../../../variables/config';
import RoundedButton from '../../../../components/Actions/Buttons/RoundedButton';
import PaymentOption from './PaymentOption';
import paymentTypesAccepted from '../../../../assets/payment-cards.jpg'
import MercadoPagoModal from '../modals/MercadoPagoModal';
import {request_mercadopago_payment_link, request_mercadopago_preference, request_mercadopago_payment, request_order} from '../requests'
import CashPaymentModal from '../modals/CashPaymentModal';

const OverviewOrder = ({order, onDispatchError, onUpdateOrder, onDispatchSending}) => {

    const classes = useStyles()

    const [payment_option, setPaymentOption] = useState(1)
    const [mp_link, setMpLink] = useState()
    const [mp_modal, setMpModal] = useState(false)
    const [cash_modal, setCashModal] = useState(false)
    const [disabled, setDisabled] = useState(false)

    //console.log('Actualizar checkout')
    if(order){
        //console.log(order.total)
        //if(order.payment) console.log(order.payment.token_id)
    }


    const onCheckout = async() => {
        const {billing_address, shipping_address, line_items, user_id, _id} = order
        if(!user_id){
            onDispatchError('Por favor registre a un paciente')
            return
        }
        if(!billing_address){
            onDispatchError('Por favor ingrese una dirección de facturación')
            return
        }
        if(!shipping_address){
            onDispatchError('Por favor ingrese una dirección de envío')
            return
        }
        if(!line_items.length){
            onDispatchError('Por favor seleccione al menos un producto')
            return
        }

        onDispatchSending(true)
        setDisabled(true)
        if(payment_option === 1){ // Pago con tarjeta de credito
            const {payment} = order
            if(payment){
                const {token_id, payment_url, payment_id, gateway} = payment
                if(gateway === 'mercadopago'){
                    const _payment = await request_mercadopago_payment(_id) //Check if there is a valid payment, watch out! Pending or rejected payments has not beeen updated
                    if(_payment.results.length === 0){
                        const mercadopago_link =  await onCheckMercadoPagoPreference(order)
                        setMpLink(mercadopago_link)
                        setMpModal(true)
                    }else{
                        const last_item = _payment.results.length - 1
                        const payment_info = _payment.results[last_item]
                        if(payment_info.status === 'rejected' || payment_info.status === 'in_process'){
                            const mercadopago_link = await onCheckMercadoPagoPreference(order)
                            setMpLink(mercadopago_link)
                            setMpModal(true)
                        }
                    }
                }
                
            }else{
                console.log('Proceder a generar link de compra')
                const mercardoPagoLink = await request_mercadopago_payment_link(order._id)
                const _order = await request_order(order._id)
                onUpdateOrder(_order)
                setMpLink(mercardoPagoLink)
                setMpModal(true)
            }
            
        }
        if(payment_option === 2) setCashModal(true)
        onDispatchSending(false)
        setDisabled(false)


    }


    const onCheckMercadoPagoPreference = async(order) => {
        try {
            const {token_id, payment_url, payment_id, gateway} = order.payment
            const _preference = await request_mercadopago_preference(token_id) // If not payment, review token_id
            if(_preference.items.length){
                if(parseInt(_preference.items[0].unit_price) === parseInt(order.total)){ // If there is no change, one can reuse the token
                    return payment_url
                }else{ // There is a change, a new token muts be generated
                    const mercardoPagoLink = await request_mercadopago_payment_link(order._id) 
                    const _order = await request_order(order._id)
                    onUpdateOrder(_order)
                    return mercardoPagoLink
                }
            }
        } catch (error) {
            console.log('Hubo un error')
        }
    }


    let subtotal; let status_id; let total;
    let discount
    if(order){
        subtotal = order.subtotal; status_id = order.status_id; total = order.total
        if(order.discount) discount = parseFloat(order.discount * order.subtotal).toFixed(0)

    }


    let checkoutButton = null

    if(status_id === ORDER_STATUS_TYPES.PENDING){
        checkoutButton = (
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12}>
                        <DisplayText variant='subtitle1'>Forma de pago</DisplayText>
                    </Grid>
                    <Grid item xs={12}>
                        <PaymentOption label='Pago con Tarjeta de Crédito/Debito' image={{src:paymentTypesAccepted, width:200}}
                        selected={payment_option === 1} onChange={() => setPaymentOption(1)} />
                    </Grid>
                    <Grid item xs={12}>
                        <PaymentOption label='Pago en efectivo' 
                        selected={payment_option === 2} onChange={() => setPaymentOption(2)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <RoundedButton disabled={disabled} color='primary' fullWidth onClick={onCheckout}>Procesar pago</RoundedButton>
                    </Grid>
                </Grid>
            </Grid>
            
        )
    }


    return ( 
        <div>
            <CashPaymentModal open={cash_modal} onClose={() => setCashModal(false)} order={order} onUpdateCompleted={onUpdateOrder}/>
            <MercadoPagoModal open={mp_modal} onClose={() => setMpModal(false)} url={mp_link}/>
            <SimpleCard>
                <Grid container>
                    <Grid item xs={12}>
                        <DisplayText  variant='subtitle1' style={{marginBottom:16}}>Resumen</DisplayText>
                    </Grid>
                    <Grid item xs={12}>
                        <DataInfo label={'Subtotal'} data={onGetFormatNumber(subtotal)}/>
                    </Grid>
                    {discount ? <Grid item xs={12}>
                        <DataInfo label={'Descuento'} data={`-${onGetFormatNumber(discount)}`}/>
                    </Grid> : null}
                    <Grid item xs={12}>
                        <DataInfo label={'Envío'} data='Gratis'/>
                    </Grid>
                    <Grid item xs={12}>
                        <DataInfo label={'Total'} data={onGetFormatNumber(total)} bold/>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.divider}/>
                    </Grid>
                    {checkoutButton}
                </Grid>
            </SimpleCard>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{

    },
    divider:{
        margin:'24px 0px',
        borderTop:`1px solid ${grey[300]}`
    }
}))
 
export default OverviewOrder;

const DataInfo = ({label, data, bold}) => {
    return(
        <Grid container wrap='nowrap'>
            <Grid item xs>
                <DisplayText color='textSecondary' variant={bold ? 'subtitle1' : 'body1'}>{label}</DisplayText>
            </Grid>
            <Grid item >
                <DisplayText  variant={bold ? 'subtitle1' : 'body1'}>{data}</DisplayText>
            </Grid>
        </Grid>
    )
}