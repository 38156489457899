
import React from 'react'
import { TextField, Grid, makeStyles, withStyles, Typography } from '@material-ui/core'
import { grey, red } from '@material-ui/core/colors'
import Autocomplete from "@material-ui/lab/Autocomplete";
import DisplayText from '../Texts/DisplayText';
//import DisplayText from '../Texts/DisplayText';


const CssTextField = withStyles(theme => ({
  root: {
    paddingTop:6,
    '& input':{
      color:grey[900],
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: `16px`,
      },
    },
  },
}))(TextField);

const SelectAutocompleteForm = props => {

    ///////////////////////////////////
    const {data, onChange, onInputChange } = props //onSearch
    const {isValid, isVisited, value, options, disabled, rules} = data;
    const {id, label,placeholder, helperText} = data.config;

    ///////////////////  STATE //////////////////////

    //////////////  FUNCTIONS  /////////////////

    const onInnerChange = (e, item) => {
      let temp = {...data}
      if(item){
        temp.value = item.value
        temp.isValid = true
      }else{
        temp.value = null
        temp.isValid = rules ? false : true
      }
      temp.isVisited = true
      onChange(temp)
    }

    ///////////////////// INTERNAL VARIABLES  /////////////////////////////
    let selectedOptions = []
    let selectedValue = null
    if(data) {
      if(options) selectedOptions = options
      if(value !== null && value !== undefined ){
        const singleEl = options.find(el => el.value === value) 
        selectedValue = singleEl ? singleEl : selectedValue
      }
    }
    
    

    let inputlabel = null;
    if(label) inputlabel =  <DisplayText variant='subtitle2'  color={!isValid && isVisited ? 'error' : 'inherit'}>{label}</DisplayText>

    return(
      <div>
        <Autocomplete
        disabled={disabled}
        value={selectedValue}
        options={selectedOptions}
        getOptionLabel={option => option.label}
        renderInput={params => (<TextField {...params} variant='outlined' color='primary' label={label} error={!isValid && isVisited} placeholder={placeholder}
          helperText={(!isValid && isVisited) ? helperText : null}/>)}
        onChange={onInnerChange}
        onInputChange={onInputChange}
        noOptionsText={<DisplayText>Sin opciones </DisplayText>}
        renderOption={(option, state) => 
          <div>
            <DisplayText variant={state.selected ? 'subtitle1' : 'body1'}>{option.label}</DisplayText>
          </div>
        }/>
      </div>
      
    )
}

export default SelectAutocompleteForm