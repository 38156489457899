import React from 'react'
import { fade, Icon, makeStyles } from '@material-ui/core'
import { blue, blueGrey, indigo } from '@material-ui/core/colors'
import cx from 'classnames'
import DisplayText from '../../../Texts/DisplayText'

const useStyles = makeStyles(theme => ({
    container:{
        width:52,
        minHeight:52,
        position:'relative'
    },
    iconContainer:{
        position:'absolute',
        top:0,
        left:0,
        color:indigo[200],
        fontSize:28,
        padding:12,
        borderRadius:'50%',
        '&:hover':{
            cursor:'pointer',
            background:fade('#FFF', 0.2)
        },
        zIndex:2
    },
    iconSelected:{
        color:'white',
        background:blue[700],
        '&:hover':{
            cursor:'pointer',
            background:blue[700],
        }
    },
    titleContainer:{
        width:52,
        height:52,
        borderRadius:26,
        position:'relative',
    },
    titleContainerOpen:{
        height:180,
        background:'white',
        transition:theme.transitions.create(['height', 'white'], {
            duration:theme.transitions.duration.complex,
            easing:theme.transitions.easing.easeIn
        })
    },
    title:{
        position:'absolute',
        top:60,
        left:40,
        transformOrigin:'0 0',
        transform:'rotate(90deg)',
        fontSize:18,
        color:blueGrey[900]
    }
}))

const SidebarButton = (props) => {

    const classes = useStyles()
    const {name, icon, selected, url, onSelectedURL} = props

    return(
        <div>
            <div className={classes.container} onClick={() => onSelectedURL(url)}>
                <Icon color='inherit' fontSize='default' 
                    className={cx({
                        [classes.iconContainer]:true,
                        [classes.iconSelected]:selected
                    })}
                >{icon}</Icon>
                <div className={cx({
                    [classes.titleContainer]:true,
                    [classes.titleContainerOpen]:selected,

                })}>
                    {selected ? <DisplayText className={classes.title}>{name}</DisplayText> : null}
                </div>
            </div>
        </div>
    )
    
}

export default SidebarButton
