import React, {useEffect, useState} from 'react'
import { Grid } from '@material-ui/core'
import Page from '../../../components/Structure/Page/Page'
import UserInfoCard from './components/UserInfoCard'
import SimpleCard from '../../../components/Structure/Cards/SimpleCard'
import DisplayText from '../../../components/Texts/DisplayText'
import { catalogs } from '../../../texts/esp/catalogs'
import InputForm from '../../../components/Forms/InputForm'
import SelectForm from '../../../components/Forms/SelectForm'
import { onGetFormData, onSetErrorsToForm, isFormValid, onInitForm, onGetSelectedFormData, onUpdateDocumentList, onGetCatalogs } from '../../../shared/utility'
import ContainedButton from '../../../components/Actions/ContainedButton'
import { request_user, request_update_user, request_download_document, request_offices } from './requests'
import DocumentsCard from './components/DocumentsCard'
import UploadDocumentModal from './modals/UploadDocumentModal'
import DeleteDocumentModal from './modals/DeleteDocumentModal'
import ChangeImageModal from './modals/ChangeImageModal'
import UpdatePasswordModal from './modals/UpdatePasswordModal'
import TopbarButtons from '../../../components/Forms/TopbarButtons'
import Orders from './components/Orders'


const documents = [
    {id:'identification_card_document', name:'Cédula de ciudadania', status:false},
    {id:'professional_card_document', name:'Tarjeta profesional', status:false},
]

const OptometristView = props => {

    const {location, history, match} = props

    const [view, setView] = useState(1);
    const [branch_offices, setBranchOffices] = useState([])

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [document_list, setDocumentList] = useState([...documents])
    const [user, setUser] = useState(null)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(null)

    const [selected_file, setSelectedFile] = useState(null)
    const [upload_file_modal, setUploadFileModal] = useState(false)
    const [delete_file_modal, setDeleteFileModal] = useState(false)
    const [update_image_modal, setUpdateImageModal] = useState(false)
    const [password_modal, setPasswordModal] = useState(false)

    const userID = match.params.id

    useEffect(() => {
        const initModule = async() => {try {
            setLoading(true)
            const {id} = match.params
            const _user = await request_user(id)
            setUser(_user)
            const _form = onInitForm(form, _user)
            const _documents = onUpdateDocumentList(document_list, _user)
            const _branch_offices = await request_offices({optical_id:_user.optical_id})
            _form.branch_office_id.options = onGetCatalogs(_branch_offices.data, '_id', 'branch_name')
            setForm(_form)
            setDocumentList(_documents)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }}
        initModule()
    }, [])

    const onUpdateCompleted = async() => {
        try {
            const _user = await request_user(userID)
            const _form = onInitForm(form, _user)
            const _documents = onUpdateDocumentList(document_list, _user)
            setUser(_user)
            setForm(_form)
            setDocumentList(_documents)
            setUploadFileModal(false)
            setDeleteFileModal(false)
            setUpdateImageModal(false)
            setPasswordModal(false)

        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }
    
    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetSelectedFormData(form, user)
        setSending(true)
        try {
            const _user = await request_update_user(user._id, data2send);
            setUser(_user)
            setSending(false)
            setSuccess('Acción exitosa')
        } catch (error) {
            console.log(error)
            setError('Hubo un error')
            setSending(false)
        }

    }

    const onUploadDocument = (data) => {
        console.log(data)
        setSelectedFile(data)
        setUploadFileModal(true)
    }

    const onDownloadDocument = async(data) => {try {
        await request_download_document(userID, {document:data.id})
    } catch (error) {
        console.log(error)
        setError('Hubo un error')
    }}

    const onDeleteDocument = data => {
        console.log(data)
        setSelectedFile(data)
        setDeleteFileModal(true)
    }


    //orders
    const onSelectedItem = (item) => {
        const {_id} = item
        history.push(`/orders/${_id}`)
    }

    const handleView = (newValue) => {
        setView(newValue);
        //console.log(value)
    };

    let viewRender = null

    switch(view){
        case 1:{
            viewRender= (<SimpleCard>
                <Grid container spacing={4} justify='flex-end'>
                    <Grid item xs={12}>
                        <DisplayText variant='subtitle1'>Información general</DisplayText>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} >
                                <InputForm data={form.first_name} onChange={onChange} />
                            </Grid>
                            <Grid item xs={12} >
                                <InputForm data={form.last_name} onChange={onChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <InputForm data={form.email} onChange={onChange} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputForm data={form.identification_card} onChange={onChange} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputForm data={form.professional_card} onChange={onChange} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputForm data={form.phone_number} onChange={onChange} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <SelectForm data={form.gender_id} onChange={onChange} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <SelectForm data={form.branch_office_id} onChange={onChange} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <SelectForm data={form.status_id} onChange={onChange} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <ContainedButton color='primary' loading={sending} onClick={onSubmit}>Guardar</ContainedButton>
                    </Grid>
                </Grid>
            </SimpleCard>)
            break;
        }
        case 2:{
            viewRender= <Orders location={location} onSelectedItem={onSelectedItem} id={userID} />
            break;
        }
    }

    const optionsTabs = [
        {label:'General', id:1},
        {label:'Pedidos', id:2},
    ]


    return(
        <Page title='Información del optómetra' loading={loading} goback success={success} onCloseSuccess={() => setSuccess(null)}>
            <>
                <UpdatePasswordModal userID={userID} open={password_modal} onClose={() => setPasswordModal(false)} onUpdateCompleted ={onUpdateCompleted}/>
                <ChangeImageModal id={userID} open={update_image_modal} onClose={() => setUpdateImageModal(false)} onUpdateCompleted ={onUpdateCompleted}/>
                <UploadDocumentModal id={userID} selected_file={selected_file} open={upload_file_modal} onClose={() => setUploadFileModal(false)} onUpdateCompleted={onUpdateCompleted}/>
                <DeleteDocumentModal id={userID} selected_file={selected_file} open={delete_file_modal} onClose={() => setDeleteFileModal(false)} onUpdateCompleted={onUpdateCompleted}/>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={4}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <UserInfoCard {...user} onChangePicture={() => setUpdateImageModal(true)} onChangePassword={() => setPasswordModal(true)} />
                            </Grid>
                            <Grid item xs={12}>
                                <DocumentsCard documents={document_list} onUploadDocument={onUploadDocument} onDownloadDocument={onDownloadDocument} onDeleteDocument={onDeleteDocument}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={8}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TopbarButtons optionsTabs={optionsTabs} view={view} handleView={handleView} />
                            </Grid>
                            <Grid item xs={12}>
                                {viewRender}
                            </Grid>
                        </Grid>
                        {/* <SimpleCard>
                            <Grid container spacing={4} justify='flex-end'>
                                <Grid item xs={12}>
                                    <DisplayText variant='subtitle1'>Información general</DisplayText>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={4}>
                                        <Grid item xs={12} >
                                            <InputForm data={form.first_name} onChange={onChange} />
                                        </Grid>
                                        <Grid item xs={12} >
                                            <InputForm data={form.last_name} onChange={onChange} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputForm data={form.email} onChange={onChange} />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <InputForm data={form.identification_card} onChange={onChange} />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <InputForm data={form.professional_card} onChange={onChange} />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <InputForm data={form.phone_number} onChange={onChange} />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <SelectForm data={form.gender_id} onChange={onChange} />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <SelectForm data={form.status_id} onChange={onChange} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <ContainedButton color='primary' loading={sending} onClick={onSubmit}>Guardar</ContainedButton>
                                </Grid>
                            </Grid>
                        </SimpleCard> */}
                    </Grid>
                </Grid>
            </>
        </Page>
    )
}

export default OptometristView

const formData = {
    first_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'first_name',
            type: 'text',
            fullWidth: true,
            label: 'Nombre(s)',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    last_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'last_name',
            type: 'text',
            fullWidth: true,
            label: 'Apellido(s)',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    identification_card: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'identification_card',
            type: 'text',
            fullWidth: true,
            label: 'Cédula',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    professional_card: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'professional_card',
            type: 'text',
            fullWidth: true,
            label: 'Tarjeta profesional',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    email: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'email',
            fullWidth: true,
            label: 'Dirección de correo',
            helperText: 'Dirección de correo no válido',
            disabled:true,
        },
        rules: {
            type: 'email',
        }
    },
    phone_number: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'phone_number',
            type: 'number',
            fullWidth: true,
            label: 'Teléfono',
            helperText: 'Número de teléfono no válido',
        },
        rules: {
            type: 'phone',
        }
    },
    gender_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[...catalogs.genders],
        config: {
            id: 'gender_id',
            type: 'select',
            fullWidth: true,
            label: 'Género',
            helperText: 'Debe elegir una opción',
        },

    },
    branch_office_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config: {
            id: 'branch_office_id',
            type: 'select',
            fullWidth: true,
            label: 'Sede',
            helperText: 'Debe elegir una opción',
        },

    },
    status_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...catalogs.user_statuses],
        config: {
            id: 'status_id',
            type: 'select',
            fullWidth: true,
            label: 'Estatus',
            helperText: 'Debe elegir una opción',
        },

    },
}