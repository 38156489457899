import acuvue from '../../assets/supplier/acuvue.jpg'
import airoptix from '../../assets/supplier/airoptix.jpg'
import alcon from '../../assets/supplier/alcon.jpg'
import avaira from '../../assets/supplier/avaira.jpg'
import bausch from '../../assets/supplier/bausch.jpg'
import Biomedics from '../../assets/supplier/Biomedics.jpg'
import coopervision from '../../assets/supplier/coopervision.jpg'
import dailies from '../../assets/supplier/dailies.jpg'
import frequency from '../../assets/supplier/frequency.jpg'
import freshlook from '../../assets/supplier/freshlook.jpg'
import johnson from '../../assets/supplier/johnson&johnson.jpg'
import proclear from '../../assets/supplier/proclear.jpg'
import purevision from '../../assets/supplier/purevision.jpg'

export const content = {
    brands:[
        {id:1, image:acuvue},
        {id:2, image:airoptix},
        {id:3, image:alcon},
        {id:4, image:avaira},
        {id:5, image:bausch},
        {id:6, image:Biomedics},
        {id:7, image:coopervision},
        {id:8, image:dailies},
        {id:9, image:frequency},
        {id:10, image:freshlook},
        {id:11, image:johnson},
        {id:12, image:proclear},
        {id:13, image:purevision},

    ]
}