import React, {useEffect} from 'react'
import { Redirect } from 'react-router-dom'
import Frame from '../../components/Structure/Frame/Frame'
import PageRouter from '../../routes/pageRouter'
import { isTokenValid } from '../../shared/utility'
import { connect } from 'react-redux'
import * as actionTypes from '../../store/actions'
import {request_me} from './requests'
import { colombiaDepartments } from '../../variables/departmentsCatalogs'
import { catalogs } from '../../texts/esp/catalogs'



const Dashboard = props => {

    const {history, onUpdateUser} = props

    useEffect(() => {
        const initModule = async() => {
            console.log('Revisar')
            const _user = await request_me()
            onUpdateUser(_user)
        }
        if(!isTokenValid()){
            history.push('/signin')
            return
        }
        initModule()
    }, [])


    const isAuth = isTokenValid()
    console.log(isAuth)
    const redirectContent = !isAuth ? <Redirect to='/signin'/> : null

    return(
        <Frame >
            {redirectContent}
            {isAuth ? <PageRouter /> : null}  
        </Frame>
    )
}

const mapDispatchToProps = dispatch => {
    return{
        onUpdateUser: (user) => dispatch({type: actionTypes.AUTH_UPDATE_USER, user })
    }
}


export default connect(null, mapDispatchToProps)(Dashboard)
