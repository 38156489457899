import { Grid } from '@material-ui/core'
import React from 'react'
import SelectForm from '../../../../components/Forms/SelectForm'
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard'
import DisplayText from '../../../../components/Texts/DisplayText'
import { onGetFormatNumber } from '../../../../shared/utility'
import { nodata } from '../../../../variables/texts'
import {catalogs} from '../../../../texts/esp/catalogs'
import { ORDER_STATUS_TYPES } from '../../../../variables/config'

const PaymentContainer = ({status, total, payment}) => {

    if(status === ORDER_STATUS_TYPES.PENDING) return null

    const _total = onGetFormatNumber(total)
    let payment_type = nodata
    let creditcard = false
    if(payment){
        payment_type = catalogs.payment_types.find(el => el.value === payment.type_id)
        if(payment_type) payment_type = payment_type.label
        if(payment.type_id === 1) creditcard = true

    }

    return(
        <SimpleCard>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <DisplayText variant='subtitle1'>Detalle de pago</DisplayText>
                </Grid>
                
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <DataWrapper label='Forma de pago' data={payment_type}/>
                        </Grid>
                        {creditcard ? (
                            <Grid item xs={12}>
                                <DataWrapper label='No. de transacción' data={payment ? payment.payment_id : nodata}/>
                            </Grid>
                        ) : null}
                        <Grid item xs={12}>
                            <DataWrapper label='Total' data={_total}/>
                        </Grid>
                    </Grid>   
                </Grid> 
                
            </Grid>
        </SimpleCard>
    )
}

export default PaymentContainer

const DataWrapper = ({label, data}) => {
    return(
        <Grid item xs={12}>
            <Grid container spacing={1}>
                <Grid item xs><DisplayText color='textSecondary' variant='subtitle2'>{label}</DisplayText></Grid>
                <Grid item><DisplayText variant='body2'>{data}</DisplayText></Grid>
            </Grid>
        </Grid>
    )
}