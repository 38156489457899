import { Button, Grid, Icon, IconButton, makeStyles } from '@material-ui/core'
import React from 'react'
import SimpleCardOptical from '../../../../components/Structure/Cards/SimpleCardOptical'
import UserAvatar from '../../../../components/Structure/DisplayData/UserAvatar'
import DisplayText from '../../../../components/Texts/DisplayText'
import Ball from './Ball'
import DisplayStatus from './DisplayStatus'
import TopbarOptical from './TopbarOptical'
import DisplayData from './DisplayData'
import { grey } from '@material-ui/core/colors'
import AvatarPicture from '../../../../components/Structure/DisplayData/AvatarPicture'
import nouser from '../../../../assets/nouser.png'

const useStyles = makeStyles((theme) => ({
    root:{
        //backgroundColor:'red',
        //width:'100%',
        //display:'inline-block',
        //position:'relative',
        //width:'50%'
       // height:'100%',
       // paddingTop:'100%',
       // position:'relative'
    },
    img_root:{
        position:'relative',

    },
    img:{
        position:'absolute',
        left:'50%',
        top:'50%',
        transform: 'translate(100%, 70%)',
    },
    button:{
        backgroundColor:grey[300],
        '&:hover':{
            backgroundColor: grey[300]
        }
    },
    changePass:{
        borderRadius:8,
        border:`1px solid ${theme.palette.primary.main}`,
        //padding:'8px 16px'
    }
   
}));

const OpticaInfoCard = (props) => {

   const {business_name, image, status_id, identification_card, business_type_id, email, phone_number, view, handleView, img} = props
   const {onChangePicture, onUpdateImg, onChangePassword} = props

   const classes = useStyles()

    const optionsTabs = [
        //{label:'General', id:1},
        //{label:'Optómetras', id: 2},
        //{label:'Órdenes', id: 3},
        //{label:'Clientes', id: 4},
    ]

    let type = null
    if(business_type_id == 1){
        type='Natural'
    }
    if(business_type_id ==2){
        type='Jurídica'
    }

    const dataLabels =[
        {label:'Cédula/NIT', value:identification_card},
        {label:'Tipo', value:type},
        {label:'Email', value: email},
        {label:'Teléfono', value:phone_number}
    ]
    console.log(img)

    return(
        <div>
            <SimpleCardOptical>
                <Grid container spacing={2}>
                    <Grid item md={7} xs={12}>
                        <Grid container spacing={4} alignItems='center'> 
                            <Grid item md={3} xs={12}>
                                <Grid container spacing={0} justify='center'>
                                    <Grid item>
                                        <AvatarPicture can_edit nopicture={nouser} onChangePicture={onChangePicture} imgSrc={image}/>
                                    </Grid>
                                    
                                    <Grid item>
                                        <DisplayText variant='subtitle1'>{business_name}</DisplayText>
                                    </Grid>
                                    
                                </Grid>
                            </Grid>
                            <Grid item md={9} xs={12}>
                                <Grid container spacing={1}>
                                    {dataLabels.map((item, index)=>{
                                        return(
                                            <Grid item xs={12}  key={index}>
                                                <DisplayData {...item}/>
                                            </Grid>
                                        )
                                    })}
                                    <Grid item>
                                        <DisplayStatus status = {status_id}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button className={classes.changePass} onClick={onChangePassword}>Cambiar contraseña</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={5} xs={12}>
                        {/*<Grid container spacing={3} justify='center'>
                            <Grid item sm={6} xs={12}><Ball content='412+' label='Pacientes'/></Grid>
                            <Grid item sm={6} xs={12}><Ball content='412+' label='Opmeopetras'/></Grid>
                                </Grid>*/}
                    </Grid> 
                </Grid>
                <TopbarOptical optionsTabs={optionsTabs} view={view} handleView={handleView} />
            </SimpleCardOptical>
        </div>
    )
}

export default OpticaInfoCard

{/* <Grid container spacing={3} justify='center'>
                            <Grid item><Ball content='412+' label='Pacientes'/></Grid>
                            <Grid item><Ball content='412+' label='Opmeopetras'/></Grid>
                        </Grid> */}


{/* <div className={classes.root} >
    <Ball content='412+' label='Pacientes'/>
    <Ball content='412+' label='Opmeopetras'/>
</div> */}