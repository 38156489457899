import { makeStyles } from '@material-ui/core'
import React from 'react'
import { catalogs } from '../../../texts/esp/catalogs'
import cx from 'classnames'
import { blueGrey, green, red } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
    root:{
        fontFamily:theme.typography.fontFamily,
        fontSize:14,
        color:'white',
        display:'inline-block',
        padding:'8px 16px',
        borderRadius:32
    },
    active:{
        background:green[700]
    },
    inactive:{
        background:red[700]
    }
}))


const AvailableStatus = ({status}) => {

    const classes = useStyles()

    return(
        <div className={cx({
            [classes.root]:true,
            [classes.active]:status,
            [classes.inactive]:!status
        })}>
            {status ? 'Activo' : 'Inactivo'}
        </div>
    )
}

export default AvailableStatus