import { Grid, makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import React, { useState, useEffect } from 'react';
import ProductImage from '../../../../../components/Structure/Images/ProductImage';
import DisplayText from '../../../../../components/Texts/DisplayText';

const ProductItem = (props) => {

    const classes = useStyles()
    const {image, title, manufacturer, price, _id, onSelectedProduct} = props
    return (
        <div className={classes.root} onClick={() => onSelectedProduct(props)}>
            <Grid container wrap='nowrap' spacing={1}>
                <Grid item>
                    <ProductImage src={image} type='CHECKOUT'/>
                </Grid>
                <Grid item xs>
                    <Grid container>
                        <Grid item xs={12}>
                            <DisplayText variant='caption' color='textSecondary'>{manufacturer}</DisplayText>
                        </Grid>
                        <Grid item xs={12}>
                            <DisplayText variant='subtitle2'>{title}</DisplayText>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div> 
        
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        borderRadius:8,
        padding:8,
        '&:hover':{
            background:grey[100], cursor:'pointer'
        }
    }
}))
 
export default ProductItem;