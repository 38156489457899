import { Checkbox, Grid, makeStyles } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import SelectAutocompleteForm from '../../../../../components/Forms/SelectAutocompleteForm';
import DisplayText from '../../../../../components/Texts/DisplayText';
import { PRODUCT_TYPES } from '../../../../../variables/config';

const eye_options = [
    {id:'EQUAL', label:'Misma formula'},
    {id:'LEFT', label:'Ojo izquierdo'},
    {id:'RIGHT', label:'Ojo derecho'},
]

const FormulaContainer = ({form, product, product_type, onChange, onEyeChange, eye}) => {

    const classes = useStyles()

    const onInnerChange = (id) => {
        console.log(id)
        onEyeChange(id)
    }


    let formContent = null
    switch (product_type) {
        case PRODUCT_TYPES.MYIOPIA:
            formContent = (
                <Grid container spacing={2}>
                    <Grid item>
                        <div style={{width:200}}>
                            <SelectAutocompleteForm data={form.sphere} onChange={onChange} />
                        </div>
                    </Grid>
                </Grid>
            )
            break;
        case PRODUCT_TYPES.ASTIGMATISM:
            formContent = (
                <Grid container spacing={2}>
                    <Grid item>
                        <div style={{width:150}}>
                            <SelectAutocompleteForm data={form.sphere} onChange={onChange} />
                        </div>
                    </Grid>
                    <Grid item>
                        <div style={{width:150}}>
                            <SelectAutocompleteForm data={form.cylinder} onChange={onChange} />
                        </div>
                    </Grid>
                    <Grid item>
                        <div style={{width:150}}>
                            <SelectAutocompleteForm data={form.axis} onChange={onChange} />
                        </div>
                    </Grid>
                </Grid>
            )
            break;
        case PRODUCT_TYPES.MULTIFOCAL:
            formContent = (
                <Grid container spacing={2}>
                    <Grid item>
                        <div style={{width:150}}>
                            <SelectAutocompleteForm data={form.sphere} onChange={onChange} />
                        </div>
                    </Grid>
                    <Grid item>
                        <div style={{width:150}}>
                            <SelectAutocompleteForm data={form.addition} onChange={onChange} />
                        </div>
                    </Grid>
                    {form.dominance.options.length ? <Grid item>
                        <div style={{width:150}}>
                            <SelectAutocompleteForm data={form.dominance} onChange={onChange} />
                        </div>
                    </Grid> : null}
                </Grid>
            )
            break;
        case PRODUCT_TYPES.COSMETICS:
                formContent = (
                    <Grid container spacing={2}>
                        {form.sphere.options && form.sphere.options.length ? (
                            <Grid item>
                                <div style={{width:200}}>
                                    <SelectAutocompleteForm data={form.sphere} onChange={onChange} />
                                </div>
                            </Grid>
                        ) : null}
                        <Grid item>
                            <div style={{width:200}}>
                                <SelectAutocompleteForm data={form.color} onChange={onChange} />
                            </div>
                        </Grid>
                    </Grid>
                )
                break;
        default:
            break;
    }
    

    return ( 
        <div  className={classes.root}>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <DisplayText variant='subtitle1'>Configuración de formula</DisplayText>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2} wrap='nowrap'>
                                {eye_options.map(item => {
                                    return(
                                        <Grid item key={item.id}>
                                            <CheckboxItem {...item} checked={eye === item.id} onChange={onInnerChange} />
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {formContent}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        marginTop:24,
        marginBottom:24
    }
}))
 
export default FormulaContainer;


const CheckboxItem = props => {

    const {id, label, checked, onChange} = props

    return(
        <div>
            <Grid container alignItems='center'>
                <Grid item>
                    <Checkbox checked={checked} onChange={() => onChange(id)}/>
                </Grid>
                <Grid item xs>
                    <DisplayText variant='body2'>{label}</DisplayText>
                </Grid>
            </Grid>
        </div>
    )
}