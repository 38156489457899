import queryString from 'query-string'

export const onGetParamsFromState = (filter, tableFilter) => {
    let params2send = {...tableFilter}
    params2send.offset = params2send.offset*params2send.limit
    params2send.query = filter.query    
    return params2send
}

export const onUpdateURLArgs = (filter, tableFilter) => {
    let params = {...tableFilter}
    params.offset = params.offset*params.limit
    params.query = filter.query    
    const urlwithargs = `?${queryString.stringify(params, {sort: false})}`
    window.history.replaceState(null, null, urlwithargs);
}

export const onGetFiltersFromURL = (search, filter, tableFilter) => {
    const args = queryString.parse(search)
    const {limit, offset, order, order_by, query} = args
    let newFilter = {...filter}
    let newTableFilter = {...tableFilter}

    if(limit !== undefined) newTableFilter.limit = parseInt(limit)
    if(offset !== undefined) newTableFilter.offset = parseInt(offset)/parseInt(limit)
    if(order !== undefined) newTableFilter.order = parseInt(order)
    if(order_by !== undefined) newTableFilter.order_by = order_by

    if(query !== undefined) newFilter.query = query

    return{
        filter: newFilter,
        tableFilter: newTableFilter
    }

  }