import { Avatar, Divider, Grid, Icon, IconButton } from '@material-ui/core'
import { amber } from '@material-ui/core/colors'
import React from 'react'
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard'
import DisplayIconData from '../../../../components/Structure/DisplayData/DisplayIconData'
import UserAvatar from '../../../../components/Structure/DisplayData/UserAvatar'
import DisplayText from '../../../../components/Texts/DisplayText'
import { isValidDocument, onGetFullname } from '../../../../shared/utility'

const DocumentsCard = (props) => {

    const {documents, onUploadDocument, onDownloadDocument, onDeleteDocument} = props

    return(
        <div>
            <SimpleCard>
                <div style={{marginBottom:16}}>
                    <DisplayText variant='subtitle1'>Documentos</DisplayText>
                </div>
                <Grid container justify='center' spacing={2}>
                    {documents.map(item => {
                        return(
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item><Icon style={{color:amber[700]}}>folder</Icon></Grid>
                                    <Grid item xs><DisplayText>{item.name}</DisplayText></Grid>
                                    <Grid item>
                                        <Grid container>
                                            <Grid item><IconButton color='primary'  onClick={() => onUploadDocument(item)}><Icon fontSize='small'>cloud_upload</Icon></IconButton></Grid>
                                            <Grid item><IconButton color='primary' disabled={!item.status} onClick={() => onDownloadDocument(item)}><Icon fontSize='small'>cloud_download</Icon></IconButton></Grid>
                                            <Grid item><IconButton color='primary' disabled={!item.status} onClick={() => onDeleteDocument(item)}><Icon fontSize='small'>close</Icon></IconButton></Grid>
                                        </Grid>
                                    </Grid>                                  
                                </Grid>
                            </Grid>
                        )
                    })}
                </Grid>
            </SimpleCard>
        </div>
    )
}

export default DocumentsCard