import { Grid, makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import React, { useState, useEffect } from 'react';
import ProductImage from '../../../../../components/Structure/Images/ProductImage';
import ProductItem from './ProductItem';

const ProducListContainer = ({products, onSelectedProduct}) => {

    const classes = useStyles()

    return ( 
        <div  className={classes.list_container} >
            <Grid container spacing={2}>
                {products.map(item => {
                    return (
                        <Grid item xs={12} key={item._id.toString()}>
                            <ProductItem {...item} onSelectedProduct={onSelectedProduct}/>
                        </Grid>
                    )
                    
                })}
            </Grid>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    list_container:{
        //background:grey[100],
        height:500,
        width:300,
        overflowY:'auto',
        padding:8,
        boxSizing:'border-box'
    }
}))
 
export default ProducListContainer;