import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import DisplayText from '../../../../components/Texts/DisplayText'

const useStyles = makeStyles((theme) => ({
    root:{
       // backgroundColor:'red',
        width:'100%',
       // display:'flex'
       // height:'100%',
       // paddingTop:'100%',
       // position:'relative'
    },
    ball:{
        position:'relative',
        //display:'inline-block',
        height:140,
        width:140,
        backgroundColor:theme.palette.primary.main,
        color:'white',
        borderRadius:'50%',
        margin:'auto',
        marginBottom:8,
        /* left:'50%',
        top:'50%', 
        transform: 'translate(-50%, -50%)', */
    },
    ball_content:{
        position:'absolute',
        left:'50%',
        top:'50%', 
        transform: 'translate(-50%, -50%)',
    },
    text:{
        textAlign:'center',
        margin:'8px 0px 16px'
    }
}));

const Ball = (props) =>{

    const classes = useStyles()

    const {content, label} = props

    return(
        <div className={classes.root}>
            <div className={classes.ball}>
                <div className={classes.ball_content}>
                <DisplayText variant='h5'>{content}</DisplayText>
                </div>
            </div>
            <div className={classes.text}>
            <DisplayText variant='body2' color='textSecondary'>{label}</DisplayText>
            </div>
        </div>
    )

}

export default Ball