import { ClickAwayListener, Grid, Grow, Icon, IconButton, makeStyles, Popper } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import ShortcutButton from '../../../../../components/Actions/ShortcutButton';
import SelectAutocompleteForm from '../../../../../components/Forms/SelectAutocompleteForm';
import SimpleCard from '../../../../../components/Structure/Cards/SimpleCard';
import DisplayText from '../../../../../components/Texts/DisplayText';
import { isFormValid, onGetFormData, onSetErrorsToForm } from '../../../../../shared/utility';
import { catalogs } from '../../../../../texts/esp/catalogs';
import { request_search_products } from '../../requests';

const MyopiaButton = ({onInjectProductSearch}) => {

    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))
    const [error, setError] = useState(null)
    const [products, setProducts] = useState([])
    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
        if(error) setError(null)

    }

    /*useEffect(() => {
        let catalogs = []
        for(let i=10; i<190; i+=10){
            catalogs.push({value:`${i.toString()}°`, label:`${i.toString()}°`})
        }
        console.log(JSON.stringify({axis:catalogs}))
    },[])*/

    const onOpenMenu = async(e) => {
        setAnchorEl(e.currentTarget)
        if(!products.length){
            try {
                const params = {limit:100, offset:0, order:1, order_by:'title', query:'MIOPIA O HIPERMETROPIA'}
                const _response = await request_search_products(params)
                setProducts(_response.data)
            } catch (error) {
                console.log(error)
            }
        }
    }
        

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            setError('Ingrese todos los campos')
            return
        }

        //Extract data
        let data2send = onGetFormData(form)

        const _sphere = data2send.sphere

        let filter_products = []
        
        products.forEach(item => {
            let variants = null
            try {variants = JSON.parse(item.variants)                
            } catch (error) {console.log(error)}
            let valid = false
            if(variants){
                const {adds, axes, colors, cylinders, dominances, spheres} = variants
                if(spheres && spheres.length){
                    spheres.forEach(el => {
                        const _value = parseFloat(el.label)
                        if(_value === parseFloat(_sphere)) valid = true
                    })
                }     
            }
            if(valid) filter_products.push(item)
        })

        //console.log(filter_products)
        if(!filter_products.length){
            setError('No existen productos con esta fórmula')
        }else{
            setError(null)
            onInjectProductSearch(data2send, filter_products, 1) // Myiopia
            setAnchorEl(null)
            setForm(JSON.parse(JSON.stringify(form_data)))

        }

    }

    const onCloseComponent = () => {
        setAnchorEl(null)
        setForm(JSON.parse(JSON.stringify(form_data)))
    }

    return ( 
        <div>
            <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement='bottom-start' disablePortal style={{zIndex:5}} >
                <ClickAwayListener onClickAway={onCloseComponent}>
                    <Grow in={Boolean(anchorEl)} timeout={350}>
                        <div style={{marginTop:8}}>
                            <SimpleCard >
                                <div>
                                    <Grid container direction='column' spacing={2}>
                                        <Grid item>
                                            <DisplayText variant='body2'>Introduzca la formula</DisplayText>
                                        </Grid>
                                        <Grid item>
                                            <Grid container  wrap='nowrap' spacing={2}>
                                                <Grid item>
                                                    <div style={{width:200}}>
                                                        <SelectAutocompleteForm data={form.sphere} onChange={onChange} />
                                                    </div>
                                                </Grid>
                                                <Grid item>
                                                    <IconButton className={classes.search} onClick={onSubmit}><Icon>search</Icon></IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            {error ? <DisplayText variant='body2' color='error'>{error}</DisplayText> : null}
                                        </Grid>
                                    </Grid>
                                </div>
                            </SimpleCard>
                        </div>
                        
                    </Grow>
                </ClickAwayListener>
            </Popper>
            <ShortcutButton onClick={onOpenMenu} >Esféricos</ShortcutButton>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    search:{
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    }
}))


 
export default MyopiaButton;

const form_data = {
    sphere: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:catalogs.spheres,
        config: {
            id: 'sphere',
            type: 'select',
            fullWidth: true,
            label: 'ESF/PWR',
            helperText: ''
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
}