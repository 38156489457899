import React, {useState} from 'react';
import {  Button, Card, ClickAwayListener, Grid,  Grow,  Icon,  makeStyles, Popper } from '@material-ui/core';
import { blueGrey } from '@material-ui/core/colors';
import cx from 'classnames'

const useStyles = makeStyles(theme => ({
    root:{
        zIndex:3
    },
    selected:{
        borderRadius:16,
        padding:'8px 24px',
        backgroundColor:theme.palette.primary.main,
        color:'white',
        '&:hover':{
            backgroundColor:theme.palette.primary.main,
            color:'white',
        }
    }
    
}))

const PageMenuMobile = (props) => {

    const {selected, onChange, options} = props

    const [anchorEl, setAnchorEl] = useState(null)
    
    const classes = useStyles()

    const onSelectItem = (id) =>{
        setAnchorEl(null)
        onChange(id)
    }

    const buttonContent = (<Card>
        <Grid container direction='column'>
            {options.map(item => {
                return (
                <Grid item xs>
                    <CustomButton number={item.number} selected={item.id === selected} onClick={() => onSelectItem(item.id)}>
                        {item.label}
                    </CustomButton>
                </Grid>
                )
            })}
        </Grid>
    </Card>)

    const onGetName = (op , sel) => {
        let name= null
        op.forEach(element => {
            if(sel === element.id)
            name = element.label
        });
        return name
    }

    return ( 
        <div className={classes.root}>
            <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement='bottom-start' disablePortal style={{zIndex:10}} >
                <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    <Grow in={Boolean(anchorEl)} timeout={350}>
                    {buttonContent}
                    </Grow>
                </ClickAwayListener>
            </Popper>
            <div onClick={(e) => setAnchorEl(e.currentTarget)} >
                <Button className={classes.selected}>    
                    {onGetName(options, selected)}
                    <Icon >expand_more</Icon>                    
                </Button>
            </div>
        </div>
     );
}
 
export default PageMenuMobile;

const useButtonStyles = makeStyles(theme => ({
    root:{position:'relative', zIndex:2},
    button:{
        borderRadius:16,
        padding:'8px 24px',
        backgroundColor:blueGrey[100],
        color:'black',
        '&:hover':{
            backgroundColor:blueGrey[100],
            color:'black',
        },
        width:'100%',
        marginTop:4
    },
    selected:{
        borderRadius:16,
        padding:'8px 24px',
        backgroundColor:theme.palette.primary.main,
        color:'white',
        '&:hover':{
            backgroundColor:theme.palette.primary.main,
            color:'white',
        }
    }
}))

const CustomButton = props => {
    const classes = useButtonStyles()
    const {children, selected, onClick} = props

    return(      
        <Button onClick={onClick} className={cx({
            [classes.button]:true,
            [classes.selected]:selected
        })}>{children}</Button>  
    )
}