import { ClickAwayListener, Grid, Grow, Icon, IconButton, makeStyles, Popper } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import ShortcutButton from '../../../../../components/Actions/ShortcutButton';
import InputForm from '../../../../../components/Forms/InputForm';
import SelectAutocompleteForm from '../../../../../components/Forms/SelectAutocompleteForm';
import SimpleCard from '../../../../../components/Structure/Cards/SimpleCard';
import DisplayText from '../../../../../components/Texts/DisplayText';
import { isFormValid, onGetFormData, onSetErrorsToForm } from '../../../../../shared/utility';
import { catalogs } from '../../../../../texts/esp/catalogs';
import { request_search_products } from '../../requests';

const CosmeticsButton = ({onInjectProductSearch}) => {

    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))
    const [error, setError] = useState(null)
    const [products, setProducts] = useState([])
    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
        if(error) setError(null)

    }

    const onOpenMenu = async(e) => {
        setAnchorEl(e.currentTarget)
        if(!products.length){
            try {
                const params = {limit:100, offset:0, order:1, order_by:'title', query:'COSMETICOS'}
                const _response = await request_search_products(params)
                setProducts(_response.data)
            } catch (error) {
                console.log(error)
            }
        }
    }
        

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            setError('Ingrese todos los campos')
            return
        }

        //Extract data
        let data2send = onGetFormData(form)
        console.log(data2send)

        const _sphere = data2send.sphere
        const _color = data2send.color

        let filter_products = []
        
        products.forEach(item => {
            let variants = null
            try {variants = JSON.parse(item.variants)                
            } catch (error) {console.log(error)}
            let valid_sphere = false
            let valid_color = false
            if(variants){
                const {adds, axes, colors, cylinders, dominances, spheres} = variants
                console.log(colors)

                if(colors && colors.length){
                    colors.forEach(el => {
                        const _value = el.label
                        if(_value === _color) valid_color = true
                    })
                }   
            }
            /*console.log(`
                sphere:${valid_sphere},
                cylinder:${valid_cylinder},
                axis:${valid_axis},
                response:${valid_sphere && valid_cylinder && valid_axis}
            `)*/
            if( valid_color ) filter_products.push(item)
        })

        if(!filter_products.length){
            setError('No existen productos con esta fórmula')
        }else{
            setError(null)
            onInjectProductSearch(data2send, filter_products, 1) // Myiopia
            setAnchorEl(null)
            setForm(JSON.parse(JSON.stringify(form_data)))

        }

    }

    const onCloseComponent = () => {
        setAnchorEl(null)
        setForm(JSON.parse(JSON.stringify(form_data)))
    }

    return ( 
        <div>
            <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement='bottom-start' disablePortal style={{zIndex:5}} >
                <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    <Grow in={Boolean(anchorEl)} timeout={350}>
                        <div style={{marginTop:8}}>
                            <SimpleCard >
                                <div>
                                    <Grid container direction='column' spacing={2}>
                                        {/* <Grid item>
                                            <DisplayText variant='body2'>Introduzca la formula</DisplayText>
                                        </Grid> */}
                                        <Grid item>
                                            <Grid container  wrap='nowrap' spacing={2}>
                                                <Grid item>
                                                    <div style={{width:250}}>
                                                        <SelectAutocompleteForm data={form.color} onChange={onChange} />
                                                    </div>
                                                </Grid>
                                                <Grid item>
                                                    <IconButton className={classes.search} onClick={onSubmit}><Icon>search</Icon></IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            {error ? <DisplayText variant='caption' color='error'>{error}</DisplayText> : null}
                                        </Grid>
                                    </Grid>
                                </div>
                            </SimpleCard>
                        </div>
                        
                    </Grow>
                </ClickAwayListener>
            </Popper>
            <ShortcutButton onClick={onOpenMenu} >Cosméticos</ShortcutButton>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    search:{
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    }
}))


 
export default CosmeticsButton;

const form_data = {
    color: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:catalogs.colors,
        config: {
            id: 'color',
            type: 'select',
            fullWidth: true,
            label: 'COLOR',
            helperText: ''
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
}