import { Grid } from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import SimpleCard from '../../../components/Structure/Cards/SimpleCard'
import DisplayText from '../../../components/Texts/DisplayText'
import { useStyles } from './styles'
import { onSetErrorsToForm, isFormValid, onInitForm, onGetSelectedFormData } from '../../../shared/utility'
import InputForm from '../../../components/Forms/InputForm'
import SelectForm from '../../../components/Forms/SelectForm'
import { catalogs } from '../../../texts/esp/catalogs'
import ContainedButton from '../../../components/Actions/ContainedButton'
import {request_update_optometrist} from '../requests'


const OptometristGeneralInfo = (props) => {

    const {data, data_lr, id, onUpdateCompleted, onDispatchSuccess} = props

    const classes = useStyles()

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [optical, setOptical] = useState(null)
    const [error, setError] = useState(null)

    useEffect(() => {
        const initModule = async() => {try {
            setLoading(true)
            if(data){
                const _form = onInitForm(form, data)
                setOptical(data)
                setForm(_form)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }}
        initModule()
    }, [data])

  
    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            console.log('hay errores')
            console.log(errors)
            return
        }

        //Extract data
        let data2send = onGetSelectedFormData(form, optical)
        //console.log(data2send)
        //console.log(id)

        setSending(true)
        try {
            const _optical = await request_update_optometrist(id, data2send);
            //setOptical(_optical)
            //onUpdate(_optical)
            onUpdateCompleted()
            setSending(false)
            //onDispatchSuccess('Acción exitosa')
            //funcion para actualizar info_card
        } catch (error) {
            console.log(error)
            setError('Hubo un error')
            setSending(false)
        }

    }


    return(
        <div>
            <SimpleCard>
                <Grid container spacing={4} justify='flex-end'>
                    <Grid item xs={12}>
                        <DisplayText variant='subtitle1'>Información general</DisplayText>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} >
                                <InputForm data={form.first_name} onChange={onChange} />
                            </Grid>
                            <Grid item xs={12} >
                                <InputForm data={form.last_name} onChange={onChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <InputForm data={form.email} onChange={onChange} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputForm data={form.identification_card} onChange={onChange} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputForm data={form.professional_card} onChange={onChange} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputForm data={form.phone_number} onChange={onChange} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <SelectForm data={form.gender_id} onChange={onChange} />
                            </Grid>
                            <Grid item xs={12} >
                                <SelectForm data={form.branch_office_id} onChange={onChange} />
                            </Grid>
                            {/* <Grid item xs={12} md={6}>
                                <SelectForm data={form.status_id} onChange={onChange} />
                            </Grid> */}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <ContainedButton color='primary' loading={sending} onClick={onSubmit}>Guardar</ContainedButton>
                    </Grid>
                </Grid>
            </SimpleCard>
        </div>
    )
}

export default OptometristGeneralInfo

const formData = {
    first_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'first_name',
            type: 'text',
            fullWidth: true,
            label: 'Nombre(s)',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    last_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'last_name',
            type: 'text',
            fullWidth: true,
            label: 'Apellido(s)',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    identification_card: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'identification_card',
            type: 'text',
            fullWidth: true,
            label: 'Cédula',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    professional_card: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'professional_card',
            type: 'text',
            fullWidth: true,
            label: 'Tarjeta profesional',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    email: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'email',
            fullWidth: true,
            label: 'Dirección de correo',
            helperText: 'Dirección de correo no válido',
            disabled:true,
        },
        rules: {
            type: 'email',
        }
    },
    phone_number: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'phone_number',
            type: 'number',
            fullWidth: true,
            label: 'Teléfono',
            helperText: 'Número de teléfono no válido',
        },
        rules: {
            type: 'phone',
        }
    },
    gender_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[...catalogs.genders],
        config: {
            id: 'gender_id',
            type: 'select',
            fullWidth: true,
            label: 'Género',
            helperText: 'Debe elegir una opción',
        },

    },
    branch_office_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config: {
            id: 'branch_office_id',
            type: 'select',
            fullWidth: true,
            label: 'Sede',
            helperText: 'Debe elegir una opción',
        },

    },
    /* status_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...catalogs.user_statuses],
        config: {
            id: 'status_id',
            type: 'select',
            fullWidth: true,
            label: 'Estatus',
            helperText: 'Debe elegir una opción',
        },

    }, */
}