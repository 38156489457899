import React, {useState, useEffect} from 'react'
import { makeStyles, Grid, IconButton, Icon, AppBar, Avatar } from '@material-ui/core'
import logo from '../../../assets/ubelens-logo-color.png'
import logoWhite from '../../../assets/ubelens-logo-white.png'

import MenuTopBar from './components/MenuTopbar'
import clsx from 'clsx'
import { withRouter } from 'react-router-dom'
import UserButton from './components/UserButton/UserButtonAvatar'
import { blueGrey } from '@material-ui/core/colors'
import DisplayText from '../../Texts/DisplayText'
import { connect } from 'react-redux'
//asdfasfdgit


const useStyles = makeStyles(theme => ({
    root:{
        paddingTop:16,
        marginRight:16,
        paddingLeft:134,
        color:blueGrey[900],
        background:'#f6f7fb',
        //add by me
        [theme.breakpoints.down('sm')]: {
            paddingLeft:0,
          },
        [theme.breakpoints.up('sm')]: {
            paddingLeft:134,
          },
    },
    appbar:{
        boxShadow:'none',
        background:'#f6f7fb',
        padding:16,
    },
    welcome:{
        fontWeight:500,
    },
    name:{
        fontWeight:500,
        color:blueGrey[700]
    },
    menu:{
        //marginRight:50,
        [theme.breakpoints.up('sm')]: {
          display: 'none',
        },
    }
}))

const Topbar = props => {

    const {user, history} = props
    const classes = useStyles()

  
    const onItemSelected = (url) =>{
        history.push(url)
    }

    const onLogOut = () => {
        localStorage.clear()
        history.push('/signin')

    }

    return(
        <AppBar className={classes.appbar}>
             <div className={classes.root}>
                
                 <Grid container wrap='nowrap' alignItems='center'>
                     <Grid item>
                        <IconButton
                            color="primary"
                            aria-label="open drawer"
                            edge="end"
                            onClick={() => props.sideBarHandler()}
                            className={classes.menu}
                        >
                            <Icon>menu</Icon>
                        </IconButton>
                     </Grid>
                     <Grid item xs>
                         {/*<Grid container spacing={1}>
                             <Grid item>
                                 <DisplayText variant='h4' color='primary' className={classes.welcome}>Bienvenid@,</DisplayText>
                             </Grid>
                             <Grid item>
                                <DisplayText variant='h4' className={classes.name}>{user ? user.first_name : ''}</DisplayText>
                             </Grid>
                         </Grid>*/}
                     </Grid>
                     <Grid item>
                        <UserButton onItemSelected={onItemSelected} onLogOut={onLogOut} user={user}/>
                     </Grid>
                     
                 </Grid>
            </div>
        </AppBar>
       
    )
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

export default withRouter(connect(mapStateToProps)(Topbar))
