import React, {useState, useEffect} from 'react'
import { Grid, Icon, IconButton, makeStyles } from '@material-ui/core'
import { request_upload_image, request_upload_document, request_delete_image } from '../requests'
import {getFileExtension} from '../../../../shared/utility'
import DisplayText from '../../../../components/Texts/DisplayText'
import SimpleModal from '../../../../components/Overlays/SimpleModal'
import SimpleFile from '../../../../components/Forms/SimpleFile'
import ContainedButton from '../../../../components/Actions/ContainedButton'

const useStyles = makeStyles(theme => ({
    paper:{
        borderRadius:30
    },
    button:{
        borderRadius:32,
        textTransform:'none'
    }
}))

const UploadImageModal = props => {

    const classes = useStyles()
    
    //////////////////////////// MULTIPLE STATES AND PROPS  ///////////////////////////////
    const {data, onClose, open, id, onUpdateData} = props
    const [file, setFile] = useState(null) 
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    
    useEffect(() => {
        if(open) setFile(null)
    },[open])

    //////////////////////////// INTERNAL FUNCTIONS  ///////////////////////////////
    const onDocumentAttached = (id, files) => {
        setFile(files[0])
    }

    const onDocumentRemove = () => {
        setFile(null)
    }

    const onSubmit = async() => {
        if(!file){
            setError('Cargue una imagen')
            return
        }
        const ext = {type:getFileExtension(file.name)}
        setLoading(true)
        try {
            const _product = await request_upload_image(id, file, ext)
            onUpdateData(_product)
            setLoading(false)
            //onClose()
        } catch (error) {
            console.log(error)
            setError('Hubo un error')
            setLoading(false)
        }
    }

    const onDeleteImage = async() => {
        setLoading(true)
        try {
            const _product = await request_delete_image(id)
            onUpdateData(_product)
            setLoading(false)
        } catch (error) {
            console.log(error)
            setError('Hubo un error')
            setLoading(false)
        }
    }


    return(
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <DisplayText variant='h6'>Subir documento</DisplayText>
                    </Grid>
                    <Grid item xs={12}>
                        <SimpleFile
                            label={data ? data.title : ''}
                            id='generic' file={file}
                            onDocumentAttached={onDocumentAttached}
                            onDocumentRemove={onDocumentRemove}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {error ? <DisplayText variant='subtitle2' color='error' align='center'>{error}</DisplayText> : null}
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item>
                                <IconButton onClick={onDeleteImage}><Icon>delete</Icon></IconButton>
                            </Grid>
                            <Grid item xs/>
                            <Grid item>
                                <ContainedButton color='primary' onClick={onSubmit} loading={loading}>Subir</ContainedButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </SimpleModal>
    )
}

export default UploadImageModal