import React, { useState, useEffect } from 'react'
import { Button, CircularProgress, Dialog, Grid, Grow, Icon, IconButton, makeStyles, Typography } from '@material-ui/core'
import Heading from '../../components/Texts/Heading'
import { blueGrey } from '@material-ui/core/colors'
import InputForm from '../../components/Forms/InputForm'
import { isFormValid } from '../../shared/utility'
import { useStyles } from './styles'
import DisplayText from '../../components/Texts/DisplayText'
import { request_create_user } from './requests'
import { connect } from 'react-redux'
import * as actionTypes from '../../store/actions'
import SelectForm from '../../components/Forms/SelectForm'
import { catalogs } from '../../texts/esp/catalogs'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} timeout={500} />;
});

const SignUpModal = props => {

    const { open, onClose, onChangeModal } = props

    const classes = useStyles()
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [completed, setCompleted] = useState(false)
    const [isValid, setIsValid] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')

    useEffect(() => {
        if (!open) {
            setForm(JSON.parse(JSON.stringify(formData)))
            setError(''); setLoading(false); setCompleted(false)
        }
    }, [open])


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        if(id === 'password') temp['passwordConfirmation'].value2 = temp[id].value
        setForm(temp)
        setIsValid(isFormValid(temp))
    }

    const onSubmit = async () => {
        if (!isValid) {
            setError('Llene los cambios solicitados')
            return
        }
        const {firstName, lastName, email, password, gender_id} = form
        let data2send = {
            email:email.value, password:password.value, first_name:firstName.value, last_name:lastName.value, gender_id: gender_id.value,
            user_type_id:2, 
        }
        console.log(data2send)
        setLoading(true)
        try {
            await request_create_user(data2send);
            setCompleted(true)
        } catch (error) {
            console.log(error)
        }
        setLoading(false)

    }

    let contentView = (
        <div>
            <Typography variant='h4' color='primary' style={{ fontWeight: 500 }}>¡Regístrate!</Typography>
            <Typography variant='h6' style={{ color: blueGrey[700] }}>Por favor ingresa los datos solicitados</Typography>
            <div className={classes.form}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <InputForm data={form.firstName} onChange={onChange} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputForm data={form.lastName} onChange={onChange} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputForm data={form.email} onChange={onChange} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputForm data={form.password} onChange={onChange} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputForm data={form.passwordConfirmation} onChange={onChange} />
                    </Grid>
                    <Grid item xs={12}>
                        <SelectForm data={form.gender_id} onChange={onChange} />
                    </Grid>
                </Grid>
            </div>
        </div>
    )

    if(completed){
        contentView = (
            <div>
                <Typography variant='h4' color='primary' style={{ fontWeight: 500 }}>!Registro exitoso!</Typography>
                <Typography variant='body1' style={{ color: blueGrey[700], marginTop:16 }}>Revise su correo electrónico para confirmar su cuenta y pueda acceder a todos los beneficios de la plataforma</Typography>
                <Grid container justify='center'><Grid item><Icon className={classes.successIcon}>done</Icon></Grid></Grid>
            </div>
        )
    }

    return (
        <Dialog open={open} maxWidth='xs' fullWidth classes={{ paper: classes.paper }} className={classes.root}
            TransitionComponent={Transition}>
            <div className={classes.buttonContainer}><IconButton onClick={() => onClose('signup')}><Icon >close</Icon></IconButton></div>
            <div className={classes.container}>
                {contentView}
                <Button variant='contained' color='primary' size='large' fullWidth onClick={completed ? () => onChangeModal('signup', 'signin') : onSubmit}>
                    {completed ? 'Iniciar sesión' : 'Registrarse' }
                    {loading ? (<CircularProgress style={{ width: 24, height: 24, marginLeft: 8, color: 'white' }} />) : null}
                </Button>
                <div className={classes.linkContainer}>
                    {!completed 
                    ?   <Grid container alignItems='center' spacing={1} wrap='nowrap' justify='center'>
                            <Grid item>
                                <Heading color='bluegrey_dark'>¿Ya tienes una cuenta?</Heading>
                            </Grid>
                            <Grid item>
                                <div className={classes.link} onClick={() => onChangeModal('signup', 'signin')}> Inicia sesión aquí</div>
                            </Grid>
                        </Grid>
                    :   null}
                </div>
                <DisplayText color='red' align='center'>{error}</DisplayText>
            </div>
        </Dialog>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        onChangeAuthStatus: (isauth) => dispatch({ type: actionTypes.CHANGE_AUTH_STATUS, isauth })
    }
}


export default connect(null, mapDispatchToProps)(SignUpModal)


const formData = {
    firstName: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'firstName',
            type: 'text',
            fullWidth: true,
            label: 'Nombre(s)',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    lastName: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'lastName',
            type: 'text',
            fullWidth: true,
            label: 'Apellido(s)',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    email: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'email',
            fullWidth: true,
            label: 'Dirección de correo',
            helperText: 'Dirección de correo no válido'
        },
        rules: {
            type: 'email',
        }
    },
    password: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'password',
            type: 'password',
            fullWidth: true,
            label: 'Contraseña',
            helperText: 'Debe contener 6 a 20 caracteres, incluyendo al menos 1 dígito, 1 mayúscula y 1 minúscula',
            autoComplete:'new-password'
        },
        rules: {
            type: 'password',
        }
    },
    passwordConfirmation: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'passwordConfirmation',
            type: 'password',
            fullWidth: true,
            label: 'Validar contraseña',
            helperText: 'Las contraseñas no coinciden'
        },
        rules: {
            type: 'equals',
        }
    },
    gender_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...catalogs.genders],
        config: {
            id: 'gender_id',
            type: 'select',
            fullWidth: true,
            label: 'Género',
            helperText: 'Debe elegir una opción',
        },

    },
}