const { mainServer } = require("../../../variables/config");

export const request_client = async(id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/client/${id}`)
    return response.data.data
}

export const request_update_client = async(id,data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.patch(`/client/${id}`, data)
    return response.data.data
}