import React, { useState, useEffect } from 'react'
//ck
import { useHistory } from "react-router-dom";
//fck
import { Grid } from '@material-ui/core'
import { isFormValid, onGetFormData, onSetErrorsToForm } from '../../../../../shared/utility'
import { useStyles } from './styles'
import DisplayText from '../../../../../components/Texts/DisplayText'
import { request_create_product } from '../../requests'
import SelectForm from '../../../../../components/Forms/SelectForm'
import { catalogs } from '../../../../../texts/esp/catalogs'
import InputForm from '../../../../../components/Forms/InputForm'
import SimpleModal from '../../../../../components/Overlays/SimpleModal'
import ContainedButton from '../../../../../components/Actions/ContainedButton'




const AddUserModal = props => {

    const { open, onClose, onRequestUsers } = props

    //ck
   // const {history} = props
    let history = useHistory();

    const classes = useStyles()
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')

    useEffect(() => {
        if (!open) {
            setForm(JSON.parse(JSON.stringify(formData)))
            setError(''); setLoading(false);
        }
    }, [open])


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        if(id === 'password') temp['passwordConfirmation'].value2 = temp[id].value
        setForm(temp)
    }

    const onSubmit = async () => {
       const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            console.log('hay errores')
            return
        } 

        //Extract data
        let data2send = onGetFormData(form)
        
        setLoading(true)
        try {
            let response = await request_create_product(data2send);
            console.log(response)
            //no sea para que sea eso
            //onRequestUsers()
            //redirecciona a la pagina del producto
            setLoading(false)
            history.push(`/products/${response._id}`)
        
        } catch (error) {
            console.log(error)
            setError('Hubo un error')
            setLoading(false)
        }

    }

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div >
                <DisplayText variant='h5' color='primary' style={{ fontWeight: 500 }}>Agregar producto</DisplayText>
                <DisplayText >Por favor ingresa los datos solicitados</DisplayText>
                <div className={classes.form}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <InputForm data={form.title} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.price} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.long_description} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.details} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.specifications} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.variants} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.manufacturer} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.tags} onChange={onChange} />
                        </Grid>
                        
                    </Grid>
                </div>
                <ContainedButton color='primary' fullWidth onClick={onSubmit} loading={loading}>
                    Agregar
                </ContainedButton>
                <DisplayText color='error' align='center'>{error}</DisplayText>
            </div>
        </SimpleModal>
    )
}

export default AddUserModal


const formData = {
    title: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'title',
            type: 'text',
            fullWidth: true,
            label: 'Nombre',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    manufacturer: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'manufacturer',
            type: 'text',
            fullWidth: true,
            label: 'Laboratorio',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    tags: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'tags',
            type: 'text',
            fullWidth: true,
            label: 'Tags',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    long_description: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'long_description',
            type: 'text',
            fullWidth: true,
            label: 'Descripción',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 1024',
            rows:4,
            multiline:true
        },
        rules: {
            type: 'distance',
            min: 1, max: 1024
        }
    },
    price: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'price',
            type: 'number',
            fullWidth: true,
            label: 'Precio',
            helperText: 'El campo no puede ser negativo'
        },
        rules: {
            type: 'numeric',
            min: 0
        }
    },
    specifications: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'specifications',
            type: 'text',
            fullWidth: true,
            label: 'Especificaciones',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 1024',
            rows:4,
            multiline:true
        },
        rules: {
            type: 'distance',
            min: 0, max: 1024
        }
    },
    details: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'details',
            type: 'text',
            fullWidth: true,
            label: 'Detalles',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 1024',
            rows:4,
            multiline:true
        },
        rules: {
            type: 'distance',
            min: 0, max: 1024
        }
    },
    variants: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'variants',
            type: 'text',
            fullWidth: true,
            label: 'Variantes',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 65536',
            rows:4,
            multiline:true
        },
        rules: {
            type: 'distance',
            min: 0, max: 65536
        }
    },
    available: {
        value: '1',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: true,
        options:[{value:1, label:'Activo'},{value:2, label:'Inactivo'}],
        config: {
            id: 'available',
            type: 'selected',
            fullWidth: true,
            label: 'Estatus',
            helperText: 'Seleccione al menos una opción',
        },
        rules: {
            type: 'select',
        }
    },
}