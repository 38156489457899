import React, { useState, useEffect } from 'react';
import { Grid, Icon, IconButton, makeStyles } from '@material-ui/core';
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard';
import DisplayText from '../../../../components/Texts/DisplayText';
import InputForm from '../../../../components/Forms/InputForm';
import RoundedButton from '../../../../components/Actions/Buttons/RoundedButton';
import { isFormValid, onGetFormData, onSetErrorsToForm } from '../../../../shared/utility';
import {request_update_discount, request_order} from '../requests'
import { ORDER_STATUS_TYPES } from '../../../../variables/config';
const DiscountCard = ({order, onUpdateData}) => {

    const classes = useStyles()
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const onChange = (data) => {
        let temp = {...form}
        const id = data.config.id
        temp[id] = {...data}
        setForm(temp)
    }

    const onSubmit = async() => {
        
        const errors = isFormValid(form)
        
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        let data2send = onGetFormData(form)
        data2send.discount = (parseFloat(data2send.discount)/100).toFixed(4)

        setLoading(true)
        try {
            const orderID = order._id
            await request_update_discount(orderID, data2send)
            const _order = await request_order(orderID)
            onUpdateData(_order)
        } catch (error) {
            console.log(error)
            setError('Hubo un error')
            
        }
        setLoading(false)
    }

    const onRemoveDiscount = async() => {

        let data2send = {discount:0}

        setLoading(true)
        try {
            const orderID = order._id
            await request_update_discount(orderID, data2send)
            const _order = await request_order(orderID)
            onUpdateData(_order)
        } catch (error) {
            console.log(error)
            setError('Hubo un error')
            
        }
        setLoading(false)
    }

    let showDiscountCard = true


    let editDiscountContent = null

    if(order){
        //console.log(order.discount)
        if(!order.discount){
            if(order.status_id !== ORDER_STATUS_TYPES.PENDING) showDiscountCard = false
            editDiscountContent = (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <DisplayText variant='subtitle1'>Agregar descuento (%)</DisplayText>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs>
                                <InputForm data={form.discount} onChange={onChange}/>
                            </Grid>
                            <Grid item>
                                <RoundedButton disabled={loading} onClick={onSubmit} loading={loading}>Aplicar</RoundedButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )
        }else{
            editDiscountContent = (
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs>
                                <DisplayText variant='subtitle1'>Descuento aplicado</DisplayText>
                            </Grid>
                            {order.status_id === ORDER_STATUS_TYPES.PENDING ? <Grid item>

                                <IconButton onClick={onRemoveDiscount} disabled={loading} size='small'><Icon>close</Icon></IconButton>
                            </Grid> : null}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <DisplayText variant='body1'>
                            {`${parseFloat(order.discount*100).toFixed(2)} %`}
                        </DisplayText>
                    </Grid>
                </Grid>
            )
        }
    }

    

    return ( 
        <div>
            {showDiscountCard ? <SimpleCard>
                <div className={classes.root}>
                    {editDiscountContent}
                </div>
            </SimpleCard> : null}
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{

    }
}))
 
export default DiscountCard;

const formData = {
    discount:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
          id:'discount',
          type:'number',
          fullWidth: true,
          label:'',
          placeholder:'Ej. 10.5,20,35',
          helperText:'El valor debe ser entre 0 y 100',
        },
        rules:{
          type:'numeric',
          min:0, max:100
        }
    },
}

