import axios from 'axios'
import FileSaver from 'file-saver'
import { mainServer } from '../../../variables/config'

export const request_user = async(id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/patient/${id}`)
    return response.data.data
}

export const request_update_user = async(id,data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.patch(`/patient/${id}`, data)
    return response.data.data
}

export const request_update_shipping_address = async(id,data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.patch(`/patient/${id}/shippingAddress`, data)
    return response.data.data
}

export const request_update_billing_address = async(id,data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.patch(`/patient/${id}/billingAddress`, data)
    return response.data.data
}

export const request_change_password = async(id,data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    await mainServer.patch(`/user/${id}/changepassword`, data)
}

export const request_upload_image = async(id, file, data) => {
    const logData = JSON.parse(localStorage.getItem('token_data'));
    mainServer.defaults.headers.common['Authorization'] = logData.token;
    let response = await mainServer.post(`/patient/${id}/image`, data)
    const options = {headers: {'Content-Type': file.type}}
    delete axios.defaults.headers.common["Authorization"]
    await axios.put(response.data.data.url, file, options);
}

export const request_delete_image = async(id) => {
    const logData = JSON.parse(localStorage.getItem('token_data'));
    mainServer.defaults.headers.common['Authorization'] = logData.token;
    await mainServer.delete(`/patient/${id}/image`)
}
  



///////////////////////////// DOCUMENTS HANDLER  /////////////////////////////////////////////

export const request_upload_document = async(id, file, data) => {
    const logData = JSON.parse(localStorage.getItem('token_data'));
    mainServer.defaults.headers.common['Authorization'] = logData.token;
    let response = await mainServer.post(`/patient/${id}/document`, data)
    console.log(response.data)
    const options = {headers: {'Content-Type': file.type}}
    delete axios.defaults.headers.common["Authorization"]
    await axios.put(response.data.data.url, file, options);
}
  
export const request_download_document = async(id, data) => {
    const logData = JSON.parse(localStorage.getItem('token_data'));
    mainServer.defaults.headers.common['Authorization'] = logData.token;
    const response = await mainServer.get(`/patient/${id}/document`, {params:{...data}})
    delete axios.defaults.headers.common["Authorization"]
    const result = await axios.get(response.data.data.url, {responseType: 'blob'});
    FileSaver.saveAs(result.data, data.document);
} 

export const request_delete_document = async(id, data) => {
    const logData = JSON.parse(localStorage.getItem('token_data'));
    mainServer.defaults.headers.common['Authorization'] = logData.token;
    await mainServer.delete(`/patient/${id}/document`, {params:{...data}})
}


/*------ORDERS------ */
export const request_orders = async(params) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/order/admin/all`, {params:{...params}})
    return response.data.data
}