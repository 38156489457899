import React, {useState} from 'react';
import { Grid } from '@material-ui/core';
import ContainedButton from '../../../../components/Actions/ContainedButton';
import InputForm from '../../../../components/Forms/InputForm';
import SelectForm from '../../../../components/Forms/SelectForm';
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard';
import DisplayText from '../../../../components/Texts/DisplayText';
import { isFormValid, onGetSelectedFormData, onSetErrorsToForm } from '../../../../shared/utility';
import { request_update_user } from '../requests';

const BasicInfoForm = ({form, onChange, user, onUpdateForm, onUpdateData, onDispatchSuccess}) => {

    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            onUpdateForm(new_form)
            return
        }
        //Extract data
        let data2send = onGetSelectedFormData(form, user)
        setSending(true)
        try {
            const _user = await request_update_user(user._id, data2send);
            onUpdateData(_user)
            setSending(false)
            onDispatchSuccess('Acción exitosa')
        } catch (error) {
            console.log(error)
            setError('Hubo un error')
            setSending(false)
        }
    }

    return ( 
        <SimpleCard>
            <Grid container spacing={4} justify='flex-end'>
                <Grid item xs={12}>
                    <DisplayText variant='subtitle1'>Información general</DisplayText>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} >
                            <InputForm data={form.first_name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.last_name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.email} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputForm data={form.phone_number} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputForm data={form.identification_card} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SelectForm data={form.gender_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SelectForm data={form.status_id} onChange={onChange} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <ContainedButton color='primary' loading={sending} onClick={onSubmit}>Guardar</ContainedButton>
                </Grid>
            </Grid>
        </SimpleCard>

     );
}
 
export default BasicInfoForm;